import { useField } from 'react-final-form';
import styles from './../formControl.module.scss';
import { RFFCurrencyInput } from "@qwealth/qcore";
import FormControlContainer from '../FormControlContainer';

export default function RFFCurrencyInputQM({ inputFieldProps = {}, ...props }) {
  const field = useField(props.name);
  return (
    <FormControlContainer>
      <RFFCurrencyInput
        {...props}
        inputFieldProps={{
          ...inputFieldProps,
          className: `
          ${field.meta.error && `${styles.missing} ${styles.missingDropDown}`}
          ${inputFieldProps.className} 
        `,
        }}
      />
    </FormControlContainer>
  )
}