import { useField } from 'react-final-form';
import styles from './../formControl.module.scss';
import { RFFReactSelect } from "@qwealth/qcore";
import FormControlContainer from '../FormControlContainer';

export default function RFFReactSelectQM({ inputFieldProps={}, ...props }) {
  const field = useField(props.name);
  return (
    <FormControlContainer>
      <RFFReactSelect
        {...props}
        inputFieldProps={{
          ...inputFieldProps,
          className: `
           ${field.meta.error && `${styles.missing} ${styles.missingDropDown}`}
           ${styles.dropDown}
           ${inputFieldProps.className} 
          `,
        }}
      />
    </FormControlContainer>
  )
}