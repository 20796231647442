import React from 'react';
import styles from './breadcrumbs.module.scss';
import { useHistory } from 'react-router-dom';
import { getRandomId } from '../../helpers';

const renderBreadcrumbs = (items, onNavigate) => {
	return items.map(item => {
		return <li key={getRandomId()}>
			<button
				onClick={() => onNavigate(item.path) }
			>
				{item.title}
			</button>
		</li>
	});
};

const Breadcrumbs = (props) => {
	const history = useHistory();
	
	const navigate = (loc) => {
		history.push(loc);
	}
	
	return (
		<>
			<div className={styles.breadCrumbsContainer}>
				<ul className={styles.breadCrumbs}>
					{renderBreadcrumbs(props.items, navigate)}
				</ul>
			</div>
		</>
	);
};

export default Breadcrumbs;
