import { put, call, cancel } from 'redux-saga/effects';
import Creators, {setUpdatedAccount} from '../state/AppRedux';
import { accountsServices } from '../services/accountsServices';
import { toast } from 'react-toastify';

export function * fetchAccounts(data) {
	yield put(Creators.isFetchingAccounts(true));
	let result = null;
	try {
		result = yield call(accountsServices.fetchAccountsList, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingAccounts(false));
			yield put(Creators.setAccounts(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingAccounts(false));
		console.log(e);
	}
}

export function * saveAccount(data) {
	yield put(Creators.isFetchingAccounts(true));
	let result = null;
	try {
		result = yield call(accountsServices.saveAccount, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingAccounts(false));
			toast.success('Account created successfully.');
			//yield put(Creators.setAccounts(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingAccounts(false));
		console.log(e);
	}
}

export function * updateAccount(data) {
	yield put(Creators.isFetching(true));
	let result = null;
	try {
		result = yield call(accountsServices.updateAccount, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetching(false));
			toast.success('Account updated successfully.');
			yield put(Creators.setUpdatedAccount(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingAccounts(false));
		console.log(e);
	}
}
