import { createReducer, createActions } from 'reduxsauce'
import {
  getParseMissingFieldsString,
  parseHouseholdNestedContacts,
  updateContactTableColumns,
  calculateHouseholdMissingFields,
  parseHouseholdMembersForHouseholdsList, getHouseholdMissingFieldsSum
} from '../helpers';
import _ from 'lodash';
import { updateItemAndGetArrayImmutably } from '@qwealth/qcore';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // start login process + loading indicators etc.
  loggingIn: null,
  logout: null,
  fetchHouseholds: null,
  setHouseholds: [ 'data' ],
  isFetching: [ 'fetching' ],
  setContacts: [ 'data' ],
  fetchContacts: null,
  setAccounts: [ 'data' ],
  fetchAccounts: null,
  fetchDetailedHouseholds: null,
  setDetailedHouseholds: [ 'data' ],
  setGeneralSettings: [ 'data' ],
  fetchGeneralSettings: null,
  updateContact: [ 'contact' ],
  fetchHouseholdDetails: [ 'qid' ],
  setHousehold: [ 'household' ],
  setActiveHousehold: [ 'qid' ],
  saveHousehold: [ 'household' ],
  saveAccount: [ 'data' ],
  isFetchingHouseholds: [ 'fetching' ],
  isFetchingContacts: [ 'fetching' ],
  isFetchingAccounts: [ 'fetching' ],
  clearHouseholdDetails: null,
  updateAccount: [ 'data' ],
  updateContactRes: [ 'contact' ],
  updateContactInStore: [ 'contact' ],
  isFetchingSettings: [ 'fetching' ],
  uploadCsvAndMappings: [ 'data' ],
  fetchImportLogs: null,
  setImportLogs: [ 'logs' ],
  bulkExport: [ 'data' ],
  downloading: [ 'fetching' ],
  fetchDocusignConsentUrl: [ 'data' ],
  setDocusignConsentUrl: [ 'url' ],
  cleanDocusignConsentUrl: null,
  fetchEmbeddedSendingUrl: [ 'data' ],
  setEmbeddedSendingUrl: [ 'url' ],
  clearDocusignEmbeddedSendingUrl: null,
  setTableColumnsPref: [ 'data' ],
  uploadIdentityDocument: [ 'data' ],
  updateIdentityVerificationInfo: [ 'response' ],
  savingHousehold: [ 'isSaving' ],
  uploadVoidChequeDocument: [ 'data' ],
  updateBankAccountInfo: [ 'response' ],
  setRelinquishingInstitutions: [ 'data' ],
  fetchRelinquishingInstitutions: null,
  isFetchingRelinquishingInstitutions: [ 'fetching' ],
  setUpdatedAccount: [ 'account' ]
});

export const QMTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  households: [],
  isFetching: false,
  contacts: [],
  accounts: [],
  enumerations: null,
  labels: null,
  activeHousehold: null,
  isFetchingHouseholds: false,
  isFetchingContacts: false,
  isFetchingAccounts: false,
  fetchingSettings: false,
  importLogs: [],
  isDownloadingFile: false,
  docusignConsentUrl: null,
  docusignEmbeddedSendingUrl: null,
  tableColumnsPref: null,
  relinquishingInstitutions: [],
  isFetchingRelinquishingInstitutions: false,
  savingHousehold: false,
};

/* ------------- Reducers ------------- */

export const logout = (state) => {
  return INITIAL_STATE;
};

export const isFetching = (state, { fetching }) => {
  return {
    ...state,
    isFetching: fetching
  }
};

export const setHouseholds = (state, { data }) => {
  const households = [];
  data.map(el => {
    households.push({
      ...el,
      missingFields: getParseMissingFieldsString(el.missing_fields.fields)
    });
  });
  return {
    ...state,
    households: households
  }
};

export const setContacts = (state, { data }) => {
  const contacts = [];
  data.map(el => {
    contacts.push({
      ...el,
      name: el.firstName + ' ' + el.lastName,
      missingFields: getParseMissingFieldsString(el.missing_fields.fields)
    });
  });
  return {
    ...state,
    contacts: contacts,
    _contacts: contacts
  }
};

export const setAccounts = (state, { data }) => {
  const accounts = [];
  data.map(el => {
    accounts.push({
      ...el,
      missingFields: getParseMissingFieldsString(el.missing_fields.fields)
    });
  });
  return {
    ...state,
    accounts: accounts
  }
};

export const setDetailedHouseholds = (state, { data }) => {
  const households = [];
  data.map(el => {
    households.push({
      ...el,
      missingFields: 0,
      totalMissingFields: getHouseholdMissingFieldsSum(el, state.labels),
      household_members: parseHouseholdMembersForHouseholdsList(el.household_members, state.labels)
    });
  });
  return {
    ...state,
    households: households
  }
};

export const setGeneralSettings = (state, { data }) => {
  return {
    ...state,
    enumerations: data.enumerations,
    labels: data.labels
  }
};

export const updateContact = (state, { contact }) => {
  let contactsArray = state.contacts;
  const contactIndex = contactsArray.findIndex(el => {
    return el.contactQID === contact.contactQID;
  });
  if (contactIndex > -1) {
    contactsArray[ contactIndex ] = contact;
  }
  return {
    ...state,
    contacts: contactsArray
  };
};

export const setHousehold = (state, { household }) => {
  return {
    ...state,
    [ household.householdQID ]: household
  };
};

export const setActiveHousehold = (state, { qid }) => {
  return {
    ...state,
    activeHousehold: qid
  };
}

export const isFetchingHouseholds = (state, { fetching }) => {
  return {
    ...state,
    isFetchingHouseholds: fetching
  };
};

export const isFetchingContacts = (state, { fetching }) => {
  return {
    ...state,
    isFetchingContacts: fetching
  };
};

export const isFetchingAccounts = (state, { fetching }) => {
  return {
    ...state,
    isFetchingAccounts: fetching
  };
};

export const clearHouseholdDetails = (state) => {
  const activeHousehold = state.activeHousehold;
  const currentState = _.cloneDeep(state);
  delete currentState[ activeHousehold ];
  currentState.activeHousehold = '';
  currentState.docusignConsentUrl = null;
  return currentState;
};

export const updateContactRes = (state, { contact }) => {
  const contactsArray = _.cloneDeep(state.contacts);
  const contactIndex = contactsArray.findIndex(el => {
    return el.contactQID === contact.contactQID;
  });
  if (contactIndex > -1) {
    contactsArray[ contactIndex ] = contact;
  }
  return {
    ...state,
    contacts: contactsArray
  };
};

export const isFetchingSettings = (state, { fetching }) => {
  return {
    ...state,
    fetchingSettings: fetching
  };
};

export const setImportLogs = (state, { logs }) => {
  return {
    ...state,
    importLogs: logs
  };
};

export const downloading = (state, { fetching }) => {
  return {
    ...state,
    isDownloadingFile: fetching
  };
};

export const setDocusignConsentUrl = (state, { url }) => {
  return {
    ...state,
    docusignConsentUrl: url
  };
};

export const cleanDocusignConsentUrl = (state) => {
  return {
    ...state,
    docusignConsentUrl: null
  };
};

export const setEmbeddedSendingUrl = (state, { url }) => {
  return {
    ...state,
    docusignEmbeddedSendingUrl: url
  };
};

export const clearDocusignEmbeddedSendingUrl = (state) => {
  return {
    ...state,
    docusignEmbeddedSendingUrl: null
  };
};

export const setTableColumnsPref = (state, { data }) => {
  return {
    ...state,
    tableColumnsPref: data
  };
};

export const updateIdentityVerificationInfo = (state, { response }) => {
  let householdToUpdate = _.cloneDeep(state[ response.household ]);
  const contactIndex = householdToUpdate.household_members.findIndex(el => {
    return el.memberQID === response.contact;
  });
  if (contactIndex > -1) {
    householdToUpdate = _.set(householdToUpdate, 'household_members.[' + contactIndex + '].contact.identification', response.response);
  }
  return {
    ...state,
    [ response.household ]: householdToUpdate
  };
};

export const savingHousehold = (state, { isSaving }) => {
  return {
    ...state,
    savingHousehold: isSaving
  };
};

export const updateBankAccountInfo = (state, { response }) => {
  let householdToUpdate = _.cloneDeep(state[ response.household ]);
  const contactIndex = householdToUpdate.household_members.findIndex(el => {
    return el.memberQID === response.contact;
  });
  if (contactIndex > -1) {
    householdToUpdate = _.set(householdToUpdate, 'household_members.[' + contactIndex + '].contact.banking_account_detail', response.response);
  }
  return {
    ...state,
    [ response.household ]: householdToUpdate
  };
}

export const isFetchingRelinquishingInstitutions = (state, { fetching }) => {
  return {
    ...state,
    isFetchingRelinquishingInstitutions: fetching
  };
}

export const setRelinquishingInstitutions = (state, { data }) => {
  const relinquishingInstitutions = [];
  data.map(el => {
    relinquishingInstitutions.push({
      ...el,
      missingFields: getParseMissingFieldsString(el.missing_fields.fields)
    });
  });
  return {
    ...state,
    relinquishingInstitutions: relinquishingInstitutions
  }
};

export const setUpdatedAccount = (state, { account }) => {
  const accounts = updateItemAndGetArrayImmutably(
    state.accounts,
    (a) => a.id === account.id,
    (targetAccount) =>
      targetAccount
        ? {
          ...targetAccount,
          ...account,
        }
        : account,
  );
  return { ...state, accounts };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [ Types.LOGOUT ]: logout,
  [ Types.IS_FETCHING ]: isFetching,
  [ Types.SET_HOUSEHOLDS ]: setHouseholds,
  [ Types.SET_CONTACTS ]: setContacts,
  [ Types.SET_ACCOUNTS ]: setAccounts,
  [ Types.SET_DETAILED_HOUSEHOLDS ]: setDetailedHouseholds,
  [ Types.SET_GENERAL_SETTINGS ]: setGeneralSettings,
  [ Types.UPDATE_CONTACT ]: updateContact,
  [ Types.SET_HOUSEHOLD ]: setHousehold,
  [ Types.SET_ACTIVE_HOUSEHOLD ]: setActiveHousehold,
  [ Types.IS_FETCHING_HOUSEHOLDS ]: isFetchingHouseholds,
  [ Types.IS_FETCHING_CONTACTS ]: isFetchingContacts,
  [ Types.IS_FETCHING_ACCOUNTS ]: isFetchingAccounts,
  [ Types.CLEAR_HOUSEHOLD_DETAILS ]: clearHouseholdDetails,
  [ Types.UPDATE_CONTACT_RES ]: updateContactRes,
  [ Types.IS_FETCHING_SETTINGS ]: isFetchingSettings,
  [ Types.SET_IMPORT_LOGS ]: setImportLogs,
  [ Types.DOWNLOADING ]: downloading,
  [ Types.SET_DOCUSIGN_CONSENT_URL ]: setDocusignConsentUrl,
  [ Types.CLEAN_DOCUSIGN_CONSENT_URL ]: cleanDocusignConsentUrl,
  [ Types.SET_EMBEDDED_SENDING_URL ]: setEmbeddedSendingUrl,
  [ Types.CLEAR_DOCUSIGN_EMBEDDED_SENDING_URL ]: clearDocusignEmbeddedSendingUrl,
  [ Types.SET_TABLE_COLUMNS_PREF ]: setTableColumnsPref,
  [ Types.UPDATE_IDENTITY_VERIFICATION_INFO ]: updateIdentityVerificationInfo,
  [ Types.SAVING_HOUSEHOLD ]: savingHousehold,
  [ Types.UPDATE_BANK_ACCOUNT_INFO ]: updateBankAccountInfo,
  [ Types.SET_RELINQUISHING_INSTITUTIONS ]: setRelinquishingInstitutions,
  [ Types.IS_FETCHING_RELINQUISHING_INSTITUTIONS ]: isFetchingRelinquishingInstitutions,
  [ Types.SET_UPDATED_ACCOUNT ]: setUpdatedAccount
});
