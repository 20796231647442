import React from 'react';
import { AppLayout, Box } from '@qwealth/qcore';
import { BsColumnsGap, BsFillPeopleFill, BsListNested } from 'react-icons/bs';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './sidebar.module.scss';
import cx from 'classnames';
import {
  selectHouseholdsCount,
  selectContactsCount,
  selectAccountsCount,
  selectRelinquishingInstitutionsCount
} from '../../state/Selectors';
import { connect } from 'react-redux';
import {
  Q_LIFE_URL,
  Q_CASH_URL,
  Q_BIAS_URL,
  Q_SCORE_URL,
  Q_RISK_URL,
  Q_FOLIO_URL,
  SF_URL,
  DIGIT_URL,
} from '../../constants';
import QApp from '../qapp/qapp';

const Sidebar = (props) => {
  const history = useHistory();

  const navigate = (loc) => {
    history.push(loc);
  }

  const QApps = {
    QLife: `${Q_LIFE_URL}`,
    QCash: `${Q_CASH_URL}`,
    QBias: Q_BIAS_URL,
    QScore: `${Q_SCORE_URL}`,
    QRisk: Q_RISK_URL,
    QFolio: Q_FOLIO_URL,
    SF: SF_URL,
    Digit: DIGIT_URL,
  };


  return (
    <>
      <AppLayout.SideNav title="QMove">
        <AppLayout.SideNavLinks>
          <div className={styles.sideNavLinkItem}>
            <button
              onClick={() => { navigate('/') }}
              className={cx(useRouteMatch({ path: '/', exact: true, sensitive: true }) && styles.active)}
            >
              <BsColumnsGap />
              <div>
                Summary
              </div>
            </button>
          </div>
          <div className={styles.sideNavLinkItem}>
            <button
              onClick={() => { navigate('/households') }}
              className={cx(useRouteMatch('/households') && styles.active)}
            >
              <BsFillPeopleFill />
              <div>
                Households
                <span className={styles.sidebarBadge}>{props.totalHouseholds}</span>
              </div>
            </button>
          </div>
          <div className={cx(styles.sideNavLinkItem, styles.subItem)}>
            <button
              onClick={() => { navigate('/contacts') }}
              className={cx(useRouteMatch('/contacts') && styles.active)}
            >
              <div>
                Contacts
                <span className={styles.sidebarBadge}>{props.totalContacts}</span>
              </div>
            </button>
          </div>
					<div className={cx(styles.sideNavLinkItem, styles.subItem)}>
						<button
							onClick={() => {}}
							className={cx(useRouteMatch('/entities') && styles.active)}
						>
							<div>
								Entities
								<span className={styles.sidebarBadge}>0</span>
							</div>
						</button>
					</div>
          <div className={cx(styles.sideNavLinkItem, styles.subItem)}>
            <button
              onClick={() => { navigate('/accounts'); }}
              className={cx(useRouteMatch('/accounts') && styles.active)}
            >
              <div>
                Accounts
                <span className={styles.sidebarBadge}>{props.totalrelinquishingInstitutions}</span>
              </div>
            </button>
          </div>
          <div className={cx(styles.sideNavLinkItem, styles.subItem)}>
            <button
              onClick={() => { navigate('/relinquishingInstitution'); }}
              className={cx(useRouteMatch('/relinquishingInstitution') && styles.active)}
            >
              <div>
                Relinquishing Inst
                <span className={styles.sidebarBadge}>{props.totalrelinquishingInstitutions}</span>
              </div>
            </button>
          </div>
          {/* Temp Hide: QC-1669 
					<div className={styles.sideNavLinkItem}>
						<button
							onClick={() => { navigate('/logs') }}
							className={cx(useRouteMatch('/logs') && styles.active)}
						>
							<BsListNested />
							<div>
								Import Logs
							</div>
						</button>
					</div> */}
        </AppLayout.SideNavLinks>
        <AppLayout.SideNavSection title="QSUITE">
          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, 40px)"
            gap="default"
            justifyContent="space-evenly"
          >
            {Object.keys(QApps).map(app => (
              <QApp
                key={app}
                name={app}
                alt={app}
                img={`${app}.png`}
                onClick={() => window.location.assign(QApps[ app ])}
              />
            ))}
          </Box>
        </AppLayout.SideNavSection>
      </AppLayout.SideNav>
    </>
  );
}

const mapStateToProps = (state) => ({
  totalHouseholds: selectHouseholdsCount(state),
  totalContacts: selectContactsCount(state),
  totalAccounts: selectAccountsCount(state),
  totalrelinquishingInstitutions: selectRelinquishingInstitutionsCount(state)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
