import React, { useState, useEffect } from 'react';
import styles from './bulkimport.module.scss';
import { BsX } from 'react-icons/bs';
import FormControl from '../formControl/formControl';
import { csv } from 'd3-fetch';
import { toast } from 'react-toastify';
import { selectLabels, isFetchingGeneralSettings } from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { connect } from 'react-redux';
import LoadingIndicator from '../loading/loading';
import QTFieldsTable from '../qtfieldstable/qtfieldstable';
import QTMappings from '../qtmappings/qtmappings';
import { BULK_IMPORT_OPTIONS } from '../../constants';

const BulkImport = (props) => {
	const [selectedFile, setSelectedFile] = useState(null);
	const [CSVColumns, setCSVColumns] = useState([]);
	const [mappings, setMappings] = useState(null);
	const [file, setFile] = useState('');
	const [selectedImportType, setSelectedImportType] = useState('Households');
	
	useEffect(() => {
		if (selectedFile) {
			csv(selectedFile)
				.then((data) => {
					if (data && data.columns) {
						setCSVColumns(data.columns);
					} else {
						toast.error('CSV File does not have any columns defined. Please upload a correct CSV.');
					}
				})
				.catch((error) => {
					toast.error('CSV Seems to be corrupted. Please upload a correct CSV.');
				});
		}
	}, [selectedFile]);
	
	useEffect(() => {
		props.fetchGeneralSettings();
		if (props.importType) {
			setSelectedImportType(props.importType);
		}
	}, []);
	
	const selectFile = (event) => {
		const file = event.target.files;
		if (file && file[0]) {
			setFile(file[0]);
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				setSelectedFile(e.target.result);
			}
			fileReader.readAsDataURL(file[0]);
		}
	};
	
	const updateMappings = (emptyParam, option, name) => {
		if (mappings) {
			setMappings({
				...mappings,
				[name]: {
					csvColumn: option.value
				}
			});
		} else {
			setMappings({
				[name]: {
					csvColumn: option.value
				}
			});
		}
	};
	
	const clearMappings = () => {
		setMappings(null);
	};
	
	const createNewRecords = () => {
		const data = {
			csv: selectedFile,
			mappings: mappings,
			importType: selectedImportType,
			fileName: file.name,
			fileType: file.type,
			fileSize: file.size,
			action: 'CREATE'
		};
		props.uploadCsvAndMappings(data);
	};
	
	const autoMatchColumnsToFields = () => {
		if (selectedImportType && props.labels) {
			const labelsArray = Object.keys(props.labels[selectedImportType]);
			let labelsMappings = {};
			CSVColumns.map(el => {
				if (labelsArray.includes(el)) {
					labelsMappings = {
						...labelsMappings,
						[el]: {
							csvColumn: el
						}
					};
				}
			});
			setMappings(labelsMappings);
		}
	};
	
	const updateImportType = (emptyArg, dropDownValue, path) => {
		setSelectedImportType(dropDownValue.value);
	};
	
	const updateRecords = () => {
		const data = {
			csv: selectedFile,
			mappings: mappings,
			importType: selectedImportType,
			fileName: file.name,
			fileType: file.type,
			fileSize: file.size,
			action: 'UPDATE'
		};
		props.uploadCsvAndMappings(data);
	};
	
	return (
		<div className={styles.bulkImportModalContainer}>
			{props.fetching ?
				<LoadingIndicator size='small' height='500px' />
				:
				<div className={styles.contentContainer}>
					<div className={styles.modalHeader}>
						<div className={styles.modalTitleContainer}>
							<h3>
								Bulk Import <span>Households</span>
							</h3>
						</div>
						<button onClick={() => props.hideBulkImportModal()}>
							<BsX style={{ strokeWidth: '1.2' }} />
						</button>
					</div>
					<div className={styles.modalBody}>
						<div className={styles.modalActionsContainer}>
							<div>
								<div>
									<FormControl
										label={'Select Table'}
										value={selectedImportType}
										type={'Dropdown'}
										options={BULK_IMPORT_OPTIONS}
										updateValue={(emptyArg, dropDownValue, path) => updateImportType(emptyArg, dropDownValue, path) }
										name='table'
										pathPrefix=''
									/>
								</div>
								<div>
									<FormControl
										label={'Select File'}
										value={'Households'}
										type={'file'}
										options={[]}
										updateValue={selectFile}
										name='table'
										pathPrefix=''
										fileTypes={'.csv'}
									/>
								</div>
							</div>
							<div>
								<button onClick={() => autoMatchColumnsToFields()}>
									Auto-Match Fields
								</button>
								<button onClick={() => clearMappings()}>
									Clear Mapping
								</button>
							</div>
						</div>
						<div className={styles.fieldsSummaryContainer}>
							<h3>
								QT Data Fields
							</h3>
							<div className={styles.tableContainer}>
								<QTFieldsTable
									data={props.labels}
									importType={selectedImportType}
								/>
							</div>
						</div>
						<div className={styles.fieldsMappingContainer}>
							<h3>
								CSV Mappings to QT Fields
							</h3>
							<div className={styles.tableContainer}>
								{(selectedFile === null || CSVColumns.length === 0)
									?
									<div className={styles.noFileSelected}>
										No file selected. Please select a valid CSV file to begin mapping.
									</div>
									:
									<QTMappings
										update={updateMappings}
										columns={CSVColumns}
										fields={props.labels}
										importType={selectedImportType}
										mappings={mappings}
									/>
								}
							</div>
						</div>
					</div>
					<div className={styles.modalFooter}>
						<button onClick={() => createNewRecords()} className={styles.themeButton}>
							Create new records
						</button>
						<button onClick={() => updateRecords()} className={styles.themeButton}>
							Update records
						</button>
						<button onClick={() => props.hideBulkImportModal()} className={styles.themeButton}>
							Cancel
						</button>
					</div>
				</div>
			}
		</div>
	);
};

const mapStateToProps = (state) => ({
	labels: selectLabels(state),
	fetching: isFetchingGeneralSettings(state)
});

const mapDispatchToProps = (dispatch) => ({
	fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
	uploadCsvAndMappings: (data) => dispatch(Creators.uploadCsvAndMappings(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BulkImport);
