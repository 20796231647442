import { useField } from 'react-final-form';
import styles from './../formControl.module.scss';
import { RFFTextInput } from "@qwealth/qcore";
import FormControlContainer from '../FormControlContainer';

export default function RFFTextInputQM({ inputFieldProps={}, ...props }) {
  const field = useField(props.name);
  return (
    <FormControlContainer>
      <RFFTextInput 
      {...props} 
      inputFieldProps={{
        ...inputFieldProps,
        className: `
          ${field.meta.error && `${styles.missing} ${styles.missingDropDown}`}
          ${inputFieldProps.className} 
        `,
      }}
      />
    </FormControlContainer>
  )
}