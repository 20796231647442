import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Box } from '@qwealth/qcore';
import styles from './importLogs.module.scss';
import { BsUpload } from 'react-icons/bs';
import { BulkImport, Collapsable, SearchForm, LoadingIndicator } from '../../components';
import { selectIsFetching, selectImportLogs } from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { connect } from 'react-redux';
import { searchImportLogs } from '../../helpers';

const renderImportLogs = (logs) => {
	if (logs.length > 0) {
		return logs.map((el, index) => {
			return (
				<Collapsable
					key={index}
					log={el}
				/>
			);
		});
	} else {
		return (
			<p style={{ textAlign: 'center' }}>
				No data found.
			</p>
		)
	}
};

const ImportLogs = (props) => {
	const [showBulkImportModal, setShowBulkImportModal] = useState();
	const [logsImportedList, setLogsImportedList] = useState([]);
	
	useEffect(() => {
		props.fetchImportLogs();
	}, []);
	
	useEffect(() => {
		if (props.importLogs.length > 0) {
			setLogsImportedList(props.importLogs);
		}
	}, [props.importLogs]);
	
	const showModal = () => {
		setShowBulkImportModal(true);
	};
	
	const hideModal = () => {
		setShowBulkImportModal(false);
	}
	
	const performSearch = (e) => {
		const filteredList = searchImportLogs(props.importLogs, e.target.value);
		setLogsImportedList(filteredList);
	};
	
	return (
		<>
			<Helmet>
				<title>Bulk Import Logs - QMove</title>
			</Helmet>
			<Container
				backgroundColor={'#E5E5E5'}
			>
				<div className={styles.pageHeader}>
					<h1>Bulk Import Logs</h1>
					<div>
						<button onClick={showModal} className={styles.themeButton}>
							<span>Bulk Import</span>
							<BsUpload />
						</button>
					</div>
				</div>
				<div>
					<Box
						backgroundColor="#FFFFFF"
						mb="large"
						display="flex"
						justifyContent="flex-end"
						borderRadius="12px"
						padding="20px"
						flexDirection="column"
						marginTop="20px"
					>
						<div className={styles.boxHeader}>
							<div>
								<h4>
									Import Logs
								</h4>
							</div>
							<div>
								<SearchForm
									formAction={performSearch}
									placeholder={'Search...'}
								/>
							</div>
						</div>
						<div className={styles.boxContent}>
							{props.fetching
								?
								<LoadingIndicator />
								:
								renderImportLogs(logsImportedList)
							}
						</div>
					</Box>
				</div>
			</Container>
			{showBulkImportModal &&
				<BulkImport
					hideBulkImportModal={hideModal}
					importType={'households'}
				/>
			}
		</>
	);
}

const mapStateToProps = (state) => ({
	fetching: selectIsFetching(state),
	importLogs: selectImportLogs(state)
});

const mapDispatchToProps = (dispatch) => ({
	fetchImportLogs: () => dispatch(Creators.fetchImportLogs())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ImportLogs);
