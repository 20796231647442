import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import axios from 'axios';
import { Box, Card, TextInput } from '@qwealth/qcore';

const EMPTY_USER = {
  givenName: '',
  surName: '',
  email: '',
};

const fields = ['givenName', 'surName', 'email'];

const UserProfile = () => {
  const user = useSelector((state) => state.microsoft.user) || EMPTY_USER;

  const [apiStatus, setApiStatus] = useState('');
  const [apiVersion, setApiVersion] = useState('');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_QMOVE_API_BASE_URL}`)
      .then(response => response.data)
      .then(apiResponse => {
        setApiStatus(apiResponse.server);
        setApiVersion(apiResponse.version);
      });
  }, []);

  return (
    <Box flexDirection="row" display="flex" gap="largest">
      <Card title="User Details">
        {fields.map(field => (
          <Box mb="large" key={field}>
            <Box textStyle="formLabel">{startCase(field)}</Box>
            <TextInput
              disabled
              value={user[field]}
            />
          </Box>
        ))}
      </Card>

      <Card title="App Details">
        <Box mb="large">
          <Box textStyle="formLabel">QMove Version</Box>
          <TextInput
            disabled
            value={process.env.REACT_APP_VERSION}
          />
        </Box>

        <Box mb="large">
          <Box textStyle="formLabel">API Status</Box>
          <TextInput
            disabled
            value={apiStatus}
          />
        </Box>

        <Box mb="large">
          <Box textStyle="formLabel">API Version</Box>
          <TextInput
            disabled
            value={apiVersion}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default UserProfile;
