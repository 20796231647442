import React, { useState, useEffect } from 'react';
import { Box } from '@qwealth/qcore';
import styles from './accountsList.module.scss';
import { searchAccountsList } from '../../helpers';
import cx from 'classnames';
import SearchForm from '../searchForm/searchForm';
import AccountsListTable from './accountsListTable';
import { AccountForm } from '../index';
import LoadingIndicator from '../loading/loading';
import PopOver from '../popover/popover'
import { GoSettings } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { selectHousehold } from '../../state/Selectors';
import { TabFilters } from '../../constants';

const AccountsList = (props) => {
  const [ accountsFiltered, setAccountsFiltered ] = useState(props.accounts.activeList);
  const [ activeFilter, setActiveFilter ] = useState('ALL');
  const [ showAccountModal, setShowAccountModal ] = useState(false);
  const [ activeAccount, setActiveAccount ] = useState(null);
  const [ showColumnsPrefModal, setShowColumnsPrefModal ] = useState(false)
  const activeHousehold = useSelector(selectHousehold);

  useEffect(() => {
    setAccountsFiltered(props.accounts.activeList);
  }, [ props.accounts ]);

  const performSearch = (e) => {
    const filteredList = searchAccountsList(props.accounts.activeList, e.target.value);
    setAccountsFiltered(filteredList);
  };

  const switchView = (filterVal) => {
    props.switchListView(filterVal);
    setActiveFilter(filterVal)
  };

  const editAccount = (account) => {
    setActiveAccount(account);
    setShowAccountModal(true);
  };

  const hideAccountCreationModal = () => {
    setShowAccountModal(false);
    props.hideAccountCreationModal && props.hideAccountCreationModal();
    setActiveAccount(null);
  };

  const showTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(true)
  };

  const hideTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(false)
  };

  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px"
        flexDirection="column"
        marginTop="20px"
      >
        {props.switchListView &&
          <div className={styles.listContainerHeader}>
            <div>
              <div className={styles.tabNavContainer}>
                <button onClick={() => switchView(TabFilters.all)} className={cx(activeFilter === TabFilters.all && styles.activeBtn)}>All</button>
                <button onClick={() => switchView(TabFilters.incomplete)} className={cx(activeFilter === TabFilters.incomplete && styles.activeBtn)}>
                  Incomplete <span>{props.accounts[ TabFilters.incomplete ].count}</span>
                </button>
                <button onClick={() => switchView(TabFilters.complete)} className={cx(activeFilter === TabFilters.complete && styles.activeBtn)}>
                  Complete <span>{props.accounts[ TabFilters.complete ].count}</span>
                </button>
                <button onClick={() => switchView(TabFilters.moved)} className={cx(activeFilter === TabFilters.moved && styles.activeBtn)}>
                  moved <span>{props.accounts[ TabFilters.moved ].count}</span>
                </button>
              </div>
            </div>
            <div className={styles.positionRelative}>
              <div className={styles.positionRelative}>
                {showColumnsPrefModal &&
                  <PopOver
                    hideModal={hideTableColumnsSelectionModal}
                    editTableColumns={props.editTableColumns}
                    dataType='accounts'
                  />
                }
              </div>
              <button onClick={showTableColumnsSelectionModal} className={cx(styles.editColumnsButton, styles.positionRelative)}><GoSettings /> Select Columns</button>
              <SearchForm
                formAction={performSearch}
                placeholder={'Search...'}
              />
            </div>
          </div>
        }
        <div className={styles.tableContainer}>
          {props.fetchingAccounts ?
            <LoadingIndicator size='small' height='500px' />
            :
            <AccountsListTable
              household={activeHousehold}
              labels={props.labels}
              accounts={accountsFiltered}
              editAccount={editAccount}
              columnPref={props.columnPref}
            />
          }
        </div>
        {(showAccountModal || props.showAccountModal) &&
          <AccountForm
            getOptions={props.getOptions}
            getFieldLabel={props.getFieldLabel}
            hideModal={hideAccountCreationModal}
            saveAccount={props.saveAccount}
            account={activeAccount}
            updateAccount={props.updateAccount}
            contacts={props.contacts}
            getOptionsRelinquishingInstitutions={props.getOptionsRelinquishingInstitutions}
            household={activeHousehold}
          />
        }
      </Box>
    </>
  );
};

export default AccountsList;
