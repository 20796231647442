import React, { useState, useEffect } from 'react';
import { parseValidations } from '../../helpers';
import styles from './qtfieldstable.module.scss';

const renderDataFieldsRows = (labels) => {
	const labelsArray = Object.keys(labels);
	return labelsArray.map((el, index) => {
		if (labels[el].visibility.toUpperCase() === 'PUBLIC' ) {
			return (
				<tr key={index}>
					<td>
						{el}
					</td>
					<td>
						{labels[el].en}
					</td>
					<td>
						{labels[el].type}
					</td>
					<td>
						{parseValidations(labels[el].validation)}
					</td>
				</tr>
			);
		}
	});
};

const QTFieldsTable = (props) => {
	const [selectedTableLabels, setSelectedTableLabels] = useState([]);
	
	useEffect(() => {
		// console.log(props.data[props.importType], props.importType);
		if (props.data && props.importType) {
			setSelectedTableLabels(props.data[props.importType]);
		}
	}, [props.data, props.importType]);
	
	return (
		<>
			<table className={styles.qtTable}>
				<thead>
					<tr>
						<th>
							Name
						</th>
						<th>
							Label
						</th>
						<th>
							Type
						</th>
						<th>
							Validation
						</th>
					</tr>
				</thead>
				<tbody>
					{renderDataFieldsRows(selectedTableLabels)}
				</tbody>
			</table>
		</>
	);
};

export default QTFieldsTable;
