import React from 'react';
import { Box } from '@qwealth/qcore';
import { InputForm, ConditionallyRequiredFieldLabel } from './Common';
import RFFTextInputQM from '../formControl/ReactFinalForm/RFFTextInputQM';

const VALIDATABLE_FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

export const ParentLegalGuardianForm = ({
  isOnboardingComplete,
}) => (
  <Box
    mb="large"
    display="grid"
    gap="large"
    gridTemplateColumns={{
      phone: '1fr',
      desktop: '1fr 1fr 1fr 1fr',
    }}
  >
    <InputForm>
      <RFFTextInputQM
        label={() => (
          <ConditionallyRequiredFieldLabel
            labelText="First Name"
            isConditionMet={isOnboardingComplete}
          />
        )}
        name="educationDisabilityAccountDetail.parentGuardian.firstName"
        subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
      />
    </InputForm>
    <InputForm>
      <RFFTextInputQM
        label={() => (
          <ConditionallyRequiredFieldLabel
            labelText="Last Name"
            isConditionMet={isOnboardingComplete}
          />
        )}
        name="educationDisabilityAccountDetail.parentGuardian.lastName"
        subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
      />
    </InputForm>
    <InputForm>
      <RFFTextInputQM
        label={() => (
          <ConditionallyRequiredFieldLabel
            labelText="Email"
            isConditionMet={isOnboardingComplete}
          />
        )}
        name="educationDisabilityAccountDetail.parentGuardian.primaryEmail"
        subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
      />
    </InputForm>
    <InputForm>
      <RFFTextInputQM
        label={() => (
          <ConditionallyRequiredFieldLabel labelText="SIN" isConditionMet={isOnboardingComplete} />
        )}
        name="educationDisabilityAccountDetail.parentGuardian.SIN"
        subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
      />
    </InputForm>
  </Box>
);
