import React, { useEffect, useState } from 'react';
import { Container } from '@qwealth/qcore';
import styles from './contacts.module.scss';
import { BsUpload, BsPlus, BsDownload } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';
import { BulkImport, ContactsList, ProgressBar } from '../../components';
import { connect } from 'react-redux';
import {
  selectContacts,
  selectLabels,
  selectIsFetchingContacts,
  selectIsFetching,
  selectIsDownloadingFile,
  selectTableColumnsPref,
} from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { extractFieldLabel } from '../../helpers';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import { useTabFilter } from '../../hooks/useTabFilter';
import { TabFilters } from '../../constants';

const Contacts = (props) => {
  const [ defaultCol, setDefaultCol ] = useState({})
  const [ activeFilter, setActiveFilter ] = useState(TabFilters.all);
  const tabFilteredList = useTabFilter({activeFilter, dataList: props.contacts});
  const [ showBulkImportModal, setShowBulkImportModal ] = useState(false);
  const history = useHistory()
  
  useEffect(() => {
    const type = 'contacts'
    props.fetchContacts();
    props.fetchGeneralSettings();

  }, [])

  const updateContact = (updatedContact) => {
    props.updateContact(updatedContact);
  };

  const updateContactField = (updatedContact) => {
    props.updateContactInStore(updatedContact);
  }

  const labelDictionary = props.labels ? props.labels.contacts : null;

  const exportContent = () => {
    props.bulkExport({
      type: 'contacts'
    });
  };

  const getFieldLabel = (field, type) => {
    if (props.labels) {
      return extractFieldLabel(field, type, props.labels)
    } else {
      return '';
    }
  };

  const hideBulkImportModal = () => {
    setShowBulkImportModal(false);
  };

  const showBulkImport = () => {
    setShowBulkImportModal(true);
  };

  const viewContactDetails = (event, contactQID, householdQID) => {
    event.stopPropagation();
    history.push({
      pathname: '/households/' + householdQID,
      state: {
        contactQID: contactQID
      }
    });
  };

  // const ListTableColumns = (dataType, labels, getValue ) => {
  // 	let defaultColumns = {}
  // 	if(typeof labels != 'undefined')
  // 	{
  // 		const columns = labels[dataType];
  // 		const columnsArray = Object.keys(columns);
  // 		return columnsArray.map((el) => {
  // 			if (columns[el].visibility.toUpperCase() === 'PUBLIC') {
  // 				defaultColumns = {...defaultColumns,[el]:getValue}
  // 				props.setTableColumnsPref(defaultColumns)
  // 				return defaultColumns
  // 			}
  // 		});
  // 	}

  // }

  return (
    <>
      {props.fetching && <ProgressBar />}
      <Helmet>
        <title>Contact - QMove</title>
      </Helmet>
      <Container>
        <div className={styles.pageHeader}>
          <h1>Contacts</h1>
          <div>
         
            <button disabled={props.isDownloading} onClick={() => exportContent()} className={styles.themeButton}>
              <span>Bulk Export</span>
              {props.isDownloading ? <CgSpinner className='icon-spin' style={{ strokeWidth: '1.2' }} /> : <BsDownload style={{ strokeWidth: '1.2' }} />}
            </button>
            <button onClick={() => showBulkImport()} className={styles.themeButton}>
              <span>Bulk Import</span>
              <BsUpload style={{ strokeWidth: '1.2' }} />
            </button>
						<button className={styles.themeButton}>
							<span>Add Individual</span>
							<BsPlus style={{ strokeWidth: '1.2' }} />
						</button>
          </div>
        </div>
        <ContactsList
          contacts={tabFilteredList}
          switchListView={setActiveFilter}
          getFieldLabel={getFieldLabel}
          labels={labelDictionary}
          updateContact={updateContact}
          updateContactField={updateContactField}
          fetchingContacts={props.fetchingContacts}
          columnPref={props.columnPref}
          viewContactDetails={viewContactDetails}
        />
      </Container>
      {showBulkImportModal &&
        <BulkImport
          hideBulkImportModal={hideBulkImportModal}
          importType={'contacts'}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  contacts: selectContacts(state),
  labels: selectLabels(state),
  fetchingContacts: selectIsFetchingContacts(state),
  fetching: selectIsFetching(state),
  isDownloading: selectIsDownloadingFile(state),
  columnPref: selectTableColumnsPref(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchContacts: () => dispatch(Creators.fetchContacts()),
  fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
  updateContact: (contact) => dispatch(Creators.updateContact(contact)),
  updateContactInStore: (contact) => dispatch(Creators.updateContactInStore(contact)),
  bulkExport: (data) => dispatch(Creators.bulkExport(data)),
  setTableColumnsPref: (data) => dispatch(Creators.setTableColumnsPref(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contacts);
