import React, { useEffect } from 'react';
import { searchQueryToObject } from '../helpers';

const DocusignCallback = () => {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.location && window.location.search) {
				const queryParams = searchQueryToObject(window.location.search);
				let redirectUrl = window.location.origin;
				if (queryParams.state) {
					redirectUrl = window.location.origin + '/households/' + queryParams.state + '?consent=true';
				}
				window.location.replace(redirectUrl)
			}
		}
	}, []);
	return null;
};

export default DocusignCallback;
