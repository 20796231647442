import React from 'react';
import { AppLayout, Box } from '@qwealth/qcore';

const QApp = (props) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			style={{ cursor: 'pointer' }}
		>
			<div>
				<img onClick={props.onClick} src={props.img} alt={props.alt} />
			</div>
			<AppLayout.SideNavCollapsedHidden>
				<Box fontSize="12px">{props.name}</Box>
			</AppLayout.SideNavCollapsedHidden>
		</Box>
	);
};

export default QApp;
