import React, { useState } from 'react';
import cx from 'classnames';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import styles from './tableRow.module.scss';
import {getRandomId, calculateHouseholdTotalMissingFields, getParseMissingFieldsString} from '../../helpers';

const renderContactRows = (members = [], labels, viewContactDetails, householdQID) => {
	if (members.length > 0) {
		return members.map(el => {
			return (
				<tr key={getRandomId(el.contact.contactQID)}>
					<td>
						{el.contact.firstName + ' ' + el.contact.lastName}
					</td>
					<td colSpan={labels.length + 3}>
						{getParseMissingFieldsString(el.contact.missing_fields.fields)}
					</td>
					<td className={cx(styles.textCenter, styles.tableColumnSmall)}>
						<span className={styles.badge}>{el.missingFields.totalMissing}</span>
					</td>
					<td className={cx(styles.textCenter, styles.tableColumnSmall)}>
						<button onClick={(e) => viewContactDetails(e, el.contact.contactQID, householdQID)} className={styles.themeTableAction}>Edit</button>
					</td>
					<td>
						&nbsp;
					</td>
				</tr>
			);
		})
	} else {
		return (
			<tr key={getRandomId()}>
				<td colSpan={8} style={{ textAlign: 'center' }}>
					No contacts found
				</td>
			</tr>
		);
	}
}

const TableRowExpandable = (props) => {
	const [isExpanded, setIsExpanded] = useState();

	const toggleRow = () => {
		setIsExpanded(!isExpanded);
	};

	const formatNumber = (number) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		})
		return formatter.format(number);
	};

	const renderRows = (household, labelsArray, rowAction, columns) => {
		if (household !== null) {
			if (typeof columns !== 'undefined' && columns) {
				return columns.map((el, index) => {
					if (el['enabled'] === true) {
						return (
							<td key={index}>
								{household[el['key']]}
							</td>
						)
					}
				})

			} else {
				return labelsArray.map((el, index) => {
					if (el !== 'aum' && el !== 'household') {
						return (
							<td key={index}>
								{household[el]}
							</td>
						)
					}
				})
			}
		}
	}


	return (
		<>
			<tr
				onClick={toggleRow}
				key={getRandomId(props.household.householdQID)}
				className={cx(isExpanded && styles.nestedTableRowContainer)}
			>
				{renderRows(props.household, props.labelsArray, props.rowAction, props.columns)}
				{/* <td>
					{props.household.householdName}
				</td>
				<td>
					{formatNumber(props.household.aum)}
				</td>
				<td>
					Digital
				</td>
				<td>
					HJAB
				</td>
				<td>
					Theresa Webb
				</td> */}
				<td className={cx(styles.textCenter, styles.tableColumnSmall)}>
					{/*<span className={styles.badge}>{props.household.missing_fields.totalMissing}</span>*/}
					<span className={styles.badge}>{props.household.totalMissingFields}</span>
				</td>
				<td className={cx(styles.textCenter, styles.tableColumnSmall)}>
					<button onClick={(e) => props.action(e, props.household.householdQID)} className={styles.themeTableAction}>Edit</button>
				</td>
				<td className={cx(styles.textCenter, styles.tableColumnExSmall)}>
					{!isExpanded ? <BsChevronDown /> : <BsChevronUp />}
				</td>
			</tr>
			{isExpanded &&
				<tr key={getRandomId()}>
					<td className={cx(styles.nestedTableContainer)} colSpan={props.labelsArray.length + 3}>
						<table className={cx(styles.table, styles.nestedTable)}>
							<thead>
								<tr>
									<th>
										Contact
									</th>
									<th colSpan={props.labelsArray.length + 3}>
										Missing Info
									</th>
									<th>
										&nbsp;
									</th>
									<th>
										&nbsp;
									</th>
									<th>
										&nbsp;
									</th>
								</tr>
							</thead>
							<tbody>
								{renderContactRows(props.household.household_members, props.labelsArray, props.viewContactDetails, props.household.householdQID)}
							</tbody>
						</table>
					</td>
				</tr>
			}
			<tr key={getRandomId()} className={styles.spacerRow}></tr>
		</>
	);
};

export default TableRowExpandable;
