import React, { useState, useEffect } from 'react';
import styles from './accountsList.module.scss';
import TableRow from '../tableRow/tableRow';
import cx from 'classnames';
import { getRandomId, sortTableColumn, getEnabledColumnsFromColumnPref, getVisibleLabels } from '../../helpers';
import { BsChevronDown, BsChevronExpand, BsChevronUp } from 'react-icons/bs';
import { toNumber } from 'lodash';
import { toCurrency } from '@qwealth/qcore';
import { getAvailableOwnershipTypes, getHouseholdPrimary, getHouseholdSecondary } from '../accountForm/helpers';

const renderAccounts = ({ accounts, labelsArray, editAccount, tableColumnsPres, primary, secondary }) => {
  if (accounts && accounts.length > 0) {
    return accounts.map((el) => {
      const ownershipTypes = getAvailableOwnershipTypes(primary, secondary);
      const ownershipType = ownershipTypes.find(o => o.type === el.ownershipType);
      const expectedValue = el.relinquishingAccount.reduce((n, { expectedValue }) => n + toNumber(expectedValue), 0);

      const accountMod = {
        ...el,
        ownership: ownershipType ? ownershipType.name : el.ownershipType,
        expectedValue: toCurrency(expectedValue)
      }
      return (
        <TableRow
          key={getRandomId()}
          account={accountMod}
          labelsArray={labelsArray}
          rowAction={editAccount}
          columns={tableColumnsPres}
        />
      );
    });
  } else {
    return (
      <tr key={getRandomId()}>
        <td colSpan={tableColumnsPres?.length} className={styles.textCenter}>
          No data found.
        </td>
      </tr>
    );
  }
};

const renderTableHeaderColumns = (labelsArray, labels, getSortIndicator, sortColumn, tableColumnsPref) => {
  if (typeof labels !== 'undefined' && labels !== null) {
    if (typeof tableColumnsPref !== 'undefined' && tableColumnsPref !== null) {
      return tableColumnsPref?.map((el, index) => {
        if (el[ 'enabled' ] === true) {
          return (
            <th key={index}>
              <button onClick={(e) => sortColumn(e, el)}>
                {labels[ el[ 'key' ] ].en} {getSortIndicator(el)}
              </button>
            </th>
          )
        }
      })
    } else {
      return labelsArray.map((el, index) => {
        if (el !== 'aum' && el !== 'household') {
          return (
            <th key={index}>
              <button onClick={(e) => sortColumn(e, el)}>
                {labels[ el ].en} {getSortIndicator(el)}
              </button>
            </th>
          )
        }
      })
    }

  }
};

const AccountsListTable = (props) => {
  const [ sortConfig, setSortConfig ] = useState(null);
  const [ sortedData, setSortedData ] = useState([]);
  const [ columns, setColumns ] = useState({})
  const primary = getHouseholdPrimary(props.household);
  const secondary = getHouseholdSecondary(props.household);

  useEffect(() => {
    setSortedData(props.accounts);
  }, [ props.accounts ]);

  useEffect(() => {
    if (typeof props.columnPref !== 'undefined' && props.columnPref !== null) {
      if (typeof props.columnPref.accounts !== 'undefined') {
        setColumns(getEnabledColumnsFromColumnPref(props.columnPref.accounts));
      } else {
        if (typeof props.labels !== 'undefined' && props.labels !== null) {
          setColumns(getVisibleLabels(props.labels))
        }
      }
    } else {
      if (typeof props.labels !== 'undefined' && props.labels !== null) {
        setColumns(getVisibleLabels(props.labels))
      }
    }
  }, [ props.columnPref, props.labels ]);

  useEffect(() => {
    if (sortConfig) {
      const sortResult = sortTableColumn(sortConfig.key, sortedData, sortConfig.direction);
      setSortedData(sortResult);
    }
  }, [ sortConfig ]);

  const getSortIndicator = (key) => {
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
        return <BsChevronDown />
      } else if (sortConfig.key === key && sortConfig.direction === 'dec') {
        return <BsChevronUp />
      }
    }
    return <BsChevronExpand />
  };

  const sortColumn = (e, key) => {
    let direction = 'asc';
    if (sortConfig) {
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'dec';
      }
    }
    setSortConfig({ key, direction });
  };

  let labelsArray = [];
  if (props.labels) {
    labelsArray = Object.keys(props.labels);
  }

  return (
    <>
      <table className={cx(styles.table, styles.tableWithEditableRows)}>
        <thead>
          <tr>
            {renderTableHeaderColumns(labelsArray, props.labels, getSortIndicator, sortColumn, props.columnPref?.accounts)}
            <th className={styles.fixedColumn}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key={getRandomId()} className={styles.spacerRow}></tr>
          {renderAccounts({
            accounts: sortedData,
            labelsArray,
            editAccount: props.editAccount,
            tableColumnsPres: props.columnPref?.accounts,
            primary,
            secondary
          })}
        </tbody>
      </table>
    </>
  );
};

export default AccountsListTable;
