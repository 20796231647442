import React from 'react';
import { Box } from '@qwealth/qcore';
import ClientsSummaryTable from './clientsSummaryTable.js';
import styles from './clients.module.scss';
import {BsChevronRight, BsFillCaretUpFill} from 'react-icons/bs';
import { getTotalElementsWhichNeedAction } from '../../helpers';

const ClientsSummary = (props) => {
	return (
		<>
			<Box
				backgroundColor="#FFFFFF"
				mb="large"
				display="flex"
				justifyContent="flex-end"
				borderRadius="12px"
				padding="20px"
				flexDirection="column"
			>
				<div className={styles.summaryBoxHeader}>
					<div>
						<h2 className={styles.summaryHeader}>
							Clients
						</h2>
					</div>
					<div>
						<div>
							<p>Clients</p>
							<span>
								{props.contacts.length}
							</span>
						</div>
						<div>
							<p>Need Action</p>
							<span className={styles.needAction}>
								{getTotalElementsWhichNeedAction(props.contacts)} <BsFillCaretUpFill />
							</span>
						</div>
					</div>
				</div>
				<ClientsSummaryTable
					contacts={props.contacts.slice(0, 3)}
					fetchingContacts={props.fetchingContacts}
				/>
				<div className={styles.summaryBoxFooter}>
					<button onClick={props.onViewAll} className={styles.viewAllButton}>View All <BsChevronRight /></button>
				</div>
			</Box>
		</>
	);
}

export default ClientsSummary;
