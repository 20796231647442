import React, { useEffect, useState } from 'react';
import { Container } from '@qwealth/qcore';
import styles from './households.module.scss';
import { BsUpload, BsPlus, BsDownload } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';
import { HouseholdsList, BulkImport } from '../../components';
import { connect } from 'react-redux';
import { selectLabels, selectIsDownloadingFile, selectHouseholds, selectIsFetchingHouseholds, selectTableColumnsPref } from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { useHistory } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import { useTabFilter } from '../../hooks/useTabFilter';
import { TabFilters } from '../../constants';

const Households = (props) => {
  const [ activeFilter, setActiveFilter ] = useState(TabFilters.all);
  const tabFilteredList = useTabFilter({activeFilter, dataList: props.households});

  const history = useHistory();
  const [ showBulkImportModal, setShowBulkImportModal ] = useState(false);

  useEffect(() => {
    props.fetchGeneralSettings();
    props.fetchRelinquishingInstitutions();

  }, [])

  const viewHouseholdDetails = (event, householdQID) => {
    event.stopPropagation();
    history.push('/households/' + householdQID);
  };

  const createNewHousehold = () => {
    history.push('/households/new');
  };

  const viewContactDetails = (event, contactQID, householdQID) => {
    event.stopPropagation();
    history.push({
      pathname: '/households/' + householdQID,
      state: {
        contactQID: contactQID
      }
    });
  };

  const showBulkUploadModal = () => {
    setShowBulkImportModal(true);
  };

  const hideBulkImportModal = () => {
    setShowBulkImportModal(false);
  }

  const exportContent = () => {
    props.bulkExport({
      type: 'households'
    });
  };

  const labelDictionary = props.labels ? props.labels.households : null;

  return (
    <>
      <Helmet>
        <title>Households - QMove</title>
      </Helmet>
      <Container
        backgroundColor={'#E5E5E5'}
      >
        <div className={styles.pageHeader}>
          <h1>Households</h1>
          <div>
         
						<button disabled={props.isDownloading} onClick={() => exportContent()} className={styles.themeButton}>
							<span>Bulk Export</span>
							{props.isDownloading ? <CgSpinner className='icon-spin' style={{ strokeWidth: '1.2' }} /> : <BsDownload style={{ strokeWidth: '1.2' }} />}
						</button>
						<button onClick={() => showBulkUploadModal()} className={styles.themeButton}>
							<span>Bulk Import</span>
							<BsUpload style={{ strokeWidth: '1.2' }} />
						</button>
						<button onClick={() => createNewHousehold()} className={styles.themeButton}>
							<span>Add Individual</span>
							<BsPlus style={{ strokeWidth: '1.2' }} />
						</button> 
          </div>
        </div>
        <HouseholdsList
          labels={labelDictionary}
          households={tabFilteredList}
          switchListView={setActiveFilter}
          onEdit={viewHouseholdDetails}
          fetchingHouseholds={props.fetchingHouseholds}
          viewContactDetails={viewContactDetails}
          columnPref={props.columnPref}
        />
      </Container>
      {showBulkImportModal &&
        <BulkImport
          hideBulkImportModal={hideBulkImportModal}
          importType={'households'}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  labels: selectLabels(state),
  households: selectHouseholds(state),
  fetchingHouseholds: selectIsFetchingHouseholds(state),
  isDownloading: selectIsDownloadingFile(state),
  columnPref: selectTableColumnsPref(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
  bulkExport: (data) => dispatch(Creators.bulkExport(data)),
  fetchRelinquishingInstitutions: () => dispatch(Creators.fetchRelinquishingInstitutions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Households);
