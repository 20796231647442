import React, { useRef } from 'react';
import { Box } from '@qwealth/qcore';
import { InputForm, ConditionallyRequiredFieldLabel } from './Common';
import {
  PAYOUT_ACCOUNT_AGE_DETERMINATION_OPTIONS,
  PAYOUT_ACCOUNT_PAYMENT_FREQUENCIES,
  PAYOUT_ACCOUNT_ELECTED_BLENDED_TAX_OPTIONS,
  QUALIFIED_UNQUALIFIED_OPTIONS,
} from '@qwealth/qdata';
import RFFReactSelectQM from '../formControl/ReactFinalForm/RFFReactSelectQM';
import RFFTextInputQM from '../formControl/ReactFinalForm/RFFTextInputQM';

const FIELD_SUBSCRIPTION = { value: true };
const VALIDATABLE_FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

export const PayoutInfoForm = ({ isOnboardingComplete }) => {
  const constRefs = useRef({
    payoutAccountAgeDeterminationOptions: [...PAYOUT_ACCOUNT_AGE_DETERMINATION_OPTIONS],
    qualifiedUnqualifiedOptions: [...QUALIFIED_UNQUALIFIED_OPTIONS],
    payoutAccountPaymentFrequencies: [...PAYOUT_ACCOUNT_PAYMENT_FREQUENCIES],
    payoutAccountElectedBlendedTaxOptions: [...PAYOUT_ACCOUNT_ELECTED_BLENDED_TAX_OPTIONS],
  });
  const {
    payoutAccountAgeDeterminationOptions,
    qualifiedUnqualifiedOptions,
    payoutAccountPaymentFrequencies,
    payoutAccountElectedBlendedTaxOptions,
  } = constRefs.current;

  return (
    <Box
      mb="large"
      display="grid"
      gap="large"
      gridTemplateColumns={{
        phone: '1fr',
        desktop: '1fr 1fr 1fr',
      }}
    >
      <InputForm>
        <RFFReactSelectQM
          label="Payments Based On Whose Birth Date?"
          name="payoutAccountDetails.determinationOfAge"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: payoutAccountAgeDeterminationOptions,
          }}
        />
      </InputForm>
      <InputForm>
        <RFFReactSelectQM
          label="Qualified RIF (Opened Before Jan 1, 1993)"
          name="payoutAccountDetails.qualifiedUnqualified"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: qualifiedUnqualifiedOptions,
          }}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Payment Start Date"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name="payoutAccountDetails.paymentStartDate"
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
          inputFieldProps={{
            type: 'date',
          }}
        />
      </InputForm>
      <InputForm>
        <RFFReactSelectQM
          label="Payment Frequency"
          name="payoutAccountDetails.paymentFrequency"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: payoutAccountPaymentFrequencies,
          }}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Payment Date"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name="payoutAccountDetails.paymentDate"
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
          inputFieldProps={{
            type: 'date',
          }}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Payment Amount"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name="payoutAccountDetails.paymentAmount"
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
          inputFieldProps={{
            type: 'number',
          }}
        />
      </InputForm>
      <InputForm>
        <RFFReactSelectQM
          label="Withholding Tax"
          name="payoutAccountDetails.electedBlendedTax"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: payoutAccountElectedBlendedTaxOptions,
          }}
        />
      </InputForm>
    </Box>
  );
};
