import React from 'react';
import { Box } from '@qwealth/qcore';
import { InputForm, ConditionallyRequiredFieldLabel } from './Common';
import RFFTextInputQM from '../formControl/ReactFinalForm/RFFTextInputQM';
import RFFReactSelectQM from '../formControl/ReactFinalForm/RFFReactSelectQM';
import RFFCurrencyInputQM from '../formControl/ReactFinalForm/RFFCurrencyInputQM';

const FIELD_SUBSCRIPTION = { value: true };
const VALIDATABLE_FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

const RelinquishingAccountForm = ({
  propKey,
  relinquishingInstitutions,
  transferTypes,
  isOnboardingComplete,
}) => {
  return (
    <Box
      display="grid"
      gap="large"
      gridTemplateColumns={{
        phone: '1fr',
        xLarge: 'repeat(2, 2fr)',
      }}
    >
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="What is the account number that should be transferred?"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name={`${propKey}.relinquishingAccountNumber`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
        />
      </InputForm>
      <InputForm>
        <RFFReactSelectQM
          label="How would you like this account to be transferred?"
          name={`${propKey}.transferType`}
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: transferTypes,
          }}
        />
      </InputForm>
      <InputForm>
        <RFFReactSelectQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Which Institution is this account being transferred from?"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name={`${propKey}.relinquishingInstitutionID`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: relinquishingInstitutions,
            toLabel: item => `${item.institutionName} ${item.address ? `- ${item.address}` : ''}`,
            toValue: item => item.id,
          }}
        />
      </InputForm>
      <InputForm>
        <RFFCurrencyInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Expected Value"
              isConditionMet={isOnboardingComplete}
            />
          )}
          name={`${propKey}.expectedValue`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
        />
      </InputForm>
    </Box>
  );
};

export default RelinquishingAccountForm;
