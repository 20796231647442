import React from 'react';
import styles from './searchForm.module.scss';
import { BsSearch } from 'react-icons/bs';

const SearchForm = (props) => {
	return (
		<>
			<div className={styles.formContainer}>
				<span><BsSearch /></span>
				<input
					type="text"
					onKeyUp={(e) => props.formAction(e)}
					className={styles.inputField}
					placeholder={props.placeholder}
				/>
			</div>
		</>
	);
};

export default SearchForm;
