import axios from 'axios';

export const createApi = () => {
  return axios.create({
    baseURL: `${process.env.REACT_APP_QMOVE_API_URL}`,
    timeout: 60000,
  })
}
export default axios.create({
  baseURL: `${process.env.REACT_APP_QMOVE_API_URL}`,
  timeout: 60000,
  headers: {
    'Accept': 'application/json, */*',
  }
});
