// select data from the redux store

import { savingHousehold } from "./AppRedux";

export const selectHouseholds = (state) => {
  return state.app.households;
};

export const selectContacts = (state) => {
  return state.app.contacts;
};

export const selectAccounts = (state) => {
  return state.app.accounts;
};

export const selectLabels = (state) => {
  return state.app.labels;
};

export const selectHousehold = (state) => {
  if (state.app.activeHousehold !== null) {
    return state.app[ state.app.activeHousehold ];
  } else {
    return null;
  }
};

export const selectHouseholdsCount = (state) => {
  return state.app.households.length;
};

export const selectHouseholdAccounts = (householdQID) => (state) => state.app.accounts.filter(a => a.accountHouseholdQID === householdQID);

export const selectContactsCount = (state) => {
  return state.app.contacts.length;
};

export const selectAccountsCount = (state) => {
  return state.app.accounts.length;
};

export const selectEnumerations = (state) => {
  return state.app.enumerations;
};

export const selectIsFetchingHouseholds = (state) => {
  return state.app.isFetchingHouseholds;
};

export const selectIsFetchingContacts = (state) => {
  return state.app.isFetchingContacts;
};

export const selectIsFetchingAccounts = (state) => {
  return state.app.isFetchingAccounts;
};

export const selectIsFetching = (state) => {
  return state.app.isFetching;
};

export const isFetchingGeneralSettings = (state) => {
  return state.app.fetchingSettings;
};

export const selectImportLogs = (state) => {
  return state.app.importLogs;
};

export const selectIsDownloadingFile = (state) => {
  return state.app.isDownloadingFile;
};

export const selectDocusignConsentUrl = (state) => {
  return state.app.docusignConsentUrl;
};

export const selectDocusignEmbeddedSendingUrl = (state) => {
  return state.app.docusignEmbeddedSendingUrl;
};

export const selectTableColumnsPref = (state) => {
  return state.app.tableColumnsPref;
};

export const selectIsSavingHousehold = (state) => {
  return state.app.savingHousehold;
};

export const selectRelinquishingInstitutions = (state) => {
  return state.app.relinquishingInstitutions;
};

export const selectIsFetchingRelinquishingInstitutions = (state) => {
  return state.app.isFetchingRelinquishingInstitutions;
};

export const selectRelinquishingInstitutionsCount = (state) => {
  return state.app.relinquishingInstitutions.length;
};
