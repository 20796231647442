import React, { useEffect, useState } from 'react';
import styles from './contactsList.module.scss';
import TableRowEditable from '../tableRow/tableRowEditable';
import cx from 'classnames';
import { getRandomId, sortTableColumn } from '../../helpers';
import { BsChevronDown, BsChevronExpand, BsChevronUp } from 'react-icons/bs';

const renderContacts = (contacts, labelsArray, labels, viewContactDetails, updateContact, updateContactField, pref) => {
	if (contacts.length > 0) {
		return contacts.map((el, index) => {
			{
				return (
					<TableRowEditable
						key={index}
						contact={el}
						labelsArray={labelsArray}
						updateContact={updateContact}
						updateContactField={updateContactField}
						viewContactDetails={viewContactDetails}
						pref={pref}
					/>
				);
			}
		});
	} else {
		return (
			<tr key={getRandomId()}>
				<td colSpan={labelsArray.length} className={styles.textCenter}>
					No data found.
				</td>
			</tr>
		);
	}
};

const renderTableHeaderColumns = (labelsArray, labels, getSortIndicator, sortColumn, pref) => {
	if (labels !== null && labelsArray.length > 0) {
		if (typeof pref !== null && typeof pref !== 'undefined') {
			return pref?.map((el, index) => {
				if (el['enabled'] === true) {
					return (
						<th key={index}>
							<button onClick={(e) => sortColumn(e, el)}>
								{labels[el['key']].en} {getSortIndicator(el)}
							</button>
						</th>
					)
				}
			})
			
		} else {
			return labelsArray.map((el, index) => {
				return (
					<th key={index}>
						<button onClick={(e) => sortColumn(e, el)}>
							{labels[el].en} {getSortIndicator(el)}
						</button>
					</th>
				)
			})	
		}

		// 	return labelsArray.map((el, index) => {
		// 		// if(pref==null)
		// 		// {
		// 		// 	return (
		// 		// 		<th key={index}>
		// 		// 			<button onClick={(e) => sortColumn(e, el)}>
		// 		// 				{labels[el].en} {getSortIndicator(el)}
		// 		// 			</button>
		// 		// 		</th>
		// 		// 		)
		// 		// }	
		// 		// else{
		// 		// 	if(typeof pref != 'undefined')
		// 		// 	{
		// 		// 		if(pref[el]===true)
		// 		// 	{
		// 		// 		return (
		// 		// 		<th key={index}>
		// 		// 			<button onClick={(e) => sortColumn(e, el)}>
		// 		// 				{labels[el].en} {getSortIndicator(el)}
		// 		// 			</button>
		// 		// 		</th>
		// 		// 		)
		// 		// 	}
		// 		// 	}
		// 		// }
		// 		if (pref == null) {
		// 			return (
		// 				<th key={index}>
		// 					<button onClick={(e) => sortColumn(e, el)}>
		// 						{labels[el['key']]?.en} {getSortIndicator(el)}
		// 					</button>
		// 				</th>
		// 			)
		// 		}
		// 		else {
		// 			if (typeof pref != 'undefined') {
		// 				if (el['enabled'] === true) {
		// 					return (
		// 						<th key={index}>
		// 							<button onClick={(e) => sortColumn(e, el)}>
		// 								{labels[el['key']].en} {getSortIndicator(el)}
		// 							</button>
		// 						</th>
		// 					)
		// 				}
		// 			}
		// 		}

		// 	})
	}
};

const ContactsListTable = (props) => {
	const [sortConfig, setSortConfig] = useState(null);
	const [sortedContacts, setSortedContacts] = useState([]);

	useEffect(() => {
		setSortedContacts(props.contacts);
	}, [props.contacts]);

	useEffect(() => {
		if (sortConfig) {
			const sortResult = sortTableColumn(sortConfig.key, [...sortedContacts], sortConfig.direction);
			setSortedContacts(sortResult);
		}
	}, [sortConfig]);

	const getSortIndicator = (key) => {
		if (sortConfig) {
			if (sortConfig.key === key && sortConfig.direction === 'asc') {
				return <BsChevronDown />
			} else if (sortConfig.key === key && sortConfig.direction === 'dec') {
				return <BsChevronUp />
			}
		}
		return <BsChevronExpand />
	};

	const sortColumn = (e, key) => {
		let direction = 'asc';
		if (sortConfig) {
			if (sortConfig.key === key && sortConfig.direction === 'asc') {
				direction = 'dec';
			}
		}
		setSortConfig({ key, direction });
	};

	let labelsArray = [];
	if (props.labels) {
		labelsArray = Object.keys(props.labels);
	}
	return (
		<>
			<table className={cx(styles.table, styles.tableWithEditableRows)}>
				<thead>
					<tr>
						{renderTableHeaderColumns(labelsArray, props.labels, getSortIndicator, sortColumn, props.columnPref?.contacts)}
						<th className={styles.fixedColumn}>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{/*<tr key={getRandomId()} className={styles.spacerRow}></tr>*/}
					{renderContacts(sortedContacts, labelsArray, props.labels, props.viewContactDetails, props.updateContact, props.updateContactField, props.columnPref)}
				</tbody>
			</table>
		</>
	);
};

export default ContactsListTable;
