import React, { useCallback, useState, useEffect } from 'react';
import styles from './dropzone.module.scss';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { connect } from 'react-redux';
import Creators from '../../state/AppRedux';
import {
	selectIsFetching
} from '../../state/Selectors';

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};


const Dropzone = (props) => {
	
	const [base64File, setBase64File] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isVoidChequeDoc, setIsVoidChequeDoc] = useState(false);
	const [isIdentityDoc, setIsIdentityDoc] = useState(false);
	
	useEffect(() => {
		if (base64File) {
			if (isIdentityDoc) {
				const data = {
					identityId: props.identification.id,
					contact: props.contact,
					household: props.household,
					documentData:  {
						fileName: selectedFile.name,
						identityImage: base64File
					}
				};
				props.uploadIdentityDocument(data);
			}
			if (isVoidChequeDoc) {
				const data = {
					bankAccountId: props.bankAccountDetails.bankAccountId,
					contact: props.contact,
					household: props.household,
					documentData:  {
						fileName: selectedFile.name,
						voidChequeDocument: base64File
					}
				};
				props.uploadVoidChequeDocument(data);
			}
			// setBase64File(null);
			// setSelectedFile(null);
		}
	}, [base64File]);
	
	useEffect(() => {
		if (typeof props.bankAccountDetails !== 'undefined') {
			setIsVoidChequeDoc(true);
		} else {
			setIsVoidChequeDoc(false);
		}
		
		if (typeof props.identification !== 'undefined') {
			setIsIdentityDoc(true);
		} else {
			setIsIdentityDoc(false);
		}
	}, [props])
	
	const onDrop = useCallback(acceptedFiles => {
		if (acceptedFiles && acceptedFiles.length > 0) {
			const file = acceptedFiles[0];
			setSelectedFile(file);
			const reader = new FileReader();
			reader.onloadend = () => {
				setBase64File(reader.result);
			};
			reader.readAsDataURL(file);
		}
	}, []);
	
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: false,
		disabled: (isIdentityDoc && (!props.identification || !props.identification.id)) || (isVoidChequeDoc && (!props.bankAccountDetails || !props.bankAccountDetails.bankAccountId)),
		accept: 'image/jpeg, image/png, image/jpg, image/png, .pdf'
	});
	
	const thumbs = () => {
		if (selectedFile) {
			return (
				<div style={thumb} key={setSelectedFile.name}>
					<div style={thumbInner}>
						<img
							src={base64File}
							style={img}
						/>
					</div>
				</div>
			);
		}
	}
	
	return (
		<>
			<div className={cx(styles.dropzoneContainer, ((isIdentityDoc && (!props.identification || !props.identification.id)) || (isVoidChequeDoc && (!props.bankAccountDetails || !props.bankAccountDetails.bankAccountId))) && styles.disabled)}>
				<label>{props.label}</label>
				<div {...getRootProps()} className={cx(styles.dropzone, isDragActive && styles.dropzoneActive)}>
					<input {...getInputProps()} />
					{isDragActive &&
							<p>Drop the files here ...</p>
					}
					{!isDragActive && !selectedFile &&
					<div>
						<p>
							Drag 'n' Drop your file here
						</p>
						<p>
							Or click to select a file
						</p>
					</div>
					}
					{selectedFile && thumbs()}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	isFetching: selectIsFetching(state)
});

const mapDispatchToProps = (dispatch) => ({
	uploadIdentityDocument: (data) => dispatch(Creators.uploadIdentityDocument(data)),
	uploadVoidChequeDocument: (data) => dispatch(Creators.uploadVoidChequeDocument(data))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dropzone);
