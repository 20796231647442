import axios from 'axios';
import { createApi } from './index';

function fetchGeneralSettings(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/general/settings', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const settingsServices = {
  fetchGeneralSettings
};
