import React, { useState, useEffect } from 'react';
import { Box } from '@qwealth/qcore';
import styles from './relinquishingInstitutionsList.module.scss';
import { searchRelinquishingInstitutionList } from '../../helpers';
import cx from 'classnames';
import SearchForm from '../searchForm/searchForm';
import RelinquishingInstitutionsListTable from './relinquishingInstitutionsListTable';
import LoadingIndicator from '../loading/loading';
import PopOver from '../popover/popover'
import { GoSettings } from 'react-icons/go';

const RelinquishingInstitutionsList = (props) => {
  const [ relinquishingInstitutionsFiltered, setRelinquishingInstitutionsFiltered ] = useState([]);
  const [ showColumnsPrefModal, setShowColumnsPrefModal ] = useState(false)

  useEffect(() => {
    setRelinquishingInstitutionsFiltered(props.relinquishingInstitutions);
  }, [ props.relinquishingInstitutions ]);

  const performSearch = (e) => {
    const filteredList = searchRelinquishingInstitutionList(props.relinquishingInstitutions, e.target.value);
    setRelinquishingInstitutionsFiltered(filteredList);
  };

  const showTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(true)
  };

  const hideTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(false)
  };

  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px"
        flexDirection="column"
        marginTop="20px"
      >
        <div className={styles.listContainerHeader}>
          <div className={styles.positionRelative}>
            <div className={styles.positionRelative}>
              {showColumnsPrefModal &&
                <PopOver
                  hideModal={hideTableColumnsSelectionModal}
                  editTableColumns={props.editTableColumns}
                  dataType='relinquishingInstitution'
                />
              }
            </div>
            <button onClick={showTableColumnsSelectionModal} className={cx(styles.editColumnsButton, styles.positionRelative)}><GoSettings /> Select Columns</button>
            <SearchForm
              formAction={performSearch}
              placeholder={'Search...'}
            />
          </div>
        </div>

        <div className={styles.tableContainer}>
          {props.fetchingAccounts ?
            <LoadingIndicator size='small' height='500px' />
            :
            <RelinquishingInstitutionsListTable
              labels={props.labels}
              relinquishingInstitutions={relinquishingInstitutionsFiltered}
              columnPref={props.columnPref}
            />
          }
        </div>
      </Box>
    </>
  );
};

export default RelinquishingInstitutionsList;
