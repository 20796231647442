import React, { useCallback, useRef } from 'react';
import { Box } from '@qwealth/qcore';
import { useFormState } from 'react-final-form';
import { GENDERS, isAccountRESPType } from '@qwealth/qdata';
import { InputForm, ConditionallyRequiredFieldLabel } from './Common';
import RFFReactSelectQM from '../formControl/ReactFinalForm/RFFReactSelectQM';
import RFFTextInputQM from '../formControl/ReactFinalForm/RFFTextInputQM';

const FIELD_SUBSCRIPTION = { value: true };
const VALIDATABLE_FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

const BeneficiaryForm = ({ propKey, isOnboardingComplete }) => {
  const { values } = useFormState();
  const { accountType } = values;
  const constRefs = useRef({
    genders: [...GENDERS],
  });
  const { genders } = constRefs.current;
  
  const getBeneficiaryTypes = useCallback(() => {
    let benTypes = ['Beneficiary', 'Contingent'];
    if (isAccountRESPType(accountType)) {
      benTypes = ['RESP'];
    }
    return benTypes;
  }, [accountType]);

  return (
    <Box
      display="grid"
      gap="large"
      gridTemplateColumns={{
        phone: '1fr',
        xLarge: 'repeat(3, 1fr)',
      }}
    >
      <InputForm>
        <RFFReactSelectQM
          label="Beneficiary Type"
          name={`${propKey}.beneficiaryType`}
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: getBeneficiaryTypes(),
          }}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="First Name"
              isConditionMet
            />
          )}
          name={`${propKey}.extBeneficiaryFirstName`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Last Name"
              isConditionMet
            />
          )}
          name={`${propKey}.extBeneficiaryLastName`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Percentage Allocation"
              isConditionMet
            />
          )}
          name={`${propKey}.percentageAllocation`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
          inputFieldProps={{
            type: 'number',
          }}
        />
      </InputForm>
      <InputForm>
        <RFFTextInputQM
          label={() => (
            <ConditionallyRequiredFieldLabel
              labelText="Social Insurance Number"
              isConditionMet={isOnboardingComplete || isAccountRESPType(accountType)}
            />
          )}
          name={`${propKey}.extBeneficiarySin`}
          subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
        />
      </InputForm>
      {isAccountRESPType(accountType) && (
        <>
          <InputForm>
            <RFFReactSelectQM
              label={() => <ConditionallyRequiredFieldLabel labelText="Gender" isConditionMet />}
              name={`${propKey}.extBeneficiaryGender`}
              subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
              inputFieldProps={{
                options: genders,
              }}
            />
          </InputForm>
          <InputForm>
            <RFFTextInputQM
              label={() => (
                <ConditionallyRequiredFieldLabel
                  labelText="Date Of Birth"
                  isConditionMet={isOnboardingComplete || isAccountRESPType(accountType)}
                />
              )}
              name={`${propKey}.extBeneficiaryDateOfBirth`}
              subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
              inputFieldProps={{
                type: 'date',
              }}
            />
          </InputForm>
        </>
      )}
    </Box>
  );
};

export default BeneficiaryForm;
