import React from 'react';
import styles from './radioButton.module.scss';

const RadioButton = (props) => {
	return (
		<div className={styles.radioButtonContainer}>
			<label>{props.label}
				<input
					type='radio'
					name={props.name || ''}
					value={props.value || ''}
					checked={props.checked}
					onChange={(e) => { props.updateValue('', e.target.value, props.pathPrefix + props.name) }}
				/>
				<span></span>
			</label>
		</div>
	);
}

export default RadioButton;
