import React, { useEffect, useState } from 'react';
import styles from './tableRow.module.scss';
import cx from 'classnames';
import { getRandomId } from '../../helpers';
import _ from 'lodash';

const renderContactFields = (contact, labelsArray, updateContact, pref) => {
	if (contact !== null) {
		if (typeof pref === 'undefined' && pref !== null) {
			return labelsArray.map((el, index) => {
				return (
					<td key={getRandomId()}>
						<input
							type="text"
							defaultValue={contact[el]}
							name={el}
							className={cx(styles.inlineTextField, (contact[el] === null || contact[el] === '') && styles.inlineTextFieldMissing)}
							onBlur={(e) => updateContact(e)}
						/>
					</td>
				)
			})
		} else {
			return pref?.map((el, index) => {
				if (el['enabled'] === true) {
					return (
						<td key={getRandomId()}>
						<input
							type="text"
							defaultValue={contact[el['key']]}
							name={el}
							className={cx(styles.inlineTextField, (contact[el['key']] === null || contact[el['key']] === '') && styles.inlineTextFieldMissing)}
							onBlur={(e) => updateContact(e)}
						/>
					</td>
					)
				}
			})
		}
	}
}

const TableRowEditable = (props) => {
	const [contact, setContact] = useState(props.contact);

	useEffect(() => {
		setContact(props.contact);
	}, [props.contact]);

	useEffect(() => {
		if (contact !== null && !_.isEqual(contact, props.contact)) {
			props.updateContact(contact);
		}
	}, [contact]);

	const updateContactField = (e) => {
		setContact({
			...contact,
			[e.target.name]: e.target.value
		});
	};

	const navigate = (e) => {
		props.viewContactDetails(e, contact.contactQID, contact.householdQID)
	}

	return (
		<>
			<tr>
				{renderContactFields(contact, props.labelsArray, updateContactField, props.pref?.contacts)}
				<td className={cx(styles.textCenter, styles.actionContainer)}>
					{contact.householdQID && <button onClick={(e) => navigate(e)} className={styles.themeTableAction}>Edit</button>}
					{/*TODO: ADD A METHOD TO ASSIGN THE CONTACT TO A CERTAIN HOUSEHOLD*/}
				</td>
			</tr>
		</>
	);
}

export default TableRowEditable;
