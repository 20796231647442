import React, { useState } from 'react';
import styles from './contactDetails.module.scss';
import { Box } from '@qwealth/qcore';
import cx from 'classnames';
import ContactInfo from '../contactInfo/contactInfo';
import ContactVerification from '../contactVerification/contactVerification';
import RegulatoryInfo from '../regulatoryInfo/regulatoryInfo';

const ContactDetails = (props) => {
  const [ activeTab, setActiveTab ] = useState('INFO');
  const contactIndex = props.activeContactIndex;

  const switchTab = (tabToActive) => {
    setActiveTab(tabToActive);
  };

  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px 40px"
        flexDirection="column"
        marginTop="20px"
        marginBottom="100px"
      >
        <div>
          <div className={styles.tabNavContainer}>
            <button onClick={() => switchTab('INFO')} className={cx(activeTab === 'INFO' && styles.activeBtn)}>
              Info <span className={styles.badge}>{(props.missingFields && props.missingFields[ props.activeContact ]) ? props.missingFields[ props.activeContact ].contactInfo : 0}</span>
            </button>
            <button onClick={() => switchTab('VERIFICATION')} className={cx(activeTab === 'VERIFICATION' && styles.activeBtn)}>
              Verification <span className={styles.badge}>{(props.missingFields && props.missingFields[ props.activeContact ]) ? props.missingFields[ props.activeContact ].contactVerification : 0}</span>
            </button>
            <button onClick={() => switchTab('REGULATORY')} className={cx(activeTab === 'REGULATORY' && styles.activeBtn)}>
              Regulatory Info
            </button>
          </div>
        </div>
        {activeTab === 'INFO' &&
          <ContactInfo
            getFieldLabel={props.getFieldLabel}
            contact={props.contact}
            update={props.update}
            contactIndex={contactIndex}
            getOptions={props.getOptions}
            activeContactIndex={props.activeContactIndex}
            contacts={props.household.household_members}
            updateSpouse={props.updateSpouse}
            isFieldRequired={props.isFieldRequired}
          />
        }
        {activeTab === 'VERIFICATION' &&
          <ContactVerification
            getFieldLabel={props.getFieldLabel}
            contact={props.contact}
            update={props.update}
            contactIndex={contactIndex}
            getOptions={props.getOptions}
            household={props.household}
            isFieldRequired={props.isFieldRequired}
          />
        }
        {activeTab === 'REGULATORY' &&
          <RegulatoryInfo
            getFieldLabel={props.getFieldLabel}
            contact={props.contact}
            update={props.update}
            contactIndex={contactIndex}
            getOptions={props.getOptions}
            saveAccount={props.saveAccount}
            updateAccount={props.updateAccount}
          />
        }
      </Box>
    </>
  );
};

export default ContactDetails;
