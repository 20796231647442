import React from 'react';
import styles from './tableRow.module.scss';
import accounts from "../../containers/accounts/accounts";

const renderRows = (account, labelsArray, columns) => {
    if (account !== null) {
        if (typeof columns !== 'undefined' && typeof columns !== null) {
            return columns?.map((el, index) => {
                if (el['enabled'] === true) {
                    if (el['key'] === 'unitNumber' || el['key'] === 'streetNumber' || el['key'] === 'streetName' || el['key'] === 'city' || el['key'] === 'provinceOrState' || el['key'] === 'country' || el['key'] === 'postalCode') {
                        if (account.address !== null && account.address !== undefined) {
                            return (
                                <td key={index}>
                                    {account.address[el['key']]}
                                </td>
                            );
                        } else {
                            return (
                                <td key={index}>

                                </td>
                            );
                        }
                    }
                    else {
                        return (
                            <td key={index}>
                                {account[el['key']]}
                            </td>
                        );
                    }
                }
            });
        } else {
            return labelsArray.map((el, index) => {
                if (el !== 'id') {
                    if (el === 'unitNumber' || el === 'streetNumber' || el === 'streetName' || el === 'city' || el === 'provinceOrState' || el === 'country' || el === 'postalCode') {
                        if (account.address !== null && account.address !== undefined) {
                            return (
                                <td key={index}>
                                    {account.address[el]}
                                </td>
                            );
                        }
                    } else {
                        return (
                            <td key={index}>
                                {account[el]}
                            </td>
                        )
                    }
                }
            })
        }

    }
}

const renderRowsAddress = (accounts, labelsArray, columns) => {
    if (accounts.address !== null) {
        if (typeof columns !== 'undefined' && typeof columns !== null) {
            return columns?.map((el, index) => {
                if (el['enabled'] === true) {
                    return (
                        <td key={index}>
                            {accounts.address[el['key']]}
                        </td>
                    );
                }
            });
        } else {
            return labelsArray.map((el, index) => {
                return (
                    <td key={index}>
                        {accounts.address[el]}
                    </td>
                )
            })
        }

    }
}
const TableRowRelinquishingInstitutions = (props) => {
    return (
        <>
            <tr>
                {renderRows(props.account, props.labelsArray, props.columns)}
            </tr>
        </>
    );
}

export default TableRowRelinquishingInstitutions;
