import React, {useEffect, useState} from 'react';
import styles from './regulatoryInfo.module.scss';
import RadioButton from '../radioButton/radioButton';
import FormControl from '../formControl/formControl';
import cx from 'classnames';
import { REGULATORY_INF0 } from '../../constants';

const RegulatoryInfo = (props) => {
	//const [regulatoryInfo, setRegulatoryInfo] = useState(REGULATORY_INF0);
	const getLabel = props.getFieldLabel;
	const contact = props.contact;
	const regulatoryInfo = props.contact.regulatory ? props.contact.regulatory : REGULATORY_INF0;
	
	return(
		<>
			<h3 className={styles.styledHeading}>
				Regulatory Information
			</h3>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('insider', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'insider'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.insider.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'insider'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.insider.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.insider.toUpperCase() === 'YES' &&
						<FormControl
							label={getLabel('insiderTicker', 'regulatory')}
							value={regulatoryInfo.insiderTicker}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
							name='insiderTicker'
						/>
					}
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('control', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'control'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.control.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'control'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.control.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.control.toUpperCase() === 'YES' &&
						<FormControl
							label={getLabel('controlTicker', 'regulatory')}
							value={regulatoryInfo.controlTicker}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
							name='controlTicker'
						/>
					}
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('authority', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'authority'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.authority.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'authority'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.authority.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.authority.toUpperCase() === 'YES' &&
						<FormControl
							label={getLabel('authorityNature', 'regulatory')}
							value={regulatoryInfo.authorityNature}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
							name='authorityNature'
						/>
					}
				</div>
			</div>
			<div className={cx(styles.formControlContainer, styles.questionOnly)}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('thirdParty', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'thirdParty'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.thirdParty.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'thirdParty'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.thirdParty.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
				
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('registered', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'registered'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.registered.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'registered'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.registered.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.registered.toUpperCase() === 'YES' &&
						<>
							<FormControl
								label={getLabel('registeredName', 'regulatory')}
								value={regulatoryInfo.registeredName}
								pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
								updateValue={props.update}
								name='registeredName'
							/>
							<FormControl
								label={getLabel('registeredEmployer', 'regulatory')}
								value={regulatoryInfo.registeredEmployer}
								pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
								updateValue={props.update}
								name='registeredEmployer'
							/>
						</>
					}
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('outsideAccount', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'outsideAccount'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.outsideAccount.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'outsideAccount'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.outsideAccount.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.outsideAccount.toUpperCase() === 'YES' &&
						<FormControl
							label={getLabel('outsideAccountSpecify', 'regulatory')}
							value={regulatoryInfo.outsideAccountSpecify}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
							name='outsideAccountSpecify'
						/>
					}
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('guarantee', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'guarantee'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.guarantee.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'guarantee'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.guarantee.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
					{regulatoryInfo.guarantee.toUpperCase() === 'YES' &&
						<FormControl
							label={getLabel('guaranteeName', 'regulatory')}
							value={regulatoryInfo.guaranteeName}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
							name='guaranteeName'
						/>
					}
				</div>
			</div>
			<div className={cx(styles.formControlContainer, styles.questionOnly)}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('foreignPep', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'foreignPep'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.foreignPep.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'foreignPep'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.foreignPep.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
				
				</div>
			</div>
			<div className={cx(styles.formControlContainer, styles.questionOnly)}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('domesticPep', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'domesticPep'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.domesticPep.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'domesticPep'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.domesticPep.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
				
				</div>
			</div>
			<div className={cx(styles.formControlContainer, styles.questionOnly)}>
				<div className={styles.radioContainer}>
					<div className={styles.labelText}>{getLabel('headOfIntOrg', 'regulatory')}</div>
					<div>
						<RadioButton
							name={'headOfIntOrg'}
							value={'Yes'}
							label={'Yes'}
							checked={regulatoryInfo.headOfIntOrg.toUpperCase() === 'YES'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
					<div>
						<RadioButton
							name={'headOfIntOrg'}
							value={'No'}
							label={'No'}
							checked={regulatoryInfo.headOfIntOrg.toUpperCase() === 'NO'}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.regulatory.'}
							updateValue={props.update}
						/>
					</div>
				</div>
				<div>
				
				</div>
			</div>
		</>
	);
};

export default RegulatoryInfo;
