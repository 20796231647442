import React from 'react';
import styles from './contactInfo.module.scss';
import FormControl from '../formControl/formControl';
import AddressForm from '../addressForm/addressForm';
import { getProspectSpousalContacts } from '../../helpers';
import { relationshipTypesWithRelatedContacts } from '../../constants';

// TODO: REMOVE THIS FUNCTION AND SHOW THE ADDRESS DIRECTLY IN THE RETURN METHOD OF THE FUNCTION

const renderEmployerAddress = (contact, getLabel, contactIndex, update, addAddress, isFieldRequired) => {
  return (
    <>
      <AddressForm
        getFieldLabel={getLabel}
        address={contact && contact.contact_income_detail ? contact.contact_income_detail.address : null}
        pathPrefix={'household_members.[' + contactIndex + '].contact.contact_income_detail.address.'}
        onUpdate={update}
        isFieldRequired={isFieldRequired}
      />
    </>
  );
};

const ContactInfo = (props) => {
  const getLabel = props.getFieldLabel;
  const contact = props.contact;
  return (
    <>
      <div>
        <h3 className={styles.styledHeading}>
          Basic Info
        </h3>
      </div>
      <div className={styles.formControlContainer}>
        <div>
          <FormControl
            label={getLabel('firstName', 'contacts')}
            value={contact ? contact.firstName : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='firstName'
            required={props.isFieldRequired('firstName', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('middleName', 'contacts')}
            value={contact ? contact.middleName : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='middleName'
            required={props.isFieldRequired('middleName', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('lastName', 'contacts')}
            value={contact ? contact.lastName : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='lastName'
            required={props.isFieldRequired('lastName', 'contacts')}
          />
        </div>
      </div>
      <div className={styles.formControlContainer}>
        <div>
          <FormControl
            label={getLabel('dateOfBirth', 'contacts')}
            type={'Date'}
            value={contact ? contact.dateOfBirth : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='dateOfBirth'
            required={props.isFieldRequired('dateOfBirth', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('SIN', 'contacts')}
            value={contact ? contact.SIN : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='SIN'
            required={props.isFieldRequired('SIN', 'contacts')}
          />
        </div>
        {/* Temp Hide: QC-1669 
					<FormControl
						label={getLabel('contactType', 'contacts')}
						type={'Dropdown'}
						options={props.getOptions('contactType', 'contacts')}
						value={contact && contact.contactType ? contact.contactType : ''}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
						updateValue={props.update}
						name='contactType'
						required={props.isFieldRequired('contactType', 'contacts')}
					/>
				</div> */}
        <div>
          <FormControl
            label={getLabel('annualIncome', 'contacts')}
            value={contact ? contact.annualIncome : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='annualIncome'
            required={props.isFieldRequired('annualIncome', 'contacts')}
          />
        </div>
      </div>
      <div className={styles.formControlContainer}>

        <div>
          <FormControl
            label={getLabel('netWorth', 'contacts')}
            value={contact ? contact.netWorth : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='netWorth'
            required={props.isFieldRequired('netWorth', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('citizenship', 'contacts')}
            value={contact ? contact.citizenship : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='citizenship'
            required={props.isFieldRequired('citizenship', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('maritalStatus', 'contacts')}
            type={'Dropdown'}
            options={props.getOptions('maritalStatus', 'contacts')}
            value={contact && contact.maritalStatus ? contact.maritalStatus : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='maritalStatus'
            required={props.isFieldRequired('maritalStatus', 'contacts')}
          />
        </div>
      </div>
      <div className={styles.formControlContainer}>
        <div>
          {contact && contact.maritalStatus && relationshipTypesWithRelatedContacts.includes(contact.maritalStatus) &&
            <FormControl
              label={'Related Contact'}
              type={'Dropdown'}
              options={getProspectSpousalContacts(props.contacts, props.activeContactIndex)}
              value={contact && contact.spouse ? contact.spouse : ''}
              pathPrefix={'household_members.[' + props.contactIndex + '].contact.related_contact.'}
              updateValue={(emptyArg, value, path) => props.updateSpouse(value, path)}
              name='contactQID2'
            />
          }
        </div>
        <div>{/* SPACER */}</div>
        <div>{/* SPACER */}</div>
      </div>
      <div>
        <h3 className={styles.styledHeading}>
          Contact Info
        </h3>
      </div>
      <div className={styles.formControlContainer}>
        <div>
          <FormControl
            label={getLabel('primaryEmail', 'contacts')}
            value={contact ? contact.primaryEmail : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='primaryEmail'
            required={props.isFieldRequired('primaryEmail', 'contacts')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('primaryPhone', 'contacts')}
            value={contact ? contact.primaryPhone : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.'}
            updateValue={props.update}
            name='primaryPhone'
            required={props.isFieldRequired('primaryPhone', 'contacts')}
          />
        </div>
        <div>
          {/*SPACER*/}
        </div>
      </div>
      <div>
        <h3 className={styles.styledHeading}>
          Address
        </h3>
      </div>
      <div className={styles.formControlContainer}>
        <FormControl
          label={'Same as Household address'}
          type={'Checkbox'}
          checked={contact.addressSameAsHousehold !== null ? contact.addressSameAsHousehold : false}
          onChange={(e) => { props.update(e, !contact.addressSameAsHousehold, 'household_members[' + props.contactIndex + '].contact.addressSameAsHousehold'); }}
          name={'addressSameAsHousehold'}
        />
      </div>
      <div>
        {contact && !contact.addressSameAsHousehold &&
          <AddressForm
            getFieldLabel={getLabel}
            address={contact && contact.address ? contact.address : null}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.address.'}
            onUpdate={props.update}
            isFieldRequired={props.isFieldRequired}
          />
        }
      </div>
      <div>
        <h3 className={styles.styledHeading}>
          Employer Information
        </h3>
      </div>
      <div className={styles.formControlContainer}>
        <div>
          <FormControl
            label={getLabel('employmentStatus', 'contactIncomeDetails')}
            type={'Dropdown'}
            options={props.getOptions('employmentStatus', 'contactIncomeDetails')}
            value={contact && contact.contact_income_detail ? contact.contact_income_detail.employmentStatus : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.contact_income_detail.'}
            updateValue={props.update}
            name='employmentStatus'
            required={props.isFieldRequired('employmentStatus', 'contactIncomeDetails')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('employerName', 'contactIncomeDetails')}
            value={contact && contact.contact_income_detail ? contact.contact_income_detail.employerName : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.contact_income_detail.'}
            updateValue={props.update}
            name='employerName'
            required={props.isFieldRequired('employerName', 'contactIncomeDetails')}
          />
        </div>
        <div>
          {/*TODO: THIS FIELD IS SET AS A VARCHAR IN THE DB INSTEAD OF AN ENUM FOR DROPDOWN*/}
          <FormControl
            label={getLabel('employerIndustry', 'contactIncomeDetails')}
            /*type={'Dropdown'}
            options={props.getOptions('employerIndustry', 'contacts')}*/
            value={contact && contact.contact_income_detail ? contact.contact_income_detail.employerIndustry : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.contact_income_detail.'}
            updateValue={props.update}
            name='employerIndustry'
            required={props.isFieldRequired('employerIndustry', 'contactIncomeDetails')}
          />
        </div>
        <div>
          <FormControl
            label={getLabel('occupation', 'contactIncomeDetails')}
            value={contact && contact.contact_income_detail ? contact.contact_income_detail.occupation : ''}
            pathPrefix={'household_members.[' + props.contactIndex + '].contact.contact_income_detail.'}
            updateValue={props.update}
            name='occupation'
            required={props.isFieldRequired('occupation', 'contactIncomeDetails')}
          />
        </div>
      </div>
      <div>
        <h3 className={styles.styledHeading}>
          Employer Address
        </h3>
      </div>
      {renderEmployerAddress(contact, getLabel, props.contactIndex, props.update, props.addNewEmploymentAddress, props.isFieldRequired)}
    </>
  );
};

export default ContactInfo;
