import React, { useState, useEffect } from 'react';
import styles from './relinquishingInstitutionsList.module.scss';
import TableRowRelinquishingInstitutions from '../tableRow/tableRowRelinquishingInstitutions';
import cx from 'classnames';
import { getRandomId, sortTableColumn, getEnabledColumnsFromColumnPref, getVisibleLabels } from '../../helpers';
import { BsChevronDown, BsChevronExpand, BsChevronUp } from 'react-icons/bs';

const renderRelinquishingInstitution = (accounts, labelsArray, editAccount, tableColumnsPres) => {
    if (accounts.length > 0) {
        return accounts.map((el) => {
            return (
                <TableRowRelinquishingInstitutions
                    key={getRandomId()}
                    account={el}
                    labelsArray={labelsArray}
                    columns={tableColumnsPres}
                />
            );
        });
    } else {
        return (
            <tr key={getRandomId()}>
                <td colSpan={tableColumnsPres?.length} className={styles.textCenter}>
                    No data found.
                </td>
            </tr>
        );
    }
};

const renderTableHeaderColumns = (labelsArray, labels, getSortIndicator, sortColumn, tableColumnsPref) => {
    if (typeof labels !== 'undefined' && labels !== null) {
        if (typeof tableColumnsPref !== 'undefined' && tableColumnsPref !== null) {
            return tableColumnsPref?.map((el, index) => {
                if (el['enabled'] === true) {
                    return (
                        <th key={index}>
                            <button onClick={(e) => sortColumn(e, el)}>
                                {labels[el['key']].en} {getSortIndicator(el)}
                            </button>
                        </th>
                    )
                }
            })
        } else {
            return labelsArray.map((el, index) => {
                if (el !== 'id') {
                    return (
                        <th key={index}>
                            <button onClick={(e) => sortColumn(e, el)}>
                                {labels[el].en} {getSortIndicator(el)}
                            </button>
                        </th>
                    )
                }
            })
        }
    }
};

const RelinquishingInstitutionsListTable = (props) => {
    const [sortConfig, setSortConfig] = useState(null);
    const [sortedData, setSortedData] = useState([]);
    const [columns, setColumns] = useState({})

    useEffect(() => {
        setSortedData(props.relinquishingInstitutions);
    }, [props.relinquishingInstitutions]);

    useEffect(() => {
        if (typeof props.columnPref !== 'undefined' && props.columnPref !== null) {
            if (typeof props.columnPref.relinquishingInstitutions !== 'undefined') {
                setColumns(getEnabledColumnsFromColumnPref(props.columnPref.relinquishingInstitution));
            } else {
                if (typeof props.labels !== 'undefined' && props.labels !== null) {
                    setColumns(getVisibleLabels(props.labels))
                }
            }
        } else {
            if (typeof props.labels !== 'undefined' && props.labels !== null) {
                setColumns(getVisibleLabels(props.labels))
            }
        }
    }, [props.columnPref, props.labels]);

    useEffect(() => {
        if (sortConfig) {
            const sortResult = sortTableColumn(sortConfig.key, sortedData, sortConfig.direction);
            setSortedData(sortResult);
        }
    }, [sortConfig]);

    const getSortIndicator = (key) => {
        if (sortConfig) {
            if (sortConfig.key === key && sortConfig.direction === 'asc') {
                return <BsChevronDown />
            } else if (sortConfig.key === key && sortConfig.direction === 'dec') {
                return <BsChevronUp />
            }
        }
        return <BsChevronExpand />
    };

    const sortColumn = (e, key) => {
        let direction = 'asc';
        if (sortConfig) {
            if (sortConfig.key === key && sortConfig.direction === 'asc') {
                direction = 'dec';
            }
        }
        setSortConfig({ key, direction });
    };

    let labelsArray = [];
    if (props.labels) {
        labelsArray = Object.keys(props.labels);
    }

    return (
        <>
            <table className={cx(styles.table, styles.tableWithEditableRows)}>
                <thead>
                    <tr>
                        {renderTableHeaderColumns(labelsArray, props.labels, getSortIndicator, sortColumn, props.columnPref?.relinquishingInstitution)}
                    </tr>
                </thead>
                <tbody>
                    <tr key={getRandomId()} className={styles.spacerRow}></tr>
                    {renderRelinquishingInstitution(sortedData, labelsArray, props.editAccount, props.columnPref?.relinquishingInstitution)}
                </tbody>
            </table>
        </>
    );
};

export default RelinquishingInstitutionsListTable;
