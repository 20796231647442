import { put, call } from 'redux-saga/effects';
import Creators from '../state/AppRedux';
import { bulkUploadServices } from '../services/bulkUploadServices';
import { toast } from 'react-toastify';
import moment from 'moment';

export function * bulkUPload(data) {
	yield put(Creators.isFetching(true));
	let result = null;
	try {
		result = yield call(bulkUploadServices.bulkUpload, data);
		console.log(result);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetching(false));
			//yield put(Creators.updateContactRes(result.data.response));
			if (result.data.response.total_records === result.data.response.processed_records) {
				toast.success('CSV Was imported successfully and all records were imported. Check Import Logs to learn more.');
			} else if ((result.data.response.total_records > result.data.response.processed_records) && result.data.response.processed_records !== 0) {
				toast.warning('CSV imported successfully. ' + result.data.response.processed_records + ' records out of ' + result.data.response.total_records + ' were processed. Check Import Logs to learn more.');
			} else if (result.data.response.processed_records === 0) {
				toast.warning(result.data.response.processed_records + ' records out of ' + result.data.response.total_records + ' were processed. Check Import Logs to learn more.');
			} else {
				// do nothing
			}
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetching(false));
		console.log(e);
	}
}

export function * getBulkImportLogs(data) {
	yield put(Creators.isFetching(true));
	let result = null;
	try {
		result = yield call(bulkUploadServices.getBulkUploadLogs, data);
		console.log(result);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetching(false));
			const sortedLogs = result.data.response.sort((a, b) => {
				return new Date(b.startTime) - new Date(a.startTime);
			});
			yield put(Creators.setImportLogs(sortedLogs));
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetching(false));
		console.log(e);
	}
}

export function * bulkExport(data) {
	yield put(Creators.downloading(true));
	let result = null;
	try {
		result = yield call(bulkUploadServices.bulkExport, data);
		if (result.status && result.status === 200) {
			let hiddenElement = document.createElement('a');
			hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(result.data);
			hiddenElement.target = '_blank';
			hiddenElement.download = data.data.type + '_QMOVE_EXPORT_' + moment(new Date()).format("YYYY_MM_DD_h:mm:ss_a") + '.csv';
			hiddenElement.click();
			yield put(Creators.downloading(false));
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.downloading(false));
		console.log(e);
	}
}
