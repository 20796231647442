import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { Form, useForm, useFormState } from 'react-final-form';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Box, useInitializeFieldValues} from '@qwealth/qcore';
import {
	isAccountNoBeneficiaryType,
	isAccountSuccessorBeneficiaryType,
	hasBeneficiariesInAccount,
	includeSpouseAsSuccessor,
	isAccountRESPType,
	hasParentLegalGuardian,
	isAccountEligibleForPayoutInfo,
	shouldTransferExternalAccounts,
} from '@qwealth/qdata';
import styles from './accountForm.module.scss';
import { InputForm, ConditionallyRequiredFieldLabel } from './Common';
import { getAvailableOwnershipTypes, getHouseholdPrimary, getHouseholdSecondary } from './helpers';
import { BsX } from 'react-icons/bs';
import {
	OPENING_ACCOUNT_JURISDICTIONS,
	OPENING_ACCOUNT_TYPES,
	OPENING_ACCOUNT_PURPOSES_OF_ACCOUNT,
	OPENING_ACCOUNT_SOURCES_OF_FUNDS,
	RELINQUISHING_ACCOUNT_TRANSFER_TYPES,
	YES_OR_NO_OPTIONS,
	PAYOUT_ACCOUNT_PAYMENT_DATES,
	PAYOUT_ACCOUNT_PAYMENT_AMOUNTS,
	OPENING_ACCOUNT_STATUSES,
	getJurisdictionsByAccountType,
	validateOpeningAccount
} from '@qwealth/qdata';
import RFFReactSelectQM from '../formControl/ReactFinalForm/RFFReactSelectQM';
import RFFTextInputQM from '../formControl/ReactFinalForm/RFFTextInputQM';
import BeneficiaryForm from './BeneficiaryForm';
import RelinquishingAccountForm from './RelinquishingAccountForm';
import { PayoutInfoForm } from './PayoutInfoForm';
import { ParentLegalGuardianForm } from './ParentLegalGuardianForm';
import { selectHouseholds } from '../../state/Selectors';
import { saveOrUpdateAccount } from '../../data/actions/accounts';
import { useHistory } from 'react-router';

const StyledFormSectionTitle = ({ children }) => <h3 className={styles.styledHeading} >{children}</h3>
const StyledFormSectionItemTitle = ({ children }) => <h3 className={styles.styledSubHeading} >{children}</h3>
const StyledFormSectionItemActionButton = ({ children, className, ...props }) => <button className={`${styles.removeBeneficiary} ${className}`} {...props}>{children}</button>
const AddButton = ({ children, className, ...props }) => <button className={`${styles.addMoreBtn} ${className}`} {...props}>{children}</button>


const FIELD_SUBSCRIPTION = { value: true };
const VALIDATABLE_FIELD_SUBSCRIPTION = { value: true, touched: true, error: true };

const getDefaultContact = () => ({
	QID: '',
	firstName: '',
	lastName: '',
	SIN: '',
	contactType: 'None',
	status: 'Active',
	gender: 'Male',
	dateOfBirth: '',
})

const getDefaultBeneficiary = () => ({
	id: 0,
	beneficiaryQID: '',
	percentageAllocation: 0,
	accountNumber: '',
	beneficiaryType: 'Beneficiary',
	extBeneficiaryFirstName: '',
	extBeneficiaryLastName: '',
	extBeneficiarySin: '',
	extBeneficiaryGender: 'Male',
	extBeneficiaryDateOfBirth: '',
	beneficiary: getDefaultContact(),
});

const getDefaultRelinquishingAccount = () => ({
	id: 0,
	relinquishingAccountNumber: '',
	accountNumber: '',
	expectedValue: 0,
	transferType: 'In Cash',
	relinquishingInstitutionID: 0,
	accountType: '',
});

const defaultAccountFormData = {
  id: 0,
  purposeOfAccount: 'Short-term Savings',
  institutionName: '',
  accountNumber: '',
  accountHouseholdQID: '',
  accountOwnersQID: '',
  inclSpouseAsSuccessor: 'No',
  accountType: 'Cash CAD',
  ownershipType: 'Primary',
  accountTitle: '',
  sourceOfFunds: 'Employment Earnings',
  beneficiariesInAccount: 'No',
  jurisdiction: '',
  status: OPENING_ACCOUNT_STATUSES[0],
  accountBeneficiaries: [getDefaultBeneficiary()],
  educationDisabilityAccountDetailsID: '',
  educationDisabilityAccountDetail: {
    id: 0,
    parentGuardianQID: '',
    accountSubType: '',
    marketValue: 0,
    bookValue: 0,
    accountOpenDate: moment().format('YYYY-MM-DD'),
    parentGuardian: {
      QID: '',
      firstName: '',
      lastName: '',
      SIN: '',
      primaryEmail: '',
      contactType: 'None',
      status: 'Active',
    },
  },
  subscriberParentLegalGuardian: 'No',
  payoutAccountDetails: {
    accountNumber: '',
    determinationOfAge: 'Annuitant',
    qualifiedUnqualified: 'Qualified',
    paymentStartDate: moment().format('YYYY-MM-DD'),
    paymentFrequency: 'Monthly',
    paymentDate: moment().format('YYYY-MM-DD'),
    paymentAmount: 0,
    electedBlendedTax: 'Elected',
    allocationCAD: 0,
    allocationUSD: 0,
    paymentMethod: 'EFT to Bank',
  },
  tansferExternalAccounts: 'No',
  relinquishingAccount: [getDefaultRelinquishingAccount()],
  deleted_account_beneficiary: [],
	deleted_relinquishing_account: [],
};


const AccountFormInternal = ({
	isOnboardingComplete,
	account,
	household
}) => {
	const householdList = useSelector(selectHouseholds);
	const [showHouseholdList, setShowHouseholdList] = useState(false);
	const [activeHousehold, setActiveHousehold] = useState(household);
	const primaryContact = getHouseholdPrimary(activeHousehold);
	const secondaryContact = getHouseholdSecondary(activeHousehold);
	const constRefs = useRef({
		OpeningAccountTypes: [ ...OPENING_ACCOUNT_TYPES ],
		purposesOfAccount: [ ...OPENING_ACCOUNT_PURPOSES_OF_ACCOUNT ],
		sourcesOfFunds: [ ...OPENING_ACCOUNT_SOURCES_OF_FUNDS ],
		openingAccountJurisdictions: [ ...OPENING_ACCOUNT_JURISDICTIONS ],
		transferTypes: [ ...RELINQUISHING_ACCOUNT_TRANSFER_TYPES ],
		yesOrNoOptions: [ ...YES_OR_NO_OPTIONS ],
		payoutAccountPaymentDates: [ ...PAYOUT_ACCOUNT_PAYMENT_DATES ],
		payoutAccountPaymentAmounts: [ ...PAYOUT_ACCOUNT_PAYMENT_AMOUNTS ],
	});
	const { OpeningAccountTypes, purposesOfAccount, sourcesOfFunds, transferTypes, yesOrNoOptions } =
		constRefs.current;

	const [ ownershipTypes, setOwnershipTypes ] = useState([]);
	const [ jurisdictions, setJurisdictions ] = useState([]);
	const relinquishingInstitutions = useSelector(state => state.app.relinquishingInstitutions);
	const { change } = useForm();
	const { values } = useFormState();
	const {
		relinqhishingInstitutionID,
		accountType,
		beneficiariesInAccount,
		inclSpouseAsSuccessor,
		subscriberParentLegalGuardian,
		tansferExternalAccounts,
		ownershipType,
		jurisdiction,
		accountHouseholdQID,
		relinquishingAccount,
    deleted_relinquishing_account,
    accountBeneficiaries,
    deleted_account_beneficiary,
	} = values;

	useInitializeFieldValues(() => {
		let data = {};
		
		if(activeHousehold){
			data.accountHouseholdQID = activeHousehold.householdQID;
		}
		if(primaryContact){
			data.accountOwnersQID = primaryContact.memberQID;
		}

		if (account) {
			const {
				accountBeneficiaries,
				relinquishing_institution,
				parentGuardian,
				payoutAccountDetails,
				educationDisabilityAccountDetail,
				relinquishingAccount,
				
				accountOwnership,
				accountHouseholdQID,
				accountOwnersQID,
				...accountData
			} = account;

			data = { ...accountData };
			data.inclSpouseAsSuccessor = data.inclSpouseAsSuccessor || 'No';
      data.beneficiariesInAccount = data.inclSpouseAsSuccessor === 'Yes' ? 'Yes' : 'No';

			if (accountHouseholdQID) {
				data.accountHouseholdQID = accountHouseholdQID;
			}
			if (accountOwnersQID) {
				data.accountOwnersQID = accountOwnersQID;
			}
			if (accountBeneficiaries) {
        data.accountBeneficiaries = accountBeneficiaries;
        if (accountBeneficiaries.length > 0) {
          data.beneficiariesInAccount = 'Yes';
        }
				data.accountBeneficiaries.forEach(ben => {
					if(!ben.beneficiary){
						ben.beneficiary = getDefaultContact();
					}
				})
      }
			if (relinquishing_institution) {
				data.relinquishing_institution = relinquishing_institution;
			}
			if (parentGuardian) {
				data.parentGuardian = parentGuardian;
				data.subscriberParentLegalGuardian = 'Yes';
			}
			if (payoutAccountDetails) {
				data.payoutAccountDetails = payoutAccountDetails;
			}
			if(educationDisabilityAccountDetail) {
				data.educationDisabilityAccountDetail = educationDisabilityAccountDetail;
			}
			if (relinquishingAccount) {
				data.relinquishingAccount = relinquishingAccount;
				if (relinquishingAccount.length > 0) {
					data.tansferExternalAccounts = 'Yes';
				}
			}
			if (accountOwnership) {
				data.accountOwnership = accountOwnership;
			}
		}
		return data;
	}, [ account ]);

	useEffect(() => {
		if (!household && !accountHouseholdQID && account && account.accountHouseholdQID) {
			const targetHousehold = householdList.find(h => h.householdQID === account.accountHouseholdQID);
			if (targetHousehold) {
				change('accountHouseholdQID', targetHousehold.householdQID );
			}
		}
		if(!household && !activeHousehold && (!account || !account.accountHouseholdQID)){
			setShowHouseholdList(true);
			if (householdList.length > 0) {
				change('accountHouseholdQID',householdList[ 0 ].householdQID );
			}
		}
	}, [ household, account, householdList, accountHouseholdQID, activeHousehold ])

	useEffect(() => {
		if (accountHouseholdQID) {
			const targetHousehold = householdList.find(h => h.householdQID === accountHouseholdQID);
			if (targetHousehold) {
				setActiveHousehold(targetHousehold);
			}
		}
	}, [accountHouseholdQID, setActiveHousehold, householdList])

	useEffect(() => {
    if (beneficiariesInAccount === 'No' && inclSpouseAsSuccessor === 'Yes') {
      change('inclSpouseAsSuccessor', 'No');
    }
  }, [beneficiariesInAccount]);

	useEffect(() => {
		setOwnershipTypes(getAvailableOwnershipTypes(primaryContact, secondaryContact));
	}, [ primaryContact, secondaryContact, setOwnershipTypes ]);

	// Set Jurisdictions
	useEffect(() => {
		const availableJurisdictions = getJurisdictionsByAccountType(accountType);
		setJurisdictions(availableJurisdictions);
		if (jurisdiction && isEmpty(availableJurisdictions)) {
			change('jurisdiction', '');
			return;
		}
		if (
			!isEmpty(availableJurisdictions) &&
			(!jurisdiction || availableJurisdictions.indexOf(jurisdiction) === -1)
		) {
			change('jurisdiction', availableJurisdictions[ 0 ]);
		}
	}, [ accountType, setJurisdictions, jurisdiction, change ]);

	// Assign correct ownership
	// Assign accountTitle according to ownership type and account type
	useEffect(() => {
		if (ownershipType === 'Primary' && primaryContact) {
      change('accountOwnersQID', primaryContact.memberQID);
      change('jointAccountOwnersQID', null);
      change(
        'accountTitle',
        `${primaryContact.contact.firstName} ${primaryContact.contact.lastName} ${accountType}`,
      );
    } else if (ownershipType === 'Secondary' && secondaryContact) {
      change('accountOwnersQID', secondaryContact.memberQID);
      change('jointAccountOwnersQID', null);
      change(
        'accountTitle',
        `${secondaryContact.contact.firstName} ${secondaryContact.contact.lastName} ${accountType}`,
      );
    } else if (ownershipType === 'Joint' && primaryContact && secondaryContact) {
      change('accountOwnersQID', primaryContact.memberQID);
      change('jointAccountOwnersQID', secondaryContact.memberQID);
      change(
        'accountTitle',
        primaryContact.contact.lastName === secondaryContact.contact.lastName
          ? `${primaryContact.contact.firstName} ${secondaryContact.contact.firstName} ${primaryContact.contact.lastName} ${accountType}`
          : `${primaryContact.contact.firstName} ${primaryContact.contact.lastName} ${secondaryContact.contact.firstName} ${secondaryContact.contact.lastName} ${accountType}`,
      );
    }
	}, [ accountType, ownershipType, primaryContact, secondaryContact, change ]);

	useEffect(() => {
		const relinquishingInstitution = relinquishingInstitutions.find(
			t => t.id === relinqhishingInstitutionID,
		);
		if (relinquishingInstitution) {
			change('relinquishing_institution', relinquishingInstitution);
		}
	}, [ relinqhishingInstitutionID, relinquishingInstitutions, change ]);

	return (
		<>
			<Box>
				<StyledFormSectionTitle>Account Info</StyledFormSectionTitle>
				<Box
					mb="large"
					display="grid"
					gap="large"
					gridTemplateColumns={{
						phone: '1fr',
						desktop: '1fr 1fr 1fr',
					}}
				>
					{showHouseholdList &&
						<InputForm>
							<RFFReactSelectQM
								label="Household"
								name="accountHouseholdQID"
								subscription={FIELD_SUBSCRIPTION}
								inputFieldProps={{
									options: householdList,
									toLabel: o => o.householdName,
									toValue: o => o.householdQID,
								}}
							/>
						</InputForm>
					}
					<InputForm>
						<RFFReactSelectQM
							label="Account Type"
							name="accountType"
							subscription={FIELD_SUBSCRIPTION}
							inputFieldProps={{
								options: OpeningAccountTypes,
							}}
						/>
					</InputForm>
					<InputForm>
						<RFFReactSelectQM
							label="Ownership"
							name="ownershipType"
							subscription={FIELD_SUBSCRIPTION}
							inputFieldProps={{
								options: ownershipTypes,
								toLabel: o => o.name,
								toValue: o => o.type,
							}}
						/>
					</InputForm>
					<InputForm>
						<RFFTextInputQM
							label={() => (
								<ConditionallyRequiredFieldLabel labelText="Account Title" isConditionMet={true} />
							)}
							name="accountTitle"
							subscription={VALIDATABLE_FIELD_SUBSCRIPTION}
						/>
					</InputForm>
					<InputForm>
						<RFFReactSelectQM
							label="Purpose Of Account"
							name="purposeOfAccount"
							subscription={FIELD_SUBSCRIPTION}
							inputFieldProps={{
								options: purposesOfAccount,
							}}
						/>
					</InputForm>
					<InputForm>
						<RFFReactSelectQM
							label="Source Of Funds"
							name="sourceOfFunds"
							subscription={FIELD_SUBSCRIPTION}
							inputFieldProps={{
								options: sourcesOfFunds,
							}}
						/>
					</InputForm>
					{jurisdictions.length > 0 && (
						<InputForm>
							<RFFReactSelectQM
								label="Jurisdiction"
								name="jurisdiction"
								subscription={FIELD_SUBSCRIPTION}
								inputFieldProps={{
									options: jurisdictions,
								}}
							/>
						</InputForm>
					)}
				</Box>
			</Box>
			{!isAccountNoBeneficiaryType(accountType) && (
				<Box>
					<StyledFormSectionTitle>Beneficiary Information</StyledFormSectionTitle>
					<Box
						mb="large"
						display="grid"
						gap="large"
						gridTemplateColumns={{
							phone: '1fr',
							desktop: '1fr 1fr 1fr',
						}}
					>
						<InputForm>
							<RFFReactSelectQM
								label="Beneficiaries"
								name="beneficiariesInAccount"
								subscription={FIELD_SUBSCRIPTION}
								inputFieldProps={{
									options: yesOrNoOptions,
								}}
							/>
						</InputForm>
					</Box>
					{isAccountSuccessorBeneficiaryType(accountType) &&
						hasBeneficiariesInAccount(beneficiariesInAccount) && (
							<Box
								mb="large"
								display="grid"
								gap="large"
								gridTemplateColumns={{
									phone: '1fr',
								}}
							>
								<InputForm>
									<RFFReactSelectQM
										label="Would you like your spouse (from “Demographic Information”)  to be listed as successor beneficiary to this account?"
										name="inclSpouseAsSuccessor"
										subscription={FIELD_SUBSCRIPTION}
										inputFieldProps={{
											options: yesOrNoOptions,
										}}
									/>
								</InputForm>
							</Box>
						)}
					{!includeSpouseAsSuccessor(inclSpouseAsSuccessor) &&
						hasBeneficiariesInAccount(beneficiariesInAccount) && (
							<FieldArray name="accountBeneficiaries">
								{({ fields }) => (
									<Box>
										{fields.map((propKey, index) => {
											return (
												<Box key={index}>
													<StyledFormSectionItemTitle>
														Beneficiary {index + 1}
														<StyledFormSectionItemActionButton
															onClick={() => {
																if (accountBeneficiaries[ index ]?.id) {
																	change('deleted_account_beneficiary', [
																		...deleted_account_beneficiary,
																		accountBeneficiaries[ index ].id,
																	]);
																}
																fields.remove(index);
															}}
														>
															Remove
														</StyledFormSectionItemActionButton>
													</StyledFormSectionItemTitle>
													<BeneficiaryForm
														propKey={propKey}
														isOnboardingComplete={isOnboardingComplete}
													/>
												</Box>
											);
										})}
										<Box
											display="flex"
											mt="default"
										>
											<AddButton onClick={(e) => {
												e.preventDefault();
												fields.push(getDefaultBeneficiary())
											}}>
												+ Add Beneficiary
											</AddButton>
										</Box>
									</Box>
								)}
							</FieldArray>
						)}
					{isAccountRESPType(accountType) && (
						<Box>
							<StyledFormSectionTitle>RESP/RDSP Information</StyledFormSectionTitle>
							<Box
								mb="large"
								display="grid"
								gap="large"
								gridTemplateColumns={{
									phone: '1fr',
									desktop: '1fr 1fr 1fr',
								}}
							>
								<InputForm>
									<RFFReactSelectQM
										label="Parent/Legal Guardian Of Beneficiaries"
										name="subscriberParentLegalGuardian"
										subscription={FIELD_SUBSCRIPTION}
										inputFieldProps={{
											options: yesOrNoOptions,
										}}
									/>
								</InputForm>
							</Box>
							{hasParentLegalGuardian(subscriberParentLegalGuardian) && (
								<Box>
									<StyledFormSectionItemTitle>
										Parent/Legal Guardian Information
									</StyledFormSectionItemTitle>
									<ParentLegalGuardianForm isOnboardingComplete={isOnboardingComplete} />
								</Box>
							)}
						</Box>
					)}
					{isAccountEligibleForPayoutInfo(accountType) && (
						<Box>
							<StyledFormSectionTitle>Payout Info</StyledFormSectionTitle>
							<PayoutInfoForm isOnboardingComplete={isOnboardingComplete} />
						</Box>
					)}
				</Box>
			)}
			<Box>
				<StyledFormSectionTitle>Relinquishing Accounts</StyledFormSectionTitle>
				<Box
					mb="large"
					display="grid"
					gap="large"
					gridTemplateColumns={{
						phone: '1fr',
						desktop: '1fr',
					}}
				>
					<InputForm>
						<RFFReactSelectQM
							label="Would you like to transfer in funds from another institution into your new account?"
							name="tansferExternalAccounts"
							subscription={FIELD_SUBSCRIPTION}
							inputFieldProps={{
								options: yesOrNoOptions,
							}}
						/>
					</InputForm>
				</Box>
				{shouldTransferExternalAccounts(tansferExternalAccounts) && (
					<FieldArray name="relinquishingAccount">
						{({ fields }) => (
							<Box>
								{fields.map((propKey, index) => {
									return (
										<Box key={index}>
											<StyledFormSectionItemTitle>
												Account {index + 1}
												<StyledFormSectionItemActionButton
													onClick={() => {
														if (relinquishingAccount[ index ]?.id) {
															change('deleted_relinquishing_account', [
																...deleted_relinquishing_account,
																relinquishingAccount[ index ].id,
															]);
														}
														fields.remove(index);
													}}
												>
													Remove
												</StyledFormSectionItemActionButton>
											</StyledFormSectionItemTitle>
											<RelinquishingAccountForm
												propKey={propKey}
												relinquishingInstitutions={relinquishingInstitutions}
												transferTypes={transferTypes}
												isOnboardingComplete={isOnboardingComplete}
											/>
										</Box>
									);
								})}
								<Box
									display="flex"
									mt="default"
								>
									<AddButton onClick={(e) => {
										e.preventDefault();
										fields.push(getDefaultRelinquishingAccount());
									}}>
										+ Add Account
									</AddButton>
								</Box>
							</Box>
						)}
					</FieldArray>
				)}
			</Box>
		</>
	)
}

const AccountForm = ({ isOnboardingComplete=false, ...props }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const householdList = useSelector(selectHouseholds);
	const tagetHousehold = useMemo(() => {
		if(!props.household && props.account){
			return householdList.find(h => h.householdQID === props.account.accountHouseholdQID);
		}
		return null;
	}, [props.household, props.account]) ;

	const handleSubmit = data => {
		dispatch(saveOrUpdateAccount(data));
		props.hideModal();
	};

	const validateForm = values => {
		const result = validateOpeningAccount(values, isOnboardingComplete);
		return result;
	};

	const navigateToTargetHousehold = useCallback(() => {
		if(tagetHousehold){
			history.push(`/households/${tagetHousehold.householdQID}`);
			props.hideModal();
		}
	}, [tagetHousehold, props.hideModal])
	return (
		<div className={styles.accountFormBackdropContainer}>
			<div className={styles.accountFormContainer}>
				<Form
					onSubmit={handleSubmit}
					initialValues={defaultAccountFormData}
					mutators={{
						...arrayMutators,
					}}
					validate={validateForm}
				>
					{({ handleSubmit, form: { reset } }) => {
						return (
							<form onSubmit={handleSubmit}>
								<div className={styles.modalHeader}>
									<Box className={styles.modalTitleContainer} display="flex" alignItems="center">
										<h3>
											{props.account ? 'Edit Account' : 'Create Account'}
										</h3>
										{
											tagetHousehold &&
											<button
												className={styles.primaryButton}
												onClick={(e) => {
													e.preventDefault();
													navigateToTargetHousehold();
												}}
											>
												Go to {tagetHousehold.householdName}
											</button>
										}
									</Box>
									<button onClick={props.hideModal} className={styles.closeButton}>
										<BsX style={{ strokeWidth: '1.2' }} />
									</button>
								</div>
								<div className={styles.modalBody}>
									<AccountFormInternal isOnboardingComplete={isOnboardingComplete} {...props} />
								</div>
								<div className={styles.modalFooter}>
									<button type="submit">
										Save
									</button>
									<button
										onClick={props.hideModal}
									>
										Cancel
									</button>
								</div>
							</form>
						);
					}}
				</Form>

			</div>
		</div>
	);
}

export default AccountForm;
