import React, { useEffect, useState } from 'react';
import styles from './tabsNav.module.scss';
import { getRandomId, getContactName } from '../../helpers';
import cx from 'classnames';

const renderTabsNavItems = (items, onSwitch) => {
  return items.map(item => {
    return <li key={getRandomId()}>
      <button
        className={cx(item.active && styles.active)}
        onClick={() => onSwitch(item.tabId)}
      >
        {item.title} <span className={styles.badge}>{item.missingInfo}</span>
      </button>
    </li>
  })
};

const getTabsNavItemsList = (household, activeTab, missingFields) => {
  //console.log(household, activeTab);
  if (household) {
    //console.log('household found');
    const itemsList = [
      {
        title: 'Household Overview',
        //missingInfo: getHouseholdOverviewMissingFields(household, labels),
        missingInfo: missingFields.householdOverview,
        active: activeTab === 'HOUSEHOLD',
        tabId: 'HOUSEHOLD'
      }
    ];
    if (household.household_members && household.household_members.length > 0) {
      household.household_members.map((el, index) => {
        const contactName = getContactName(el.contact);
        itemsList.push({
          title: contactName ? contactName : 'New Contact ' + (index + 1),
          //missingInfo: getContactsTotalMissingFields(el.contact, labels),
          missingInfo: missingFields[ el.contact.contactQID ] ? missingFields[ el.contact.contactQID ].totalMissing : 0,
          active: activeTab === el.contact.contactQID,
          tabId: el.contact.contactQID
        });
      });
    }
    return itemsList;
  } else {
    return [ {
      title: 'Household Overview',
      missingInfo: 0
    } ];
  }
};


const TabsNav = (props) => {
  const [ tabNavItems, setTabNavItems ] = useState([]);

  useEffect(() => {
    const items = getTabsNavItemsList(props.household, props.activeTab, props.missingFields);
    setTabNavItems(items);
  }, [ props.household, props.activeTab, props.missingFields ]);

  return (
    <>
      <div className={styles.tabsNavContainer}>
        <ul>
          {renderTabsNavItems(tabNavItems, props.switchTab)}
          {/* Temp Hide: QC-1669 
					<li>
						<button disabled={props.household && props.household.contacts && props.household.contacts.length > 0} className={styles.createNewContactBtn} onClick={() => props.addNewContact()}>+ Add Contact</button>
					</li> */}
        </ul>
      </div>
    </>
  );
};

export default TabsNav;
