import React from 'react';
import styles from './documentPreview.module.scss';
import { getFileExtensionFromFileName } from '../../helpers';
import pdfIcon from '../../assets/pdf.png';

const DocumentPreview = (props) => {
	const fileType = getFileExtensionFromFileName(props.fileName ? props.fileName : '');
	return (
		<div className={styles.documentPreviewContainer}>
			<div className={styles.content}>
				<label>
					{props.label}
				</label>
				{props.fileUrl ?
				<div className={styles.imageContainer}>
					{(fileType && fileType.toUpperCase() === 'PDF') ?
						<img className={styles.pdfIcon} src={pdfIcon} alt="" />
						:
						<img src={props.fileUrl} alt="" />
					}
					<p>
						<a href={props.fileUrl} target='_blank'>
							Download
							{fileType && fileType.toUpperCase() === 'PDF' ? ' PDF ' : ' '}
							File
						</a>
					</p>
				</div>
					:
					<div className={styles.imageContainer}>
						<p>
							No file uploaded.
						</p>
					</div>
				}
			</div>
		</div>
	);
};

export default DocumentPreview;
