import axios from 'axios';
import { createApi } from './index';

function fetchContactsList(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/contacts', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function updateContact(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/contacts/' + params.contact.contactQID, params.contact, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function uploadIdentityDocument(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/contact/upload/identity/document/' + params.data.identityId, params.data.documentData, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function uploadVoidChequeDocument(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/bank/upload/void/check/document/' + params.data.bankAccountId, params.data.documentData, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const contactsServices = {
  fetchContactsList,
  updateContact,
  uploadIdentityDocument,
  uploadVoidChequeDocument
};
