import React, { useEffect } from 'react';
import { HouseholdsSummary, ClientsSummary, AccountsSummary } from '../../components';
import { Container } from '@qwealth/qcore';
import { connect } from 'react-redux';
import Creators from '../../state/AppRedux';
import {
	selectHouseholds,
	selectContacts,
	selectAccounts,
	selectIsFetchingHouseholds,
	selectIsFetchingContacts,
	selectIsFetchingAccounts
} from '../../state/Selectors';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Dashboard = (props) => {
	
	const history = useHistory();
	
	useEffect(() => {
		props.fetchContacts();
		props.fetchAccounts()
	}, []);
	
	return (
		<>
			<Helmet>
				<title>Summary - QMove</title>
			</Helmet>
			<Container
				backgroundColor={'#E5E5E5'}
			>
				<HouseholdsSummary
					households={props.households}
					onViewAll={() => { history.push('/households'); }}
					fetchingHouseholds={props.fetchingHouseholds}
				/>
				<ClientsSummary
					contacts={props.contacts}
					onViewAll={() => { history.push('/contacts'); }}
					fetchingContacts={props.fetchingContacts}
				/>
				<AccountsSummary
					accounts={props.accounts}
					onViewAll={() => { history.push('/accounts'); }}
					fetchingAccounts={props.fetchingAccounts}
				/>
			</Container>
		</>
	);
}

const mapStateToProps = (state) => ({
	households: selectHouseholds(state),
	contacts: selectContacts(state),
	accounts: selectAccounts(state),
	fetchingHouseholds: selectIsFetchingHouseholds(state),
	fetchingContacts: selectIsFetchingContacts(state),
	fetchingAccounts: selectIsFetchingAccounts(state)
});

const mapDispatchToProps = (dispatch) => ({
	fetchContacts: () => dispatch(Creators.fetchContacts()),
	fetchAccounts: () => dispatch(Creators.fetchAccounts())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);