import { removeEmptyValues } from '@qwealth/qcore';
import axios from 'axios';
import { createApi } from './index';

export const saveOrUpdateContactRequest = (data) => {
  const API = createApi(axios);
  let dataClean = removeEmptyValues(data);
  let request;
  if (data.id) {
    request = API.patch(`/contacts/${data.id}`, dataClean);
  } else {
    request = API.post('/contacts', dataClean);
  }
  return request
    .then(result => result.data.response)
    .catch(error => {
      throw error.response;
    })
}
