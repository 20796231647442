import React, { useEffect, useState } from 'react';
import { Container } from '@qwealth/qcore';
import styles from './accounts.module.scss';
import { Helmet } from 'react-helmet-async';
import { BsPlus, BsUpload, BsDownload } from 'react-icons/bs';
import { AccountsList, AccountForm, BulkImport, ProgressBar } from '../../components';
import { connect, useDispatch } from 'react-redux';
import {
  selectAccounts,
  selectLabels,
  selectEnumerations,
  selectIsFetchingAccounts,
  selectIsDownloadingFile,
  selectTableColumnsPref,
  selectRelinquishingInstitutions,
  selectContacts,
  selectIsFetching
} from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { filterListBasedOnMissingFields, getOptionsFromEnumerations, extractFieldLabel } from '../../helpers';
import { CgSpinner } from 'react-icons/cg';
import { useTabFilter } from '../../hooks/useTabFilter';
import { TabFilters } from '../../constants';

const Accounts = (props) => {
  const dispatch = useDispatch();
  const [ activeFilter, setActiveFilter ] = useState(TabFilters.all);
  const tabFilteredList = useTabFilter({activeFilter, dataList: props.accounts});
  const [ showAccountModal, setShowAccountModal ] = useState(false);
  const [ activeAccount, setActiveAccount ] = useState(null);
  const [ showBulkImportModal, setShowBulkImportModal ] = useState(false);

  useEffect(() => {
    dispatch(Creators.setActiveHousehold(null));
    props.fetchAccounts();
    props.fetchGeneralSettings();
    props.fetchRelinquishingInstitutions();
  }, []);

  const showAccountCreationModal = () => {
    setShowAccountModal(true);
  };

  const hideAccountCreationModal = () => {
    setShowAccountModal(false);
  };

  const getOptions = (field, type) => {
    if (props.enumerations) {
      return getOptionsFromEnumerations(field, type, props.enumerations);
    } else {
      return [];
    }
  };

  const labelDictionary = props.labels ? props.labels.accounts : null;

  const getFieldLabel = (field, type) => {
    if (props.labels) {
      return extractFieldLabel(field, type, props.labels)
    } else {
      return '';
    }
  };

  const saveAccount = (data) => {
    props.saveAccount(data);
  };

  const editAccount = (account) => {
    setActiveAccount(account);
  };

  const updateAccount = (data) => {
    if (showAccountModal) {
      props.saveAccount(data);
    } else {
      props.updateAccount(data);
    }
  }

  const exportContent = () => {
    props.bulkExport({
      type: 'accounts'
    });
  };

  const hideBulkImportModal = () => {
    setShowBulkImportModal(false);
  };

  const showBulkImport = () => {
    setShowBulkImportModal(true);
  }

  const getOptionsRelinquishingInstitutions = () => {
    const optionsArray = [];
    props.relinquishingInstitutions.map(el => {
      optionsArray.push({
        id: el.id,
        label: el.institutionName,
        value: el.institutionName
      });
    })
    return optionsArray
  }
  return (
    <>
      {props.isFetching && <ProgressBar />}
      <Helmet>
        <title>Accounts - QMove</title>
      </Helmet>
      <Container>
        <div className={styles.pageHeader}>
          <h1>Accounts</h1>
          <div>
           
            <button disabled={props.isDownloading} onClick={() => exportContent()} className={styles.themeButton}>
              <span>Bulk Export</span>
              {props.isDownloading ? <CgSpinner className='icon-spin' style={{ strokeWidth: '1.2' }} /> : <BsDownload style={{ strokeWidth: '1.2' }} />}
            </button>
            <button onClick={() => showBulkImport()} className={styles.themeButton}>
              <span>Bulk Import</span>
              <BsUpload style={{ strokeWidth: '1.2' }} />
            </button>
						<button onClick={() => showAccountCreationModal()} className={styles.themeButton}>
							<span>Add Individual</span>
							<BsPlus style={{ strokeWidth: '1.2' }} />
						</button>
          </div>
        </div>
        <AccountsList
          labels={labelDictionary}
          accounts={tabFilteredList}
          switchListView={setActiveFilter}
          editAccount={editAccount}
          getOptions={getOptions}
          getFieldLabel={getFieldLabel}
          saveAccount={saveAccount}
          fetchingAccounts={props.fetchingAccounts}
          updateAccount={updateAccount}
          columnPref={props.columnPref}
          contacts={props.contacts}
          showAccountModal={showAccountModal}
          hideAccountCreationModal={hideAccountCreationModal}
          getOptionsRelinquishingInstitutions={getOptionsRelinquishingInstitutions}
        />
      </Container>
      {showBulkImportModal &&
        <BulkImport
          hideBulkImportModal={hideBulkImportModal}
          importType={'accounts'}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  accounts: selectAccounts(state),
  labels: selectLabels(state),
  enumerations: selectEnumerations(state),
  fetchingAccounts: selectIsFetchingAccounts(state),
  isDownloading: selectIsDownloadingFile(state),
  columnPref: selectTableColumnsPref(state),
  contacts: selectContacts(state),
  relinquishingInstitutions: selectRelinquishingInstitutions(state),
  isFetching: selectIsFetching(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccounts: () => dispatch(Creators.fetchAccounts()),
  fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
  saveAccount: (data) => dispatch(Creators.saveAccount(data)),
  updateAccount: (data) => dispatch(Creators.updateAccount(data)),
  bulkExport: (data) => dispatch(Creators.bulkExport(data)),
  setTableColumnsPref: (data) => dispatch(Creators.setTableColumnsPref(data)),
  fetchRelinquishingInstitutions: () => dispatch(Creators.fetchRelinquishingInstitutions()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
