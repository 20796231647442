import React, { useEffect, useState } from 'react';
import styles from './qtmappings.module.scss';
import FormControl from '../formControl/formControl';
import { getOptions } from '../../helpers';

const renderMappingTable = (labels, columns, mappings, update) => {
	const labelsArray = Object.keys(labels);
	const columnOptions = getOptions(columns);
	return labelsArray.map((el, index) => {
		if (labels[el].visibility.toUpperCase() === 'PUBLIC') {
			return (
				<tr key={index}>
					<td>
						{el}
					</td>
					<td>
						<FormControl
							type={'dropdown'}
							options={columnOptions}
							updateValue={update}
							name={el}
							pathPrefix=''
							value={(mappings && mappings[el]) ? mappings[el].csvColumn : ''}
						/>
					</td>
				</tr>
			);
		}
	});
};

const QTMappings = (props) => {
	const [selectedTableLabels, setSelectedTableLabels] = useState([]);
	
	useEffect(() => {
		if (props.fields && props.importType) {
			setSelectedTableLabels(props.fields[props.importType]);
		}
	}, [props.data]);
	
	return(
		<>
			<table className={styles.qtTable}>
				<thead>
					<tr>
						<th>
							QT Data Field
						</th>
						<th>
							CSV Column
						</th>
					</tr>
				</thead>
				<tbody>
					{renderMappingTable(selectedTableLabels, props.columns, props.mappings, props.update)}
				</tbody>
			</table>
		</>
	);
};

export default QTMappings;
