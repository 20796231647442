import React from 'react';
import { Box } from '@qwealth/qcore';
import AccountsSummaryTable from './accountsSummaryTable.js';
import styles from './accounts.module.scss';
import { BsFillCaretUpFill, BsChevronRight } from 'react-icons/bs';
import { getTotalElementsWhichNeedAction } from '../../helpers';

const AccountsSummary = (props) => {
	return (
		<>
			<Box
				backgroundColor="#FFFFFF"
				mb="large"
				display="flex"
				justifyContent="flex-end"
				borderRadius="12px"
				padding="20px"
				flexDirection="column"
			>
				<div className={styles.summaryBoxHeader}>
					<div>
						<h2 className={styles.summaryHeader}>
							Accounts
						</h2>
					</div>
					<div>
						<div>
							<p>Non-Reg Accounts</p>
							<span>
								{/*{props.accounts.length}*/}
								0
							</span>
						</div>
						<div>
							<p>Reg Accounts</p>
							<span>
								0
							</span>
						</div>
						<div>
							<p>Need Action</p>
							<span className={styles.needAction}>
								{getTotalElementsWhichNeedAction(props.accounts)} <BsFillCaretUpFill />
							</span>
						</div>
					</div>
				</div>
				<AccountsSummaryTable
					accounts={props.accounts.slice(0, 3)}
					fetchingAccounts={props.fetchingAccounts}
				/>
				<div className={styles.summaryBoxFooter}>
					<button onClick={() => props.onViewAll()} className={styles.viewAllButton}>View All <BsChevronRight /></button>
				</div>
			</Box>
		</>
	);
}

export default AccountsSummary;
