import React, { useState, useEffect } from 'react';
import { Box } from '@qwealth/qcore';
import styles from './householdsList.module.scss';
import HouseholdsListTable from './householdsListTable';
import { searchHouseholdsList } from '../../helpers';
import cx from 'classnames';
import SearchForm from '../searchForm/searchForm';
import LoadingIndicator from '../loading/loading';
import PopOver from '../popover/popover'
import { GoSettings } from 'react-icons/go';
import { TabFilters } from '../../constants';

const HouseholdsList = (props) => {
  const [ householdsFiltered, setHouseholdsFiltered ] = useState(props.households.activeList);
  const [ activeFilter, setActiveFilter ] = useState(TabFilters.all);
  const [ showColumnsPrefModal, setShowColumnsPrefModal ] = useState(false);

  useEffect(() => {
    setHouseholdsFiltered(props.households.activeList);
  }, [ props.households ]);

  const performSearch = (e) => {
    const filteredList = searchHouseholdsList(props.households.activeList, e.target.value);
    setHouseholdsFiltered(filteredList);
  };

  const switchView = (filterVal) => {
    props.switchListView(filterVal);
    setActiveFilter(filterVal)
  };

  const showTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(true)
  };

  const hideTableColumnsSelectionModal = () => {
    setShowColumnsPrefModal(false)
  };

  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px"
        flexDirection="column"
        marginTop="20px"
      >
        <div className={styles.householdsListContainerHeader}>
          <div>
            <div className={styles.tabNavContainer}>
              <button onClick={() => switchView(TabFilters.all)} className={cx(activeFilter === TabFilters.all && styles.activeBtn)}>All</button>
              <button onClick={() => switchView(TabFilters.incomplete)} className={cx(activeFilter === TabFilters.incomplete && styles.activeBtn)}>
                Incomplete <span>{props.households[TabFilters.incomplete].count}</span>
              </button>
              <button onClick={() => switchView(TabFilters.complete)} className={cx(activeFilter === TabFilters.complete && styles.activeBtn)}>
                Complete <span>{props.households[TabFilters.complete].count}</span>
              </button>
              <button onClick={() => switchView(TabFilters.moved)} className={cx(activeFilter === TabFilters.moved && styles.activeBtn)}>
                moved <span>{props.households[TabFilters.moved].count}</span>
              </button>
            </div>
          </div>
          <div className={styles.positionRelative}>
            <div className={styles.positionRelative}>
              {showColumnsPrefModal &&
                <PopOver
                  hideModal={hideTableColumnsSelectionModal}
                  editTableColumns={props.editTableColumns}
                  dataType='households'
                />
              }
            </div>
            <button onClick={showTableColumnsSelectionModal} className={styles.editColumnsButton}><GoSettings /> Select Columns</button>
            <SearchForm
              formAction={performSearch}
              placeholder={'Search...'}
            />
          </div>
        </div>
        {props.fetchingHouseholds ?
          <LoadingIndicator size='small' height='500px' />
          :
          <HouseholdsListTable
            labels={props.labels}
            households={householdsFiltered}
            onEdit={props.onEdit}
            viewContactDetails={props.viewContactDetails}
            columnPref={props.columnPref}
          />
        }
      </Box>
    </>
  );
};

export default HouseholdsList;
