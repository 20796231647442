import axios from 'axios';
import { createApi } from './index';

function fetchHouseholdsList(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/households', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function fetchHouseholdsDetailedList(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/households/contacts', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    });
}

function fetchHouseholdDetails(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/households/' + params, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    });
};

function saveHousehold(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/households/', params, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    });
};

export const householdsServices = {
  fetchHouseholdsList,
  fetchHouseholdsDetailedList,
  fetchHouseholdDetails,
  saveHousehold
};
