import React, { useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { QContext } from '@qwealth/qdata';
import Creators from './state/AppRedux';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import {
  Dashboard,
  UserProfile,
  Households,
  Contacts,
  Accounts,
  HouseholdDetails,
  ImportLogs,
  DocusignCallback,
  RelinquishingInstitution,
} from './containers';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider, AppLayout, useAcquireToken, getAccessAndIdToken, useDispatchOnMount } from '@qwealth/qcore';
import Header from './components/header/Header';
import Sidebar from './components/sidebar/sidebar';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './containers/login';
import { LoadingIndicator } from './components';
import { configureAxios } from './data/actions/axios';
import { loadUser } from './data/actions/microsoft/user';

const reloadAndClearCache = () => {
  if (typeof window !== 'undefined') {
    if (window.location) {
      window.location.reload(true);
    }
  }
}

function App() {
  const dispatch = useDispatch();
  const isAxiosInitialized = useSelector((state) => state.axios);
  const { idToken, account, isInProgress } = useAcquireToken();

  useEffect(() => {
    if (idToken && account) {
      QContext.setIdToken(idToken);
      dispatch(configureAxios(idToken))
      dispatch(loadUser());
      // refresh the token every 20 minutes
      setInterval(() => {
        getAccessAndIdToken({ forceRefresh: true }).then(auth => {
          if (auth) {
            QContext.setIdToken(auth.idToken);
            dispatch(configureAxios(auth.idToken));
          }
        });
      }, 20 * 60 * 1000);
    }
  }, [ idToken, account, dispatch ]);

  // Load data required at App Level here
  useDispatchOnMount(Creators.fetchDetailedHouseholds, isAxiosInitialized ? true  : undefined);
 
  if (!account) {
    return <LoginPage />;
  }

  if (isInProgress || !isAxiosInitialized) {
    return (
      <AppLayout alignItems="center">
        <LoadingIndicator />
      </AppLayout>
    );
  }

  return (
    <Router>
      <ThemeProvider>
        <HelmetProvider>
          <Helmet>
            <title>
              QMove
            </title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          </Helmet>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <AppLayout>
            <AuthenticatedTemplate>
              <Sidebar />
              <AppLayout.Main p="large">
                <Header />
                <Switch>
                  <Route exact path="/">
                    <Dashboard />
                  </Route>
                  <Route exact path="/profile">
                    <UserProfile />
                  </Route>
                  <Route exact path="/households">
                    <Households />
                  </Route>
                  <Route exact path="/contacts">
                    <Contacts />
                  </Route>
                  <Route exact path="/accounts">
                    <Accounts />
                  </Route>
                  <Route path="/households/:QID">
                    <HouseholdDetails />
                  </Route>
                  <Route exact path="/households/new">
                    <HouseholdDetails />
                  </Route>
                  <Route path="/logs">
                    <ImportLogs />
                  </Route>
                  <Route path="/docusign-callback" exact>
                    <DocusignCallback />
                  </Route>
                  <Route path="/relinquishingInstitution">
                    <RelinquishingInstitution />
                  </Route>
                </Switch>
              </AppLayout.Main>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginPage />
            </UnauthenticatedTemplate>
          </AppLayout>
        </HelmetProvider>
      </ThemeProvider>
      <button onClick={() => reloadAndClearCache()} className="app-version">V 0.0.13</button>
    </Router>
  );
}

export default App;
