import React from 'react'
import styles from './addressForm.module.scss';
import FormControl from '../formControl/formControl';

const AddressForm = (props) => {
	const getLabel = props.getFieldLabel;
	const address = props.address;
	return (
		<>
			<div className={styles.formControlContainer}>
				<div>
					<FormControl
						label={getLabel('unitNumber', 'address')}
						value={address ? address.unitNumber : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='unitNumber'
						required={props.isFieldRequired('unitNumber', 'address')}
					/>
				</div>
				<div>
					<FormControl
						label={getLabel('streetName', 'address')}
						value={address ? address.streetName : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='streetName'
						required={props.isFieldRequired('streetName', 'address')}
					/>
				</div>
				<div>
					<FormControl
						label={getLabel('streetNumber', 'address')}
						value={address ? address.streetNumber : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='streetNumber'
						required={props.isFieldRequired('streetNumber', 'address')}
					/>
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div>
					<FormControl
						label={getLabel('city', 'address')}
						value={address ? address.city : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='city'
						required={props.isFieldRequired('city', 'address')}
					/>
				</div>
				<div>
					<FormControl
						label={getLabel('provinceOrState', 'address')}
						value={address ? address.provinceOrState : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='provinceOrState'
						required={props.isFieldRequired('provinceOrState', 'address')}
					/>
				</div>
			</div>
			<div className={styles.formControlContainer}>
				<div>
					<FormControl
						label={getLabel('country', 'address')}
						value={address ? address.country : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='country'
						required={props.isFieldRequired('country', 'address')}
					/>
				</div>
				<div>
					<FormControl
						label={getLabel('postalCode', 'address')}
						value={address ? address.postalCode : ''}
						pathPrefix={props.pathPrefix}
						updateValue={props.onUpdate}
						name='postalCode'
						required={props.isFieldRequired('postalCode', 'address')}
					/>
				</div>
			</div>
		</>
	);
}

export default AddressForm;
