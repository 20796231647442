
import { Box, styled, themeGet } from '@qwealth/qcore';
import { FaAsterisk } from 'react-icons/fa';

export const InputForm = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ConditionallyRenderedFieldAsterisk = styled(FaAsterisk)`
  font-size: 0.5rem;
  margin: 0 0 0.6rem 0.3rem;

  &.condition-met {
    color: ${themeGet('colors.red')};
  }
`;

export const ConditionallyRequiredFieldLabel = ({
  labelText,
  isConditionMet = false,
}) => {
  return (
    <>
      {labelText}
      <ConditionallyRenderedFieldAsterisk className={isConditionMet ? 'condition-met' : ''} />
    </>
  );
};
