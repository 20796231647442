import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTabFilter } from '../../hooks/useTabFilter';
import { selectHouseholdAccounts } from '../../state/Selectors';
import AccountsList from '../accounts/accountsList';
import { TabFilters } from '../../constants'

const labels = {
  accountTitle: { en: "Account Title", visibility: "public" },
  accountType: { en: "Account Type", visibility: "public" },
  ownership: { en: "Ownership", visibility: "public" },
  expectedValue: { en: "Expected Value", visibility: "public" },
  sourceOfFunds: { en: "Source Of Funds", visibility: "public" },
  purposeOfAccount: { en: "Purpose Of Account", visibility: "public" },
};

const HouseholdAccounts = ({ getFieldLabel, getOptions, saveAccount, updateAccount, household }) => {
  const householdAccountList = useSelector(selectHouseholdAccounts(household ? household.householdQID : ''));
  const accountListMemoized = useMemo(() => householdAccountList, [household]);
  const tabFilteredList = useTabFilter({activeFilter: TabFilters.all, dataList: accountListMemoized});

  return (
    <>
      <AccountsList
        household={household}
        labels={labels}
        accounts={tabFilteredList}
        getFieldLabel={getFieldLabel}
        getOptions={getOptions}
        saveAccount={saveAccount}
        updateAccount={updateAccount}
      />
    </>
  );
};

export default HouseholdAccounts;
