import { put, call, cancel } from 'redux-saga/effects';
import Creators from '../state/AppRedux';
import { settingsServices } from '../services/settingsServices';
import { toast } from 'react-toastify';

export function * fetchGeneralSettings(data) {
	yield put(Creators.isFetchingSettings(true));
	let result = null;
	try {
		result = yield call(settingsServices.fetchGeneralSettings, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingSettings(false));
			yield put(Creators.setGeneralSettings(result.data.response));
		}
	} catch(e) {
		yield put(Creators.isFetchingSettings(false));
		toast.error('Something went wrong. Please try again later.');
		console.log(e);
	}
}
