import React, { useEffect, useState } from 'react';
import { Container } from '@qwealth/qcore';
import styles from './relinquishingInstitution.module.scss';
import { Helmet } from 'react-helmet-async';
import { BsPlus, BsUpload, BsDownload } from 'react-icons/bs';
import { RelinquishingInstitutionsList, BulkImport } from '../../components';
import { connect } from 'react-redux';
import { selectRelinquishingInstitutions, selectLabels, selectEnumerations, selectIsFetchingRelinquishingInstitutions, selectIsDownloadingFile, selectTableColumnsPref, selectAccounts } from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { filterListBasedOnMissingFields, getOptionsFromEnumerations, extractFieldLabel } from '../../helpers';
import { CgSpinner } from 'react-icons/cg';

const RelinquishingInstitution = (props) => {
  const [ filteredRelinquishingInstitutions, setFilteredRelinquishingInstitutions ] = useState([]);
  const [ filterAccounts, setFilteredAccounts ] = useState([]);
  const [ showBulkImportModal, setShowBulkImportModal ] = useState(false);

  useEffect(() => {
    props.fetchRelinquishingInstitutions();
    props.fetchGeneralSettings();
  }, []);

  useEffect(() => {
    setFilteredRelinquishingInstitutions(props.relinquishingInstitutions);
    setFilteredAccounts(props.accounts);
  }, [ props.relinquishingInstitutions, props.accounts ]);

  const getOptions = (field, type) => {
    if (props.enumerations) {
      return getOptionsFromEnumerations(field, type, props.enumerations);
    } else {
      return [];
    }
  };

  const labelDictionary = props.labels ? { ...props.labels.relinquishingInstitution, ...props.labels.address } : null;

  const getFieldLabel = (field, type) => {
    if (props.labels) {
      return extractFieldLabel(field, type, props.labels)
    } else {
      return '';
    }
  };

  const exportContent = () => {
    props.bulkExport({
      type: 'relinquishingInstitutions'
    });
  };

  const hideBulkImportModal = () => {
    setShowBulkImportModal(false);
  };

  const showBulkImport = () => {
    setShowBulkImportModal(true);
  }

  return (
    <>
      <Helmet>
        <title>Relinquishing Institution - QMove</title>
      </Helmet>
      <Container>
        <div className={styles.pageHeader}>
          <h1>Relinquishing Institution</h1>
          <div>
            
            <button disabled={props.isDownloading} onClick={() => exportContent()} className={styles.themeButton}>
              <span>Bulk Export</span>
              {props.isDownloading ? <CgSpinner className='icon-spin' style={{ strokeWidth: '1.2' }} /> : <BsDownload style={{ strokeWidth: '1.2' }} />}
            </button>
            <button onClick={() => showBulkImport()} className={styles.themeButton}>
              <span>Bulk Import</span>
              <BsUpload style={{ strokeWidth: '1.2' }} />
            </button>
            <button onClick={() => {}} className={styles.themeButton}>
                <span>Add Individual</span>
                <BsPlus style={{ strokeWidth: '1.2' }} />
            </button>
          </div>
        </div>
        <RelinquishingInstitutionsList
          labels={labelDictionary}
          relinquishingInstitutions={filteredRelinquishingInstitutions}
          getOptions={getOptions}
          getFieldLabel={getFieldLabel}
          fetchingAccounts={props.fetchingAccounts}
          columnPref={props.columnPref}
        />
      </Container>
      {showBulkImportModal &&
        <BulkImport
          hideBulkImportModal={hideBulkImportModal}
          importType={'relinquishingInstitutions'}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  relinquishingInstitutions: selectRelinquishingInstitutions(state),
  accounts: selectAccounts(state),
  labels: selectLabels(state),
  enumerations: selectEnumerations(state),
  fetchingRelinquishingInstitutions: selectIsFetchingRelinquishingInstitutions(state),
  isDownloading: selectIsDownloadingFile(state),
  columnPref: selectTableColumnsPref(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchRelinquishingInstitutions: () => dispatch(Creators.fetchRelinquishingInstitutions()),
  fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
  saveAccount: (data) => dispatch(Creators.saveAccount(data)),
  updateAccount: (data) => dispatch(Creators.updateAccount(data)),
  bulkExport: (data) => dispatch(Creators.bulkExport(data)),
  setTableColumnsPref: (data) => dispatch(Creators.setTableColumnsPref(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelinquishingInstitution)