import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import Rehydration from './Rehydration'
import ReduxPersist from './ReduxPersistConfig'
import { composeWithDevTools } from 'redux-devtools-extension'
import reduxReset from 'redux-reset';
import createSagaMiddleware from 'redux-saga'

// creates the store
export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */

  const sagaMonitor = null;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  middleware.push(sagaMiddleware);

  /* ------------- Thunk Middleware ------------- */
  middleware.push(thunk);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));



  // redux devtools
  const composeEnhancers = composeWithDevTools({ realtime: true, port: 8001 });

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  // const createAppropriateStore = Config.useReactotron ? console.tron.createStore : createStore;
  const store = createStore(rootReducer, composeEnhancers(compose(...enhancers, reduxReset())));

  // configure persistStore and check reducer version number
  if (ReduxPersist.active) {
    Rehydration.updateReducers(store)
  }

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware
  }
}
