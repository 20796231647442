import axios from 'axios';
import { createApi } from './index';

function fetchAccountsList(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/accounts', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function saveAccount(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/accounts', params.data, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function updateAccount(params) {
  const options = {
    method: 'PATCH'
  };
  const API = createApi(axios);
  return API.patch('/accounts/' + params.data.id, params.data, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const accountsServices = {
  fetchAccountsList,
  saveAccount,
  updateAccount
};
