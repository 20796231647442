import axios from 'axios';
import { createApi } from './index';

function fetchRelinquishingInstitutionsList(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/relinquishing/institution', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const relinquishingInstitutionsServices = {
  fetchRelinquishingInstitutionsList
};
