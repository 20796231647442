import React, { useState } from 'react';
import { Box } from '@qwealth/qcore';
import cx from 'classnames';
import styles from './householdOverview.module.scss';
import FormControl from '../formControl/formControl';
import AddressForm from '../addressForm/addressForm';
import HouseholdAccounts from './householdAccounts';

const HouseholdOverview = (props) => {
  const [ activeTab, setActiveTab ] = useState('INFO');
  const getLabel = props.getFieldLabel;
  const getOptions = props.getOptions;
  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px 40px"
        flexDirection="column"
        marginTop="20px"
        marginBottom="100px"
      >
        <div>
          <div className={styles.tabNavContainer}>
            <button onClick={() => setActiveTab('INFO')} className={cx(activeTab === 'INFO' && styles.activeBtn)}>
              Info <span className={styles.badge}>{props.missingFields ? props.missingFields.householdOverview : 0}</span>
            </button>
            <button onClick={() => setActiveTab('SUMMARY')} className={cx(activeTab === 'SUMMARY' && styles.activeBtn)}>
              Accounts
            </button>
          </div>
        </div>
        {
          activeTab === 'INFO' &&
          <div>
            <div>
              <h3 className={styles.styledHeading}>
                Basic Info
              </h3>
            </div>
            <div className={styles.formControlContainer}>
              <div>
                <FormControl
                  label={getLabel('householdName', 'households')}
                  value={props.household && props.household.householdName ? props.household.householdName : ''}
                  type={'Text'}
                  updateValue={props.update}
                  name='householdName'
                  pathPrefix=''
                  required={props.isFieldRequired('householdName', 'households')}
                />
              </div>
              {/* Temp Hide: QC-1669 
              <div>
                <FormControl
                  label={'Advisor'}
                  value={'Jacob Jones'}
                  type={'Dropdown'}
                />
              </div> */}
              <div>
                {/* TODO: REMOVE THE BELOW CODE BLOCK */}
                <FormControl
                  label={getLabel('isDiscretionary', 'households')}
                  value={props.household ? props.household.isDiscretionary : ''}
                  type={'Dropdown'}
                  options={getOptions('isDiscretionary', 'households')}
                  updateValue={props.update}
                  name='isDiscretionary'
                  pathPrefix=''
                  required={props.isFieldRequired('isDiscretionary', 'households')}
                />
              </div>
            </div>
            <div className={styles.formControlContainer}>
              <div>
                <FormControl
                  label={getLabel('feeType', 'households')}
                  value={props.household && props.household.feeType ? props.household.feeType : ''}
                  type={'Dropdown'}
                  options={getOptions('feeType', 'households')}
                  updateValue={props.update}
                  name='feeType'
                  pathPrefix=''
                  required={props.isFieldRequired('feeType', 'households')}
                />
              </div>
              <div>
                {props.household && props.household.feeType === 'Flat' &&
                  <FormControl
                    label={getLabel('flatFee', 'households')}
                    value={props.household && props.household.flatFee ? props.household.flatFee : ''}
                    type={'Text'}
                    updateValue={props.update}
                    name='flatFee'
                    pathPrefix=''
                    required={props.isFieldRequired('flatFee', 'households')}
                  />
                }
              </div>
              {/* Temp Hide: QC-1669 
                <div>
                  <FormControl
                    label={getLabel('signingType', 'households')}
                    value={props.household ? props.household.signingType : ''}
                    type={'Dropdown'}
                    options={getOptions('signingType', 'households')}
                    updateValue={props.update}
                    name='signingType'
                    pathPrefix=''
                    required={props.isFieldRequired('signingType', 'households')}
                  /> 
                </div>*/}
            </div>
            <div>
              <h3 className={styles.styledHeading}>
                Household Address
              </h3>
            </div>
            {typeof props.household !== 'undefined' && props.household.hasOwnProperty('address') &&
              <AddressForm
                id={1}
                address={props.household.address}
                getFieldLabel={getLabel}
                onUpdate={props.update}
                pathPrefix={'address.'}
                isFieldRequired={props.isFieldRequired}
              />
            }
          </div>
        }
        {
          activeTab === 'SUMMARY' &&
          <div>
            <div>
              <h3 className={styles.styledHeading}>
                Accounts
              </h3>
            </div>
            <HouseholdAccounts
              household={props.household}
              columnPref={props.columnPref}
              getFieldLabel={props.getFieldLabel}
              getOptions={props.getOptions}
              saveAccount={props.saveAccount}
              updateAccount={props.updateAccount}
            />
          </div>
        }
      </Box>
    </>
  );
};

export default HouseholdOverview;
