import React, { useState, useEffect } from 'react';
import styles from './householdsList.module.scss';
import TableRowExpandable from '../tableRow/tableRowExpandable';
import cx from 'classnames';
import { getRandomId, sortTableColumn, getEnabledColumnsFromColumnPref, getVisibleLabels } from '../../helpers';
import { BsChevronExpand, BsChevronDown, BsChevronUp } from 'react-icons/bs';

const renderHouseholds = (households, labelsArray, action, viewContactDetails, tableColumnsPres) => {
	if (households.length > 0) {
		return households.map((el) => {
			return (
				<TableRowExpandable
					key={getRandomId(el.householdQID)}
					labelsArray={labelsArray}
					household={el}
					action={action}
					viewContactDetails={viewContactDetails}
					columns={tableColumnsPres}
				/>
			);
		});
	} else {
		return (
			<tr key={getRandomId()}>
				<td colSpan={8} className={styles.textCenter}>
					No data found.
				</td>
			</tr>
		);
	}
};

const renderTableHeaderColumns = (labelsArray, labels, getSortIndicator, sortColumn, tableColumnsPref) => {
	if (typeof labels !== 'undefined' && labels !== null) {
		if (typeof tableColumnsPref !== 'undefined' && typeof tableColumnsPref !== null) {
			return tableColumnsPref?.map((el, index) => {
				if (el['enabled'] === true) {
					return (
						<th key={index}>
							<button onClick={(e) => sortColumn(e, el['key'])}>
								{labels[el['key']].en} {getSortIndicator(el['key'])}
							</button>
						</th>
					)
				}
			})
		} else {
			return labelsArray.map((el, index) => {
				if (el !== 'aum' && el !== 'household') {
					return (
						<th key={index}>
							<button onClick={(e) => sortColumn(e, el)}>
								{labels[el].en} {getSortIndicator(el)}
							</button>
						</th>
					)
				}
			})
		}

	}
};
const HouseholdsListTable = (props) => {
	const [sortConfig, setSortConfig] = useState(null);
	const [sortedData, setSortedData] = useState([]);
	const [columns, setColumns] = useState({})

	useEffect(() => {
		setSortedData(props.households);
	}, [props.households]);

	useEffect(() => {
		if (sortConfig) {
			const sortResult = sortTableColumn(sortConfig.key, sortedData, sortConfig.direction);
			setSortedData(sortResult);
		}
	}, [sortConfig]);

	useEffect(() => {
		if (typeof props.columnPref !== 'undefined' && props.columnPref !== null) {
			if (typeof props.columnPref.households !== 'undefined') {
				setColumns(getEnabledColumnsFromColumnPref(props.columnPref.households));
			} else {
				if (typeof props.labels !== 'undefined' && props.labels !== null) {
					setColumns(getVisibleLabels(props.labels))
				}
			}
		} else {
			if (typeof props.labels !== 'undefined' && props.labels !== null) {
				setColumns(getVisibleLabels(props.labels))
			}
		}
	}, [props.columnPref, props.labels]);
	const getSortIndicator = (key) => {
		if (sortConfig) {
			if (sortConfig.key === key && sortConfig.direction === 'asc') {
				return <BsChevronDown />
			} else if (sortConfig.key === key && sortConfig.direction === 'dec') {
				return <BsChevronUp />
			}
		}
		return <BsChevronExpand />
	};

	const sortColumn = (e, key) => {
		let direction = 'asc';
		if (sortConfig) {
			if (sortConfig.key === key && sortConfig.direction === 'asc') {
				direction = 'dec';
			}
		}
		setSortConfig({ key, direction });
	};

	let labelsArray = [];
	if (props.labels) {
		labelsArray = Object.keys(props.labels);
	}

	return (
		<>
			<table className={cx(styles.table, styles.tableWithCollapsableRows)}>
				<thead>
					<tr>
						{renderTableHeaderColumns(labelsArray, props.labels, getSortIndicator, sortColumn, props.columnPref?.households)}
						{/* <th>
							<button onClick={(e) => sortColumn(e, 'householdName')}>
								Household Name {getSortIndicator('householdName')}
							</button>
						</th>
						<th>
							<button onClick={(e) => sortColumn(e, 'aum')}>
								AUM {getSortIndicator('aum')}
							</button>
						</th>
						<th>
							<button onClick={(e) => sortColumn(e, 'sign')}>
								Sign {getSortIndicator('sign')}
							</button>
						</th>
						<th>
							<button onClick={(e) => sortColumn(e, 'rep')}>
								Rep Code {getSortIndicator('rep')}
							</button>
						</th>
						<th>
							Assignee
						</th>
						<th className={styles.textCenter}>
							Missing Info
						</th>
						<th>
							&nbsp;
						</th>
						<th>
							&nbsp;
						</th> */}
					</tr>
				</thead>
				<tbody>
					<tr key={getRandomId()} className={styles.spacerRow}></tr>
					{renderHouseholds(sortedData, labelsArray, props.onEdit, props.viewContactDetails, props.columnPref?.households)}
				</tbody>
			</table>
		</>
	);
};

export default HouseholdsListTable;
