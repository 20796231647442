import { put, call } from 'redux-saga/effects';
import Creators from '../state/AppRedux';
import { docusignIntegrationServices } from '../services/docusignIntegrationServices' ;
import { toast } from 'react-toastify';


export function * fetchConsentUrl(data) {
	yield put(Creators.isFetchingHouseholds(true));
	let result = null;
	try {
		result = yield call(docusignIntegrationServices.getConsentUrl, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingHouseholds(false));
			yield put(Creators.setDocusignConsentUrl(result.data));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingHouseholds(false));
		console.log(e);
	}
}

export function * fetEmbeddedSendingUrl(data) {
	yield put(Creators.isFetchingHouseholds(true));
	let result = null;
	try {
		result = yield call(docusignIntegrationServices.getEmbeddedSendingUrl, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingHouseholds(false));
			yield put(Creators.setEmbeddedSendingUrl(result.data.response.redirect_url));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingHouseholds(false));
		console.log(e);
	}
}
