import { put, call, cancel } from 'redux-saga/effects';
import Creators from '../state/AppRedux';
import { relinquishingInstitutionsServices } from '../services/relinquishingInstitutionsServices';
import { toast } from 'react-toastify';

export function * fetchRelinquishingInstitutions(data) {
	yield put(Creators.isFetchingRelinquishingInstitutions(true));
	let result = null;
	try {
		result = yield call(relinquishingInstitutionsServices.fetchRelinquishingInstitutionsList, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingRelinquishingInstitutions(false));
			yield put(Creators.setRelinquishingInstitutions(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingRelinquishingInstitutions(false));
		console.log(e);
	}
}