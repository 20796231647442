const envPrefix = window.location.href.includes('dev') ? 'dev.' : '';

export const DIGIT_URL = 'https://qwealth.d1g1t.com';
export const Q_BIAS_URL = 'https://qwealth.getfeedback.com/r/I9U1DemR';
export const Q_CASH_URL = `https://${envPrefix}qcash.qwnext.com`;
export const Q_FOLIO_URL = `https://${envPrefix}qfolio.qwnext.com`;
export const Q_LIFE_URL = `https://${envPrefix}qlife.qwnext.com`;
export const Q_RISK_URL = 'https://qwealth.getfeedback.com/preview/Mvob5Ic2';
export const Q_SCORE_URL = `https://${envPrefix}qscore.qwnext.com`;
export const SF_URL = 'https://login.salesforce.com';

export const HOUSEHOLD_SCHEMA = {
	householdName: null,
	feeType: null,
	isDiscretionary: null,
	flatFee: null,
	contacts: [],
	addresses: []
};

export const CONTACT_SCHEMA = {
	fistName: null,
	lastName: null,
	employment: {
		occupation: null,
		fixedCompensation: null,
		variableCompensation: null,
		employer: {
			addresses: []
		}
	},
	accounts: [],
	address: {
		postalCode: null
	},
	legalEntities: {},
	document: {}
};

export const BULK_IMPORT_OPTIONS = [
	{
		label: 'Households',
		value: 'households'
	},
	{
		label: 'Contacts',
		value: 'contacts'
	},
	{
		label: 'Accounts',
		value: 'accounts'
	}
];

export const REGULATORY_INF0 = {
	thirdParty: 'No',
	outsideAccount: "No",
	registeredName: null,
	authority: "No",
	foreignPep: "No",
	registered: "No",
	control: "No",
	domesticPep: "No",
	insiderTicker: null,
	guarantee: "No",
	outsideAccountSpecify: null,
	insider: "No",
	authorityNature: null,
	registeredEmployer: null,
	controlTicker: null,
	headOfIntOrg: "No",
	guaranteeName: null
};

export const relationshipTypesWithRelatedContacts = [
	'Married',
	'Common-law',
	'Separated'
];

export const BENEFICIARY = {
	benFirstName: null,
	benLastName: null,
	benGender: null,
	benSIN: null,
	benType: null,
	percentageAllocation: null,
	address: null
};

export const JOINT_WITH_SPOUSE_ACCOUNT_TYPES = [
	'Cash CAD',
	'Cash USD',
	'Margin CAD',
	'Margin USD',
	'Margin Short CAD',
	'Margin Short USD',
	'In Trust (Minor) CAD',
	'In Trust (Minor) USD',
	'RESP CAD',
	'RDSP CAD'
];

export const PLAN_JURISDICTION_ACCOUNT_TYPES = [
	'LRSP CAD',
	'LIRA CAD',
	'LIRA USD',
	'LRSP USD',
	'RLSP CAD', 
	'RLSP USD', 
	'LIF CAD',
	'LIF USD',
	'LRIF CAD',
	'RLIF CAD',
	'PRIF CAD'
];

export const NO_BENEFICIARIES_ACCOUNT_TYPES = [
	'Cash CAD',
	'Cash USD',
	'Margin CAD',
	'Margin USD'
];

export const SUCCESSOR_BENEFICIARY_ACCOUNT_TYPES = [
	'RSP CAD',
	'RSP USD',
	'Spousal RSP CAD',
	'Spousal RSP USD',
	'RIF CAD',
	'RIF USD',
	'Spousal RIF CAD',
	'Spousal RIF',
	'TFSA CAD',
	'TFSA USD'
];

export const RESP_ACCOUNT_TYPES = [
	'RESP CAD',
	'RDSP CAD'
];

export const PAYOUT_INFO = [
	'RIF CAD',
	'RIF USD',
	'Spousal RIF CAD',
	'Spousal RIF',
	'LIF CAD',
	'LRIF CAD',
	'RLIF CAD',
	'PRIF CAD',
]

export const ITF = [
	'In Trust (Minor) CAD',
	'In Trust (Minor) USD'
]

export const TabFilters = {
	all: 'ALL',
	complete: 'COMPLETE',
	incomplete: 'INCOMPLETE',
	moved: 'MOVED',
}
