import React, { useEffect } from 'react';
import HouseholdOverview from '../householdOverview/householdOverview';
import ContactDetails from '../contactDetails/contactDetails';
import TransparentLoadingIndicator from '../transparentLoadingIndicator/transparentLoadingIndicator';

const Tabs = (props) => {
	useEffect(() => {
		// re-render component
		// console.log('called \n', props.contact);
	}, [props.contact]);
	
	return (
		<>
			{props.fetching && <TransparentLoadingIndicator />}
			{props.activeTab === 'HOUSEHOLD' &&
				<HouseholdOverview
					household={props.household}
					getFieldLabel={props.getFieldLabel}
					getOptions={props.getOptions}
					update={props.update}
					addNewHouseholdAddress={props.addNewHouseholdAddress}
					isFieldRequired={props.isFieldRequired}
					missingFields={props.missingFields}
				/>
			}
			{props.activeTab !== 'HOUSEHOLD' &&
				<ContactDetails
					columnPref={props.columnPref}
					labels={props.labels}
					activeContact={props.activeTab}
					household={props.household}
					getFieldLabel={props.getFieldLabel}
					getOptions={props.getOptions}
					update={props.update}
					contact={props.contact}
					activeContactIndex={props.activeContactIndex}
					saveAccount={props.saveAccount}
					updateAccount={props.updateAccount}
					updateSpouse={props.updateSpouse}
					isFieldRequired={props.isFieldRequired}
					missingFields={props.missingFields}
				/>
			}
		</>
	);
};

export default Tabs;
