import React, { useState } from 'react';
import styles from './collapsable.module.scss';
import cx from 'classnames';
import moment from 'moment';

const Collapsable = (props) => {
	const [collapsed, setCollapsed] = useState(true);
	
	const toggle = () => {
		setCollapsed(!collapsed);
	}
	
	return (
		<div className={cx(styles.collapsableContainer, !collapsed && styles.expanded)}>
			<button onClick={() => toggle()} className={styles.collapsableTitle}>
				<span className={styles.primaryText}>{props.log.file}</span> was imported at <span>{moment(props.log.startTime).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span> resulting in <span className={styles.successText}>{props.log.processed_records} successful</span> records imported <span className={styles.infoText}>out of {props.log.total_records}</span> <span>click to learn more</span>
			</button>
			{!collapsed &&
				<div className={styles.collapsedContent}>
					<p>
						<span>Target: </span> <strong>{props.log.target}</strong>
					</p>
					<p>
						<span>Transaction Type: </span> <strong>{props.log.transaction_type}</strong>
					</p>
					<p>
						<span>Status: </span> <strong>{props.log.status}</strong>
					</p>
					<p>
						<span>Total Records: </span> <strong>{props.log.total_records}</strong>
					</p>
					<p>
						<span>Processed Records: </span> <strong>{props.log.processed_records}</strong>
					</p>
					<p>
						<span>Download CSV File: </span> <strong><a href={props.log.file_url} target='_blank'>{props.log.file}</a></strong>
					</p>
				</div>
			}
		</div>
	);
};

export default Collapsable;
