import React, { useState, useEffect } from 'react';
import { Box } from '@qwealth/qcore';
import styles from './contactsList.module.scss';
import { searchContactsList } from '../../helpers';
import cx from 'classnames';
import SearchForm from '../searchForm/searchForm';
import ContactsListTable from './contacctsListTable';
import LoadingIndicator from '../loading/loading';
import PopOver from '../popover/popover'
import { GoSettings } from 'react-icons/go';
import { TabFilters } from '../../constants';

const ContactsList = (props) => {
  const [ contactsFiltered, setContactsFiltered ] = useState([]);
  const [ activeFilter, setActiveFilter ] = useState('ALL');
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  useEffect(() => {
    setContactsFiltered(props.contacts.activeList);
  }, [ props.contacts ]);

  const performSearch = (e) => {
    const filteredList = searchContactsList(props.contacts.activeList, e.target.value);
    setContactsFiltered(filteredList);
  };

  const switchView = (filterVal) => {
    props.switchListView(filterVal);
    setActiveFilter(filterVal)
  };

  const showTableColumnsSelectionModal = () => {
    setIsModalOpen(true)
  }

  const hideTableColumnsSelectionModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Box
        backgroundColor="#FFFFFF"
        mb="large"
        display="flex"
        justifyContent="flex-end"
        borderRadius="12px"
        padding="20px"
        flexDirection="column"
        marginTop="20px"

      >
        <div className={styles.listContainerHeader}>
          <div>
            <div className={styles.tabNavContainer}>
              <button onClick={() => switchView(TabFilters.all)} className={cx(activeFilter === TabFilters.all && styles.activeBtn)}>All</button>
              <button onClick={() => switchView(TabFilters.incomplete)} className={cx(activeFilter === TabFilters.incomplete && styles.activeBtn)}>
                Incomplete <span>{props.contacts[ TabFilters.incomplete ].count}</span>
              </button>
              <button onClick={() => switchView(TabFilters.complete)} className={cx(activeFilter === TabFilters.complete && styles.activeBtn)}>
                Complete <span>{props.contacts[ TabFilters.complete ].count}</span>
              </button>
              <button onClick={() => switchView(TabFilters.moved)} className={cx(activeFilter === TabFilters.moved && styles.activeBtn)}>
                moved <span>{props.contacts[ TabFilters.moved ].count}</span>
              </button>
            </div>
          </div>
          <div className={styles.positionRelative}>
            <div className={styles.positionRelative}>
              {isModalOpen &&
                <PopOver
                  hideModal={hideTableColumnsSelectionModal}
                  tableColumns={props.tableColumns}
                  dataType='contacts'
                />
              }
            </div>
            <button onClick={showTableColumnsSelectionModal} className={cx(styles.editColumnsButton, styles.positionRelative)}><GoSettings /> Select Columns</button>
            <SearchForm
              formAction={performSearch}
              placeholder={'Search...'}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          {props.fetchingContacts ?
            <LoadingIndicator size={'small'} height='500px' />
            :
            <ContactsListTable
              contacts={contactsFiltered}
              labels={props.labels}
              updateContact={props.updateContact}
              updateContactField={props.updateContactField}
              viewContactDetails={props.viewContactDetails}
              columnPref={props.columnPref}
              columnOrder={props.columnOrder}
            />
          }
        </div>
        {/* {isModalOpen &&
					<EditColumns
						getFieldLabel={props.getFieldLabel}
						hideModal={hideTableColumnsSelectionModal}
						tableColumns={props.tableColumns}
						setTableColoums={props.setTableColoums}
						editTableColumns={props.editTableColumns}
						dataType='contacts' //contacts/accounts/households
					/>
				} */}
      </Box>
    </>
  );
};

export default ContactsList;
