import axios from 'axios';
import { createApi } from './index';

function bulkUpload(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/bulk/upload/create', params.data, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
};

function getBulkUploadLogs(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/bulk/upload/list', options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function bulkExport(params) {
  const options = {
    method: 'GET'
  };
  const API = createApi(axios);
  return API.get('/bulk/export/' + params.data.type, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const bulkUploadServices = {
  bulkUpload,
  getBulkUploadLogs,
  bulkExport
};