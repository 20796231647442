export const getAvailableOwnershipTypes = (
  primaryContact,
  secondaryContact,
) => {
  const ownershipTypes = [];
  if (primaryContact) {
    ownershipTypes.push({ type: 'Primary', name: primaryContact.contact.firstName });
  }
  if (secondaryContact) {
    ownershipTypes.push({ type: 'Secondary', name: secondaryContact.contact.firstName });
    ownershipTypes.push({
      type: 'Joint',
      name: `${primaryContact.contact.firstName}/${secondaryContact.contact.firstName}`,
    });
  }
  return ownershipTypes;
};

export const getHouseholdPrimary = (household) => {
  if (household && household.household_members) {
    return household.household_members.find(m => m.memberType === "Primary") || null;
  }
  return null;
}

export const getHouseholdSecondary = (household) => {
  if (household && household.household_members) {
    return household.household_members.find(m => m.memberType === "Secondary") || null;
  }
  return null;
}
