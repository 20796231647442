import { TabFilters } from "./constants";

export const getParseMissingFieldsString = (fields) => {
  let missingFields = [];
  if (fields.length < 1) {
    return 'No fields missing'
  } else if (fields.length > 3) {
    missingFields = fields.slice(0, 3);
  } else {
    missingFields = fields;
  }
  const missingFieldsString = missingFields.map(el => {
    return 'Missing ' + el.label.en;
  });
  return missingFieldsString.join(', ') + (fields.length > 3 ? '...' : '');
};

export const getTotalElementsWhichNeedAction = (elements) => {
  let total = 0;
  elements.map(el => {
    if (el.missing_fields && el.missing_fields.totalMissing) {
      total = total + el.missing_fields.totalMissing;
    }
  });
  return total;
};

export const parseHouseholdNestedContacts = (elements) => {
  let contacts = [];
  console.log(elements)
  elements.map(el => {
    contacts.push({
      ...el,
      name: el.contact.firstName + ' ' + el.contact.lastName,
      missingFields: getParseMissingFieldsString(el.contact.missing_fields.fields)
    });
  });
  return contacts;
};

export const searchHouseholdsList = (elements, searchTerm) => {
  return elements.filter(el => el.householdName && el.householdName.toUpperCase().includes(searchTerm.toUpperCase()));
};

export const filterListBasedOnMissingFields = (elements) => {
  if (elements && elements.length > 0) {
    return elements.filter(el => el.missing_fields.totalMissing > 0);
  } else {
    return elements;
  }
};

export const getListBasedOnTabFilter = (elements, filter) => {
  switch (filter) {
    case TabFilters.incomplete:
      return filterCompleteRecords(elements);
    case TabFilters.complete:
      return filterIncompleteRecords(elements);
    case TabFilters.moved:
      return filterMovedRecords(elements);
    default:
      return elements;
  }
}

export const filterCompleteRecords = (elements) =>
  elements ? elements.filter(el => el.missing_fields.totalMissing === 0) : elements;

export const filterIncompleteRecords = (elements) =>
  elements ? elements.filter(el => el.missing_fields.totalMissing > 0) : elements;

export const filterMovedRecords = (elements) =>
  elements ? elements.filter(el => el.isMoved) : elements;

export const getNumberOfIncompleteEnteries = (elements) => {
  const incompleteEntries = filterListBasedOnMissingFields(elements);
  return incompleteEntries.length;
};

export const searchContactsList = (elements, searchTerm) => {
  if (elements.length > 0 && searchTerm !== '') {
    const term = searchTerm.toUpperCase();
    return elements.filter(el => {
      return ((el.firstName && el.firstName.toUpperCase().includes(term)) ||
        (el.lastName && el.lastName.toUpperCase().includes(term)) ||
        (el.middleName && el.middleName.toUpperCase().includes(term)) ||
        (el.primaryEmail && el.primaryEmail.toUpperCase().includes(term))
      )
    });
  } else {
    return elements;
  }
};

export const getRandomId = (prefix) => {
  return Math.random().toString(36).replace('0.', prefix || '');
};

export const searchAccountsList = (elements, searchTerm) => {
  if (elements.length > 0) {
    const term = searchTerm.toUpperCase();
    return elements.filter(el => {
      return (el.accountNumbe && el.accountNumber.toUpperCase().includes(term) ||
        el.houseHoldName && el.houseHoldName.toUpperCase().includes(term)
      )
    });
  } else {
    return elements;
  }
};

export const getOptionsFromEnumerations = (field, type, enumerations) => {
  if (enumerations[ type ] && enumerations[ type ][ field ]) {
    const availableOptions = enumerations[ type ][ field ];
    const optionsArray = [];
    availableOptions.map(el => {
      optionsArray.push({
        label: el,
        value: el
      });
    });
    return optionsArray;
  } else {
    return [];
  }
};

export const extractFieldLabel = (field, type, fieldsDictionary) => {
  if (fieldsDictionary[ type ] && fieldsDictionary[ type ][ field ]) {
    return fieldsDictionary[ type ][ field ][ 'en' ];
  } else {
    return '';
  }
};

export const calculateHouseholdTotalMissingFields = (household) => {
  let totalMissingFields = 0;
  totalMissingFields = totalMissingFields + household.missing_fields.totalMissing;
  if (household && household.household_members && household.household_members.length > 0) {
    if (household.household_members && household.household_members.length > 0) {
      household.household_members.map((el) => {
        totalMissingFields = totalMissingFields + el.contact?.missing_fields.totalMissing;
      });
    }
  }
  return totalMissingFields;
};

export const sortTableColumn = (key, records, order) => {
  // console.log('key: ', key);
  // console.log('order: ', order);
  // console.log('records before sort: ', records);
  let sortedData = [ ...records ];
  if (key && order) {
    sortedData.sort((a, b) => {
      if (a[ key ] < b[ key ]) {
        return order === 'asc' ? 1 : -1;
      }
      if (a[ key ] > b[ key ]) {
        return order === 'dec' ? 1 : -1;
      }
      return 0;
    });
  }
  // console.log('records after sort: ', sortedData);
  return sortedData;
};

export const parseValidations = (validation) => {
  if (Array.isArray(validation)) {
    let validationString = '';
    validation.map((el, index) => {
      validationString = validationString + el + (index < (validation.length - 1) ? ', ' : '');
    });
    return validationString;
  } else {
    return validation;
  }
};

export const getOptions = (array) => {
  const optionsArray = [];
  array.map(el => {
    optionsArray.push({
      label: el,
      value: el
    });
  });
  return optionsArray;
};

export const searchImportLogs = (elements, searchTerm) => {
  // console.log(searchTerm);
  if (elements.length > 0 && searchTerm !== '') {
    const term = searchTerm.toUpperCase();
    const filteredElements = elements.filter(el => {
      return ((el.file && el.file.toUpperCase().includes(term)) ||
        (el.transaction_type && el.transaction_type.toUpperCase().includes(term)) ||
        (el.status && el.status.toUpperCase().includes(term)) ||
        (el.target && el.target.toUpperCase().includes(term))
      )
    });
    // console.log(filteredElements);
    return filteredElements;
  } else {
    return elements;
  }
};

export const searchQueryToObject = (searchQuery) => {
  let pairs = searchQuery.substring(1).split('&'),
    obj = {},
    pair,
    i;
  for (i in pairs) {
    if (pairs[ i ] === '') continue;

    pair = pairs[ i ].split('=');
    obj[ decodeURIComponent(pair[ 0 ]) ] = decodeURIComponent(pair[ 1 ]);
  }
  return obj;
};

export const getEnabledColumnsFromColumnPref = (columnsPref) => {
  const prefArray = Object.keys(columnsPref);
  const columns = [];
  prefArray.map(el => {
    if (columnsPref[ el ] === true) {
      columns.push(el);
    }
  });
  return columns;
};

export const getVisibleLabels = (labels) => {
  const labelsArray = [];
  Object.keys(labels).map(el => {
    if (labels[ el ].visibility.toUpperCase() === 'PUBLIC') {
      labelsArray.push(el);
    }
  });
  return labelsArray;
};

export const returnSelectedColumnValue = (data, column) => {
  if (column === 'relinquishingInstitution') {
    if (data.relinqhishing_institution) {
      return data.relinqhishing_institution.institutionName;
    }
  } else if (column === 'beneficiary') {
    if (data.account_beneficiary) {
      return data.account_beneficiary?.benFirstName + ' ' + data.account_beneficiary?.benLastName;
    }
  }
  return data[ column ];
};

export const getContactName = (contact) => {
  let contactName = 'Contact';
  if (contact) {
    contactName = contact?.firstName + ' ' + contact?.lastName;
  }
  return contactName;
};

export const getProspectSpousalContacts = (members, activeContactIndex) => {
  const contacts = [ ...members ];
  contacts.splice(activeContactIndex, 1);
  const availableContacts = [];
  contacts.map((el) => {
    availableContacts.push({
      label: el.contact.contact_name,
      value: el.memberQID
    });
  });
  return availableContacts;
};

export const checkIfFieldIsRequired = (field, type, fieldsDictionary) => {
  if (fieldsDictionary[ type ] && fieldsDictionary[ type ][ field ]) {
    return fieldsDictionary[ type ][ field ].required;
  } else {
    return false;
  }
};

export const getFileExtensionFromFileName = (fileName) => {
  return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
};

export const getContactByAccountID = (account, list) => {
  if (typeof account !== 'undefined' && account !== null) {
    return list.find(item => item.contactQID === account.accountOwnerId)
  }
};

export const getMissingFieldsFromObjectBasedOnLabels = (obj, objLabels) => {
  let count = 0;
  if (typeof obj !== 'undefined' && obj !== null) {
    const keys = Object.keys(obj);
    keys.map(el => {
      if (typeof objLabels[ el ] !== 'undefined') {
        if (objLabels[ el ].required && !obj[ el ]) {
          count = count + 1;
        }
      }
    });
  } else {
    const baseObjKeys = Object.keys(objLabels);
    if (baseObjKeys.length > 0) {
      baseObjKeys.map(el => {
        if (objLabels[ el ].required) {
          if (el === 'addressId' || el === 'employerAddress') {
            count = count + 6;
          } else {
            count = count + 1;
          }
        }
      });
    }
  }
  return count;
};

export const getContactsTotalMissingFields = (contact, labels) => {
  let missingFieldsCount = 0;
  let contactInfoMissingFieldsCount = 0;
  let contactVerificiationMissingFieldsCount = 0;
  if (typeof contact !== undefined && contact !== null) {
    const contactKeys = Object.keys(contact);
    if (contactKeys.length > 0) {
      contactKeys.map(el => {
        if (![ 'address', 'banking_account_detail', 'contact_income_detail', 'identification' ].includes(el)) {
          if (typeof labels[ 'contacts' ][ el ] !== 'undefined') {
            // console.log(el, ' found', labels['contacts'][el]['required'] && !contact[el]);
            if (labels[ 'contacts' ][ el ][ 'required' ] && !contact[ el ]) {
              contactInfoMissingFieldsCount = contactInfoMissingFieldsCount + 1;
            }
          }
        } else {
          switch (el) {
            case 'address':
              if (!contact.addressSameAsHousehold) {
                contactInfoMissingFieldsCount = contactInfoMissingFieldsCount + getMissingFieldsFromObjectBasedOnLabels(contact.address, labels.address);
              }
              break;
            case 'banking_account_detail':
              contactVerificiationMissingFieldsCount = contactVerificiationMissingFieldsCount + getMissingFieldsFromObjectBasedOnLabels(contact.banking_account_detail, labels.bankingAccountDetails);
              break;
            case 'contact_income_detail':
              contactInfoMissingFieldsCount = contactInfoMissingFieldsCount + getMissingFieldsFromObjectBasedOnLabels(contact.contact_income_detail, labels.contactIncomeDetails);
              break;
            case 'identification':
              contactVerificiationMissingFieldsCount = contactVerificiationMissingFieldsCount + getMissingFieldsFromObjectBasedOnLabels(contact.identification, labels.identificationid);
              break;
            default:
              // do nothing;
              break;
          }
        }
      });
    }
  }
  return {
    contactInfo: contactInfoMissingFieldsCount,
    contactVerification: contactVerificiationMissingFieldsCount,
    totalMissing: (contactInfoMissingFieldsCount + contactVerificiationMissingFieldsCount)
  }
};

export const getHouseholdOverviewMissingFields = (household, labels) => {
  let missingFieldsCount = 0;
  if (typeof household !== 'undefined' && household !== null) {
    const householdKeys = Object.keys(household);
    if (householdKeys.length > 0) {
      householdKeys.map(el => {
        if (![ 'household_members', 'missing_fields', 'address' ].includes(el)) {
          if (typeof labels[ 'households' ][ el ] !== 'undefined') {
            if (labels[ 'households' ][ el ][ 'required' ] && !household[ el ]) {
              missingFieldsCount = missingFieldsCount + 1;
            }
          }
        } else if (el === 'address') {
          missingFieldsCount = missingFieldsCount + getMissingFieldsFromObjectBasedOnLabels(household.address, labels.address);
        }
      });
    } else {
      return 5;
    }

  }
  return missingFieldsCount;
};

export const calculateHouseholdMissingFields = (household, labels) => {
  const missingFields = {
    householdOverview: household ? getHouseholdOverviewMissingFields(household, labels) : 0
  };
  if (typeof household !== 'undefined' && household) {
    if (household.household_members && household.household_members.length > 0) {
      household.household_members.map(el => {
        missingFields[ el.memberQID ] = getContactsTotalMissingFields(el.contact, labels);
      });
    }
  }
  return missingFields;
};

export const parseHouseholdMembersForHouseholdsList = (members, labels) => {
  const householdMembers = [];
  members.forEach(el => {
    householdMembers.push({
      ...el,
      missingFields: getContactsTotalMissingFields(el.contact, labels)
    });
  });
  return householdMembers;
};

export const getHouseholdMissingFieldsSum = (household, labels) => {
  const householdMissingFields = calculateHouseholdMissingFields(household, labels);
  const missingFieldsKeys = Object.keys(householdMissingFields);
  let totalMissingFieldsCount = 0;
  if (missingFieldsKeys.length > 0) {
    missingFieldsKeys.forEach(el => {
      if (el === 'householdOverview') {
        totalMissingFieldsCount = totalMissingFieldsCount + householdMissingFields[ el ];
      } else {
        totalMissingFieldsCount = totalMissingFieldsCount + householdMissingFields[ el ].totalMissing;
      }
    });
  }
  return totalMissingFieldsCount;
}

export const searchRelinquishingInstitutionList = (elements, searchTerm) => {
  if (elements.length > 0) {
    const term = searchTerm.toUpperCase();
    return elements.filter(el => {
      return (el.institutionName && el.institutionName.toUpperCase().includes(term) ||
        el.institutionContact && el.institutionContact.toUpperCase().includes(term) ||
        el.institutionEmail && el.institutionEmail.toUpperCase().includes(term)
      )
    });
  } else {
    return elements;
  }
};
