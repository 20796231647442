import { Client } from '@microsoft/microsoft-graph-client';
import { getAccessAndIdToken } from '@qwealth/qcore';

async function getAuthenticatedClient() {
  const authenticationResult = await getAccessAndIdToken();
  
  const { accessToken: newAccessToken } = authenticationResult;
  // Initialize Graph client
  return Client.init({
    // Use the provided access token to authenticate requests
    authProvider: done => {
      done(null, newAccessToken || null);
    },
  });
}

export async function getUserDetails() {
  const client = await getAuthenticatedClient();
  return await client
    .api('/me')
    .select('id,displayName,givenName,mail,surname,userPrincipalName')
    .get();
}

export async function getUserAvatar() {
  const client = await getAuthenticatedClient();
  return await client.api('/me/photo/$value').get();
}