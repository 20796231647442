import React from 'react';
import styles from './progressBar.module.scss';

const ProgressBar = () => {
	return (
		<div className={styles.progressBar}>
			<div className="meter animate">
				<span style={{ width: '100%' }}><span></span></span>
			</div>
		</div>
	);
};

export default ProgressBar;
