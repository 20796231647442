import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, logout, styled } from '@qwealth/qcore';
import { useSelector } from 'react-redux';
import { getUserAvatar } from '../../services/microsoft/graphService';

const Avatar = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const StyledPopup = styled(Box)`
  position: absolute;
  z-index: 997;
  right: 5px;
  top: 59px;
  opacity: 0;
  display: none;

  &:after {
    content: '';
    position: absolute;
    top: -8px;
    right: 12px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }

  &.show {
    display: block;
    animation: fadein 0.25s linear forwards;
  }

  @keyframes fadein {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const url = window.URL || window.webkitURL;

export default function (props) {
  const { push } = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [source, setSource] = useState(null);
  const [initials, setInitials] = useState(null);

  const user = useSelector(state => state.microsoft.user);

  useEffect(() => {
    if (!source) {
      getUserAvatar()
        .then(data => url.createObjectURL(data))
        .then(blobUrl => setSource(blobUrl))
        .catch(err => console.log(err));
    }
  }, [source]);

  useEffect(() => {
    if (user.givenName && user.surname) {
      setInitials(`${user.givenName.substring(0, 1)}${user.surname.substring(0, 1)}`);
    }
  }, [user]);

  const DefaultAvatar = (
    <Box
      borderRadius="round"
      height="50px"
      width="50px"
      bg="primary"
      fontSize="18px"
      fontWeight="bold"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor="red"
    >
      {initials}
    </Box>
  );

  const CustomAvatar = <Avatar alt="avatar" src={source} {...props} />;

  const handleClick = () => {
    setShowPopup(false);
    push({ pathname: '/profile' });
  };

  return (
    <Box display="flex" justifyContent="flex-end" mb="large">
      <Box display="flex" alignItems="center" onClick={() => setShowPopup(!showPopup)}>
        <Box style={{ position: 'relative' }}>
          {source ? CustomAvatar : DefaultAvatar}

          <StyledPopup
            flexDirection="column"
            display="flex"
            className={showPopup ? 'show' : undefined}
            boxShadow="layout"
            height="auto"
            width="auto"
            p="default"
            backgroundColor="white"
            borderRadius="default"
          >
            <Button variant="outline" mb="default" color="red" onClick={handleClick}>
              PROFILE
            </Button>
            {/* TODO: fix this later @Hoang */}
            <Button color="primary" color="red" onClick={logout}>
              LOG OUT
            </Button>
          </StyledPopup>
        </Box>
        <Box ml="default" fontWeight="bold">
          {user.displayName}
        </Box>
      </Box>
    </Box>
  );
}
