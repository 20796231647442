import React from 'react';
import styles from './tableRow.module.scss';

const renderRows = (account, labelsArray, rowAction, columns) => {
	if (account !== null) {
		if (typeof columns !== 'undefined' && typeof columns !== null) {
			return columns?.map((el, index) => {
				if (el['enabled'] === true) {
					
					if (account.hasOwnProperty(el['key'])) {
                        return (
                            <td key={index}>
                                {account[el['key']]}
                            </td>
                        );
                    } else {
                        if(account.relinqhishing_institution !== null && account.relinqhishing_institution !== undefined) {
							if(el['key']==='relinquishingInstitution') {
								return (
									<td key={index}>
										{account.relinqhishing_institution.institutionName}
									</td>
								);
							}
                            
                        }
                    }
				}
			});
		} else {
			return labelsArray.map((el, index) => {
				return (
					<td key={index}>
						{account[el]}
					</td>
				)
			})
		}
	}
}

const TableRow = (props) => {
	return (
		<>
			<tr>
				{renderRows(props.account, props.labelsArray, props.rowAction, props.columns)}
				<td className={styles.actionContainer}>
					<button className={styles.themeTableAction} onClick={() => props.rowAction(props.account)}>Edit</button>
				</td>
			</tr>
		</>
	);
}

export default TableRow;
