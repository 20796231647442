import React, { useEffect } from 'react';
import styles from './formControl.module.scss';
import cx from 'classnames';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { BsFillCalendarFill } from 'react-icons/bs';
import moment from 'moment';
import { GrDrag } from 'react-icons/gr'

const renderDropDown = (params) => {
	let computedValue = null;
	if (typeof params.value === 'object' && params.value !== null) {
		computedValue = params.value;
	} else {
		computedValue = {
			label: params.value,
			value: params.value
		}
	};
	return (
		<>
			<Select
				placeholder={params.placeholder || ''}
				className={cx((!params.value || params.warning) && [styles.missing, styles.missingDropDown], styles.dropDown)}
				options={params.options || []}
				name={params.name}
				onChange={(value) => { params.updateValue('', value, params.pathPrefix + params.name) }}
				value={computedValue}
			/>
		</>
	);
};

const renderTextInput = (params) => {
	//console.log(params);
	return (
		<>
			<input
				type="text"
				placeholder={params.placeholder || ''}
				className={cx((params.required) && !params.value && styles.missing)}
				value={params.value || ''}
				name={params.name}
				onChange={(e) => { params.updateValue(e, e.target.value, params.pathPrefix + params.name) }}
			/>
		</>
	);
};

const renderCheckbox = (params) => {
	if (params && params.dragable) {
		return (
			<>
				<div className={cx(params.style ? params.style : styles.formCheckboxContainer, params?.DragOn ? styles.dragDiv : '', styles.displayFlex)} draggable="true"
				     onDragStart={(e) => { params?.onDragStart(e, params.id) }}
				     onDrop={(e) => { params?.onDrop(e, params.id) }}
				     onDragOver={(e) => { params?.onDragOver(e) }}>
					<GrDrag style={{ marginRight: '6px', strokeOpacity: '0.5', marginTop: '3px' }} />
					<label className={cx(styles.checkbox, styles.bounce)}>
						<input type="checkbox" checked={params.checked} onChange={params.onChange} name={params.name} />
						<svg viewBox="0 0 21 21">
							<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
						</svg>
						{params.label}
					</label>
				</div>
			</>
		);
	} else  {
		return (
			<>
				<div className={params.style ? params.style : styles.formCheckboxContainer}>
					<label className={cx(styles.checkbox, styles.bounce)}>
						<input type="checkbox" checked={params.checked} onChange={params.onChange} name={params.name}/>
						<svg viewBox="0 0 21 21">
							<polyline points="5 10.75 8.5 14.25 16 6"></polyline>
						</svg>
						{params.label}
					</label>
				</div>
			</>
		);
	}
};

const renderDatePicker = (params) => {
	return (
		<div className={cx(styles.datePickerContainer, !params.value && styles.missing)}>
			<span><BsFillCalendarFill style={{ height: '16px', width: '16px' }} /></span>
			<DatePicker
				className={cx(!params.value && styles.missing)}
				value={params.value}
				selected={params.value ? moment(params.value).toDate() : ''}
				dateFormat='YY-MM-DD'
				onChange={date => { params.updateValue('DATE', date, params.pathPrefix + params.name) }}
			/>
		</div>
	);
};

const renderFileField = (params) => {
	return (
		<>
			<input
				type="file"
				placeholder={params.placeholder || ''}
				className={cx(!params.value && styles.missing)}
				name={params.name}
				// onChange={(e) => { params.updateValue(e, e.target.value, params.pathPrefix + params.name) }}
				onChange={(e) => params.updateValue(e)}
				accept={params.fileTypes}
			/>
		</>
	);
};

const FormControl = (props) => {

	return (
		<div className={styles.formControl}>
			{(!props.type || props.type.toUpperCase() !== 'CHECKBOX') && <label>{props.label}</label>}
			{props.type && props.type.toUpperCase() === 'DROPDOWN' && renderDropDown(props)}
			{(!props.type || props.type.toUpperCase() === 'TEXT') && renderTextInput(props)}
			{(props.type && props.type.toUpperCase() === 'CHECKBOX') && renderCheckbox(props)}
			{(props.type && props.type.toUpperCase() === 'DATE') && renderDatePicker(props)}
			{(props.type && props.type.toUpperCase() === 'FILE') && renderFileField(props)}
			{props.warning && props.warningMessage && <p className={styles.warningText}>{props.warningMessage}</p>}
		</div>
	);
};

export default FormControl;
