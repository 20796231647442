import { useEffect, useState } from 'react';
import { getListBasedOnTabFilter } from '../helpers';
import { TabFilters } from './../constants';

export const useTabFilter = ({ activeFilter = TabFilters.all, dataList }) => {
  const [ filteredList, setFilteredList ] = useState({
    [ TabFilters.all ]: {
      list: [],
      count: 0
    },
    [ TabFilters.complete ]: {
      list: [],
      count: 0
    },
    [ TabFilters.incomplete ]: {
      list: [],
      count: 0
    },
    [ TabFilters.moved ]: {
      list: [],
      count: 0
    },
    activeList: dataList,
  });

  useEffect(() => {
    const all = getListBasedOnTabFilter(dataList, TabFilters.all);
    const complete = getListBasedOnTabFilter(dataList, TabFilters.complete);
    const incomplete = getListBasedOnTabFilter(dataList, TabFilters.incomplete);
    const moved = getListBasedOnTabFilter(dataList, TabFilters.moved);
    setFilteredList({
      [ TabFilters.all ]: {
        list: all,
        count: all.length
      },
      [ TabFilters.complete ]: {
        list: complete,
        count: complete.length
      },
      [ TabFilters.incomplete ]: {
        list: incomplete,
        count: incomplete.length
      },
      [ TabFilters.moved ]: {
        list: moved,
        count: moved.length
      },
      activeList: all
    });
  }, [ dataList, setFilteredList ])

  useEffect(() => {
    setFilteredList((s) => ({ ...s, activeList: s[ activeFilter ].list }))
  }, [ activeFilter ])

  return filteredList;
}