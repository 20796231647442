import React from 'react';
import styles from './contactVerification.module.scss';
import FormControl from '../formControl/formControl';
import AddressForm from '../addressForm/addressForm';
import Dropzone from '../dropzone/dropzone';
import DocumentPreview from '../documentPreview/documentPreview';

const ContactVerification = (props) => {
	const getLabel = props.getFieldLabel;
	const contact = props.contact;
	
	return (
		<>
			<div>
				<h3 className={styles.styledHeading}>
					Basic Info
				</h3>
			</div>
			<div className={styles.formControlContainer}>
				<div>
					<FormControl
						label={getLabel('identificationNumber', 'identificationid')}
						value={contact && contact.identification ? contact.identification.identificationNumber : ''}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.identification.'}
						updateValue={props.update}
						name='identificationNumber'
						required={props.isFieldRequired('identificationNumber', 'identificationid')}
					/>
					<FormControl
						label={getLabel('identificationExpiryDate', 'identificationid')}
						type={'Date'}
						value={contact && contact.identification ? contact.identification.identificationExpiryDate : ''}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.identification.'}
						updateValue={props.update}
						name='identificationExpiryDate'
						required={props.isFieldRequired('identificationExpiryDate', 'identificationid')}
					/>
					<FormControl
						label={getLabel('identificationType', 'identificationid')}
						type={'Dropdown'}
						options={props.getOptions('identificationType', 'identificationid')}
						value={contact && contact.identification ? contact.identification.identificationType : ''}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.identification.'}
						updateValue={props.update}
						name='identificationType'
						required={props.isFieldRequired('identificationType', 'identificationid')}
					/>
				</div>
				<div>
					<DocumentPreview
						label={'Identity Document Preview'}
						fileUrl={contact && contact.identification ? contact.identification.identificationDocumentUrl : null}
						fileName={contact && contact.identification ? contact.identification.identificationDocument : null}
					/>
				</div>
				<div>
					<Dropzone
						label={'Upload Verification Image'}
						identification={contact.identification}
						contact={contact.contactQID}
						household={props.household.householdQID}
					/>
				</div>
			</div>
			<div>
				<h3 className={styles.styledHeading}>
					Bank Information
				</h3>
			</div>
			<div className={styles.formControlContainer}>
				<div>
					<FormControl
						label={getLabel('bankAccountNum', 'bankingAccountDetails')}
						value={contact && contact.banking_account_detail ? contact.banking_account_detail.bankAccountNum : ''}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.banking_account_detail.'}
						updateValue={props.update}
						name='bankAccountNum'
						required={props.isFieldRequired('bankAccountNum', 'bankingAccountDetails')}
					/>
					<div>
						<FormControl
							label={getLabel('institutionName', 'bankingAccountDetails')}
							value={contact && contact.banking_account_detail ? contact.banking_account_detail.institutionName : ''}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.banking_account_detail.'}
							updateValue={props.update}
							name='institutionName'
							required={props.isFieldRequired('institutionName', 'bankingAccountDetails')}
						/>
					</div>
					<div>
						<FormControl
							label={getLabel('bankInstNum', 'bankingAccountDetails')}
							value={contact && contact.banking_account_detail ? contact.banking_account_detail.bankInstNum : ''}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.banking_account_detail.'}
							updateValue={props.update}
							name='bankInstNum'
							required={props.isFieldRequired('bankInstNum', 'bankingAccountDetails')}
						/>
						<FormControl
							label={getLabel('bankTransitNum', 'bankingAccountDetails')}
							value={contact && contact.banking_account_detail ? contact.banking_account_detail.bankTransitNum : ''}
							pathPrefix={'household_members.[' + props.contactIndex + '].contact.banking_account_detail.'}
							updateValue={props.update}
							name='bankTransitNum'
							required={props.isFieldRequired('bankTransitNum', 'bankingAccountDetails')}
						/>
					</div>
				</div>
				<div>
					<DocumentPreview
						label={'Void Cheque Preview'}
						fileUrl={contact && contact.banking_account_detail ? contact.banking_account_detail.voidChequeDocumentUrl : null}
						fileName={contact && contact.banking_account_detail ? contact.banking_account_detail.voidChequeDocument : null}
					/>
				</div>
				<div>
					<Dropzone
						label={'Upload Void Cheque'}
						bankAccountDetails={contact.banking_account_detail}
						contact={contact.contactQID}
						household={props.household.householdQID}
					/>
				</div>
			</div>
			<div className={styles.formControlContainer}>
			
			</div>
			{contact && contact.banking_account_detail &&
				<div>
					<div>
						<h3 className={styles.styledHeading}>
							Bank Address
						</h3>
					</div>
					<AddressForm
						getFieldLabel={getLabel}
						address={contact.banking_account_detail.address}
						onUpdate={props.update}
						pathPrefix={'household_members.[' + props.contactIndex + '].contact.banking_account_detail.address.'}
						isFieldRequired={props.isFieldRequired}
					/>
				</div>
			}
		</>
	);
};

export default ContactVerification;
