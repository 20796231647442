import React from 'react';
import { Box } from '@qwealth/qcore';
import HouseholdsSummaryTable from './householdSummaryTable';
import styles from './households.module.scss';
import { BsChevronRight, BsFillCaretUpFill } from 'react-icons/bs';
import { getTotalElementsWhichNeedAction } from '../../helpers';

const HouseholdSummary = (props) => {
	return (
		<>
			<Box
				backgroundColor="#FFFFFF"
				mb="large"
				display="flex"
				justifyContent="flex-end"
				borderRadius="12px"
				padding="20px"
				flexDirection="column"
			>
				<div className={styles.summaryBoxHeader}>
					<div>
						<h2 className={styles.summaryHeader}>
							Households
						</h2>
					</div>
					<div>
						<div>
							<p>Households</p>
							<span>
								{props.households.length}
							</span>
						</div>
						<div>
							<p>Need Action</p>
							<span className={styles.needAction}>
								{getTotalElementsWhichNeedAction(props.households)} <BsFillCaretUpFill />
							</span>
						</div>
					</div>
				</div>
				<HouseholdsSummaryTable
					fetchingHouseholds={props.fetchingHouseholds}
					households={props.households.slice(0, 3)}
				/>
				<div className={styles.summaryBoxFooter}>
					<button onClick={props.onViewAll} className={styles.viewAllButton}>View All <BsChevronRight /></button>
				</div>
			</Box>
		</>
	);
}

export default HouseholdSummary;
