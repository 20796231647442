import React from 'react';
import styles from './loading.module.scss';
import { Loader } from '@qwealth/qcore';

const LoadingIndicator = (props) => {
	return (
		<div className={styles.loadingIndicatorContainer} style={{ minHeight: props.height ? props.height : '200px' }}>
			<Loader size={props.size ? props.size : 'small'} />
		</div>
	);
};

export default LoadingIndicator;
