import axios from 'axios';
import { createApi } from './index';

function getConsentUrl(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/docusign/get/consent', params.data, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

function getEmbeddedSendingUrl(params) {
  const options = {
    method: 'POST'
  };
  const API = createApi(axios);
  return API.post('/docusign/embedded/sending', params.data, options)
    .then(result => {
      return result;
    })
    .catch(error => {
      throw error.response;
    })
}

export const docusignIntegrationServices = {
  getConsentUrl,
  getEmbeddedSendingUrl
};
