import { put, call, cancel } from 'redux-saga/effects';
import Creators, {savingHousehold} from '../state/AppRedux';
import { householdsServices } from '../services/householdsServices';
import { toast } from 'react-toastify';


export function * fetchHouseholds(data) {
	yield put(Creators.isFetchingHouseholds(true));
	let result = null;
	try {
		result = yield call(householdsServices.fetchHouseholdsList, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingHouseholds(false));
			yield put(Creators.setHouseholds(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingHouseholds(false));
		console.log(e);
	}
}

export function * fetchDetailedHouseholdsList(data) {
	yield put(Creators.isFetchingHouseholds(true));
	let result = null;
	try {
		result = yield call(householdsServices.fetchHouseholdsDetailedList, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingHouseholds(false));
			yield put(Creators.setDetailedHouseholds(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingHouseholds(false));
		console.log(e);
	}
}

export function * fetchHouseholdDetails(data) {
	yield put(Creators.isFetchingHouseholds(true));
	let result = null;
	try {
		result = yield call(householdsServices.fetchHouseholdDetails, data.qid);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingHouseholds(false));
			yield put(Creators.setHousehold(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingHouseholds(false));
		console.log(e);
	}
}

export function * saveHousehold(data) {
	yield put(Creators.savingHousehold(true));
	let result = null;
	try {
		result = yield call(householdsServices.saveHousehold, data.household);
		if (result.status && result.status === 200) {
			yield put(Creators.savingHousehold(false));
			yield put(Creators.setHousehold(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.savingHousehold(false));
		console.log(e);
	}
}
