import React from 'react';
import styles from './householdDetailsHeader.module.scss';
import { BsEnvelope, BsChevronDown, BsPlus } from 'react-icons/bs';
import userImage from '../../assets/user.png';
import TabsNav from '../tabsNav/tabsNav';
import { useParams } from 'react-router-dom';

//TODO: GET WRITE ACCESS TO THE FIGMA WIREFRAMES TO EXTRACT THE PNG FOR THE HEADER BACKGROUND

const HouseholdDetailsHeader = (props) => {
  const { QID } = useParams();
  return (
    <>
      <div className={styles.headerContainer}>
        <div>
          <h1>{props.household ? props.household.householdName : QID}</h1>
        </div>

        <div>
          {/* Temp Hide: QC-1669 
					<div className={styles.assignment}>
						<span>Assigned To</span>
						<div>
							<img src={userImage} alt="user" />
							<span>Jacob Jones</span>
							<span>
								<BsChevronDown style={{ strokeWidth: '1.2' }} />
							</span>
						</div>
					</div>
					<div>
						<button>
							<span><BsEnvelope style={{ strokeWidth: '1.2' }} /></span> Email Missing Info
						</button>
						<button onClick={() => props.saveHousehold()}>
							<span><BsPlus style={{ strokeWidth: '1.2' }} /></span> Save
						</button>
					</div> */}
        </div>
      </div>
      <TabsNav
        household={props.household}
        switchTab={props.switchTab}
        activeTab={props.activeTab}
        addNewContact={props.addNewContact}
        labels={props.labels}
        missingFields={props.missingFields}
      />
    </>
  );
}

export default HouseholdDetailsHeader;
