import axios from 'axios';

export const AXIOS_INITIALIZED = 'AXIOS_INITIALIZED';

const initializeAxios = () => ({ type: AXIOS_INITIALIZED });

export const configureAxios = idToken => async dispatch => {
  axios.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  dispatch(initializeAxios());
  return Promise.resolve(true);
};
