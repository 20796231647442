import { AXIOS_INITIALIZED } from '../actions/axios';

export default function (state = false, action) {
  switch (action.type) {
    case AXIOS_INITIALIZED: {
      return true;
    }
    default:
      return state;
  }
}
