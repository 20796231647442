import { put, call, cancel } from 'redux-saga/effects';
import Creators, {updateBankAccountInfo, updateIdentityVerificationInfo} from '../state/AppRedux';
import { contactsServices } from '../services/clientsServices';
import { toast } from 'react-toastify';


export function * fetchContacts(data) {
	yield put(Creators.isFetchingContacts(true));
	let result = null;
	try {
		result = yield call(contactsServices.fetchContactsList, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetchingContacts(false));
			yield put(Creators.setContacts(result.data.response));
		}
	} catch(e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetchingContacts(false));
		console.log(e);
	}
}

export function * updateContact(data) {
	yield put(Creators.isFetching(true));
	let result = null;
	try {
		result = yield call(contactsServices.updateContact, data);
		if (result.status && result.status === 200) {
			yield put(Creators.isFetching(false));
			yield put(Creators.updateContactRes(result.data.response));
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.isFetching(false));
		console.log(e);
	}
}

export function * uploadIdentityDocument(data) {
	yield put(Creators.savingHousehold(true));
	let result = null;
	try {
		result = yield call(contactsServices.uploadIdentityDocument, data);
		if (result.status && result.status === 200) {
			yield put(Creators.savingHousehold(false));
			toast.success('Document uploaded successfully.');
			const responseObj = {
				contact: data.data.contact,
				household: data.data.household,
				response: result.data.response
			};
			yield put(Creators.updateIdentityVerificationInfo(responseObj));
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.savingHousehold(false));
		console.log(e);
	}
}

export function * uploadVoidChequeDocument(data) {
	yield put(Creators.savingHousehold(true));
	let result = null;
	try {
		result = yield call(contactsServices.uploadVoidChequeDocument, data);
		if (result.status && result.status === 200) {
			yield put(Creators.savingHousehold(false));
			toast.success('Document uploaded successfully.');
			const responseObj = {
				contact: data.data.contact,
				household: data.data.household,
				response: result.data.response
			};
			yield put(Creators.updateBankAccountInfo(responseObj));
		}
	} catch (e) {
		toast.error('Something went wrong. Please try again later.');
		yield put(Creators.savingHousehold(false));
		console.log(e);
	}
}
