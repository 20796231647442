import React, { useState, useEffect } from 'react';
import styles from './popover.module.scss';
import FormControl from '../formControl/formControl';
import { connect } from 'react-redux';
import {
	selectContacts,
	selectLabels,
	selectTableColumnsPref
} from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { getVisibleLabels } from '../../helpers';
import _ from 'lodash';

const renderTableColumns = (columns, labels, changeHandler, getValue, onDragStart, onDrop, onDragOver, DragOn) => {
	if (columns) {
		return columns.map((el, index) => {
			return (
				<>
					<FormControl
						key={index}
						DragOn={DragOn}
						id={index}
						key={index}
						style={styles.paddingNone}
						label={labels[el['key']].en}
						type={'Checkbox'}
						name={el['key']}
						pathPrefix={''}
						checked={el['enabled']}
						onChange={(e) => {
							changeHandler(e)
						}}
						onDragStart={onDragStart}
						onDrop={onDrop}
						onDragOver={onDragOver}
						dragable
					/>
				</>
			);
		});
	}
};

const Popover = (props) => {
	const [columns, setColumns] = useState([]);
	const [existingRow, setExistingRow] = useState();
	const [DragOn] = useState(true);
	const [labels, setLabels] = useState()

	useEffect(() => {
		let dataTypeLabels = []
		if (typeof props.columnPref !== 'undefined' && props.columnPref !== null && props.columnPref.hasOwnProperty(props.dataType)) {
			setColumns(props.columnPref[props.dataType]);
			if (props.dataType === 'relinquishingInstitution') {
				dataTypeLabels = { ...props.labels[props.dataType], ...props.labels.address }
				setLabels(dataTypeLabels)
			} else {
				dataTypeLabels = props.labels[props.dataType];
				setLabels(dataTypeLabels)
			}
		} else {
			if (props.dataType === 'relinquishingInstitution') {
				dataTypeLabels = { ...props.labels[props.dataType], ...props.labels.address }
				setLabels(dataTypeLabels)
			} else {
				dataTypeLabels = props.labels[props.dataType];
				setLabels(dataTypeLabels)
			}
			const columnsArray = getVisibleLabels(dataTypeLabels);
			const defaultColumns = {};
			columnsArray.map(el => {
				defaultColumns[el] = true;
			});
			const output = Object.entries(defaultColumns).map(([key, enabled, order]) => ({ key, enabled, order }));
			output.forEach((el, index) => el.order = index)
			setColumns(output);
		}
	}, [props.columnPref]);

	const onChangeHandler = (e) => {
		const existingColumn = _.cloneDeep(columns)
		const filterIndex = existingColumn.findIndex(item => item['key'] === e.target.name)
		existingColumn[filterIndex].enabled = e.target.checked
		setColumns(existingColumn);
	};

	const commitChanges = () => {
		props.setTableColumnsPref({
			...props.columnPref,
			[props.dataType]: columns
		});
		props.hideModal();
	};

	const getValue = (name) => {
		if (columns && columns.hasOwnProperty(name)) {
			return columns[name];
		} else {
			return true;
		}
	};

	const onDragStart = (e, id) => {
		setExistingRow(id)
	}

	const onDrop = (e, id) => {
		e.preventDefault();
		const existingReArrangeColumns = _.cloneDeep(columns)
		const replacedRow = existingReArrangeColumns.find(el => el.order == id)
		if (existingRow < replacedRow.order) {
			existingReArrangeColumns[existingRow].order = replacedRow.order
			for (let i = replacedRow.order; i > existingRow; i--) {
				existingReArrangeColumns[i].order = existingReArrangeColumns[i].order - 1
			}
		} else if (existingRow > replacedRow.order) {
			existingReArrangeColumns[existingRow].order = replacedRow.order
			for (let i = replacedRow.order; i < existingRow; i++) {
				existingReArrangeColumns[i].order = existingReArrangeColumns[i].order + 1
			}
		} else {
			existingReArrangeColumns.sort((a, b) => a.order - b.order)
		}
		existingReArrangeColumns.sort((a, b) => a.order - b.order)
		setColumns(existingReArrangeColumns)
	}

	const onDragOver = (e) => {
		e.preventDefault()
	}

	return (
		<div className={styles.popover__wrapper}>
			<div className={styles.popover__content}>
				<div>
					<div>
						<div>
							<div>
								<div>
									{renderTableColumns(columns, labels, onChangeHandler, getValue, onDragStart, onDrop, onDragOver, DragOn)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.modalFooter}>
				<button onClick={() => commitChanges()}>Save</button>
				<button onClick={() => props.hideModal()}>Cancel</button>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	contacts: selectContacts(state),
	labels: selectLabels(state),
	columnPref: selectTableColumnsPref(state),
});

const mapDispatchToProps = (dispatch) => ({
	setTableColumnsPref: (data) => dispatch(Creators.setTableColumnsPref(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Popover);
