import {takeLatest, all} from 'redux-saga/effects';
import { QMTypes }  from '../state/AppRedux';
import { fetchHouseholds, fetchDetailedHouseholdsList, fetchHouseholdDetails, saveHousehold } from './householdsSagas';
import { fetchContacts, updateContact, uploadIdentityDocument, uploadVoidChequeDocument } from './clientsSagas';
import { fetchAccounts, saveAccount, updateAccount } from './accountsSagas';
import { fetchGeneralSettings } from './settingsSagas';
import { bulkUPload, getBulkImportLogs, bulkExport } from './bulkUploadSagas';
import { fetchConsentUrl, fetEmbeddedSendingUrl } from './docusignIntegrationSagas';
import { fetchRelinquishingInstitutions } from './relinquishingInstitutionsSagas';

export default function* root() {
	yield all([
		takeLatest(QMTypes.FETCH_HOUSEHOLDS, fetchHouseholds),
		takeLatest(QMTypes.FETCH_CONTACTS, fetchContacts),
		takeLatest(QMTypes.FETCH_ACCOUNTS, fetchAccounts),
		takeLatest(QMTypes.FETCH_DETAILED_HOUSEHOLDS, fetchDetailedHouseholdsList),
		takeLatest(QMTypes.FETCH_GENERAL_SETTINGS, fetchGeneralSettings),
		takeLatest(QMTypes.FETCH_HOUSEHOLD_DETAILS, fetchHouseholdDetails),
		takeLatest(QMTypes.SAVE_HOUSEHOLD, saveHousehold),
		takeLatest(QMTypes.SAVE_ACCOUNT, saveAccount),
		takeLatest(QMTypes.UPDATE_ACCOUNT, updateAccount),
		takeLatest(QMTypes.UPDATE_CONTACT, updateContact),
		takeLatest(QMTypes.UPLOAD_CSV_AND_MAPPINGS, bulkUPload),
		takeLatest(QMTypes.FETCH_IMPORT_LOGS, getBulkImportLogs),
		takeLatest(QMTypes.BULK_EXPORT, bulkExport),
		takeLatest(QMTypes.FETCH_DOCUSIGN_CONSENT_URL, fetchConsentUrl),
		takeLatest(QMTypes.FETCH_EMBEDDED_SENDING_URL, fetEmbeddedSendingUrl),
		takeLatest(QMTypes.UPLOAD_IDENTITY_DOCUMENT, uploadIdentityDocument),
		takeLatest(QMTypes.UPLOAD_VOID_CHEQUE_DOCUMENT, uploadVoidChequeDocument),
		takeLatest(QMTypes.FETCH_RELINQUISHING_INSTITUTIONS, fetchRelinquishingInstitutions)
	])
}

