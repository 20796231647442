import React, { useEffect, useState, useRef } from 'react';
import styles from './householdDetails.module.scss';
import { Container } from '@qwealth/qcore';
import {
  Breadcrumbs,
  HouseholdDetailsHeader,
  Tabs,
  DocusignModal,
  ProgressBar
} from '../../components';
import { useParams, useLocation } from 'react-router-dom';
import {
  selectHousehold,
  selectLabels,
  selectEnumerations,
  selectIsFetchingHouseholds,
  selectDocusignConsentUrl,
  selectDocusignEmbeddedSendingUrl,
  selectTableColumnsPref,
  selectIsSavingHousehold
} from '../../state/Selectors';
import Creators from '../../state/AppRedux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  getOptionsFromEnumerations,
  extractFieldLabel,
  searchQueryToObject,
  checkIfFieldIsRequired,
  calculateHouseholdMissingFields
} from '../../helpers';
import moment from 'moment';
import cx from 'classnames';
import { HOUSEHOLD_SCHEMA, CONTACT_SCHEMA } from '../../constants';

// TODO: LIST COMPONENTS NEEDS BUTTONS TO REMOVE THE ITEMS FROM THE LISTS AS WELL
// TODO: REFACTOR THE BELOW METHOD TO BE MORE DYNAMIC AND MOVE IT OUT OF THE COMPONENT
// TODO: FETCH OPTIONS FOR ALL DROPDOWNS AS PER THE MOCKUPS
const generateBreadcrumbs = (household, qid) => {
  return [
    {
      title: 'Households',
      path: '/households'
    },
    {
      title: household,
      path: '/households/' + qid
    }
  ];
}

const HouseholdDetails = (props) => {
  const { QID } = useParams();
  const [ activeTab, setActiveTab ] = useState('HOUSEHOLD');
  const [ household, setHousehold ] = useState();
  const [ householdCopy, setHouseholdCopy ] = useState();
  const [ activeContact, setActiveContact ] = useState();
  const [ activeContactIndex, setActiveContactIndex ] = useState();
  const location = useLocation();
  const [ contactQID, setContactQID ] = useState('');
  const [ bypassPreviousConsent, setBypassPreviousConsent ] = useState(false);
  const [ docusignModal, setDocusignModal ] = useState(false);
  const [ missingFields, setMissingFields ] = useState(null);

  const timer = useRef();

  useEffect(() => {
    if (location && location.state) {
      if (location.state.hasOwnProperty('contactQID')) {
        setContactQID(location.state.contactQID);
      }
    }
    props.fetchGeneralSettings();
    if (typeof window !== 'undefined') {
      if (window.location && window.location.search) {
        const queryParams = searchQueryToObject(window.location.search);
        if (queryParams.consent) {
          setDocusignModal(true);
          getSendingUrl();
        }
      }
    }
    return () => {
      props.clearHouseholdDetails();
    };
  }, []);

  useEffect(() => {
    if (QID === 'new') {
      //console.log('new household');
      setHousehold(HOUSEHOLD_SCHEMA);
    } else {
      //console.log('fetching household');
      props.fetchHouseholdDetails(QID);
      props.setActiveHousehold(QID);
    }
  }, [ QID ]);

  useEffect(() => {
    if (props.household) {
      if (!_.isEqual(household, props.household)) {
        setHousehold(props.household);
        setHouseholdCopy(props.household);
        if (typeof activeContactIndex !== 'undefined') {
          setActiveContact(props.household.household_members[ activeContactIndex ].contact);
        }
      }
    }
  }, [ props.household ]);

  useEffect(() => {
    if (contactQID !== '') {
      switchTab(contactQID);
    }
    setMissingFields(calculateHouseholdMissingFields(household, props.labels));
  }, [ household ]);

  useEffect(() => {
    if (props.docusignConsentUrl) {
      if (typeof window !== 'undefined') {
        if (window.location) {
          const queryParams = searchQueryToObject(window.location.search);
          if (queryParams.consent && !bypassPreviousConsent) {
            props.cleanDocusignConsentUrl();
          } else {
            window.location.replace(props.docusignConsentUrl);
          }
        }
      }
    }
  }, [ props.docusignConsentUrl ])

  const breadcrumbsItems = props.household ? generateBreadcrumbs(props.household.householdName, QID) : generateBreadcrumbs(QID, QID);

  const switchTab = (tabToActivate) => {
    // console.log(tabToActivate);
    if (tabToActivate !== 'HOUSEHOLD') {
      if (household && household.household_members && household.household_members.length > 0) {
        const selectedContactIndex = household.household_members.findIndex(el => {
          return el.contact.contactQID === tabToActivate;
        });
        if (household.household_members && household.household_members.length > 0) {
          setActiveContact({
            ...household.household_members[ selectedContactIndex ].contact,
            spouse: getSpouseForSelectedContactIfExists(household.household_members[ selectedContactIndex ].contact)
          });
          setActiveContactIndex(selectedContactIndex);
        }
      }
    }
    setActiveTab(tabToActivate);
  };

  const getFieldLabel = (field, type) => {
    if (props.labels) {
      return extractFieldLabel(field, type, props.labels)
    } else {
      return '';
    }
  };

  const getOptions = (field, type) => {
    if (props.enumerations) {
      return getOptionsFromEnumerations(field, type, props.enumerations);
    } else {
      return [];
    }
  };

  const updateHousehold = (event, value, path) => {
    // console.log(event);
    // console.log(value);
    // console.log(path);
    clearTimeout(timer.current);
    let updatedValue = value;
    if (typeof value === 'object' && value !== null) {
      updatedValue = value.value;
    }
    if (typeof event !== 'object') {
      if (event.toUpperCase() === 'DATE') {
        updatedValue = moment(value).format('yyyy-MM-DD');
      }
    }
    // console.log(path, '\n',  value, '\n', updatedValue);
    let householdToUpdate = _.cloneDeep(household);
    // TODO: FIX THE _.set METHOD USAGE HERE
    householdToUpdate = _.set(householdToUpdate, path, updatedValue);
    setHousehold(householdToUpdate);
    timer.current = setTimeout(() => {
      saveHousehold(householdToUpdate);
    }, 2000);
    if (path && path.includes('household_members')) {
      setActiveContact({
        ...householdToUpdate.household_members[ activeContactIndex ].contact,
        spouse: getSpouseForSelectedContactIfExists(householdToUpdate.household_members[ activeContactIndex ].contact)
      });
    }
  };

  const addNewHouseholdAddress = () => {
    const houseHoldAddresses = household.addresses;
    houseHoldAddresses.push({});
    setHousehold({
      ...household,
      addresses: houseHoldAddresses
    });
  };

  const saveHousehold = (householdToSave = household) => {
    props.saveHousehold(householdToSave);
  };

  const saveAccount = (data) => {
    props.saveAccount(data);
  };

  const addNewContact = () => {
    if (household.household_members.length === 0) {
      const householdToUpdatd = _.cloneDeep(household);
      householdToUpdatd.household_members.push({
        memberType: "Beneficiary",
        householdQID: household.householdQID,
        contact: CONTACT_SCHEMA
      });
      setHousehold(householdToUpdatd);
    } else {
      // do nothing for now
    }
  };

  const updateAccount = (data) => {
    //console.log(data);
    props.updateAccount(data);
  };

  const startDocusign = () => {
    // TODO: RENAME FUNCTION TO explicitConsent
    setBypassPreviousConsent(true);
    if (typeof window !== 'undefined') {
      if (window.location) {
        props.fetchDocusignConsentUrl({
          return_url: window.location.origin + '/docusign-callback&state=' + QID,
          householdQID: QID
        });
      }
    }
  };

  const getSendingUrl = () => {
    if (typeof window !== 'undefined') {
      if (window.location) {
        props.fetchEmbeddedSendingUrl({
          return_url: window.location.origin + '/docusign-callback&state=' + QID,
          householdQID: QID
        });
      }
    }
  };

  const closeDocusignModal = () => {
    setDocusignModal(false);
  };

  const getSpouseForSelectedContactIfExists = (contact = activeContact) => {
    if (contact) {
      if (contact.related_contact !== null) {
        let contactSpouse = null;
        const contactSpouseID = contact.related_contact.contactQID2;
        const contactSpouseIndex = household.household_members.findIndex((el) => {
          if (el.contact) {
            return el.contact.contactQID === contactSpouseID;
          }
        });
        if (contactSpouseIndex > -1) {
          contactSpouse = household.household_members[ contactSpouseIndex ];
          return {
            label: contactSpouse.contact.contact_name,
            value: contactSpouse.contact.contactQID
          };
        }
      } else {
        const spouseIndex = household.household_members.findIndex((el) => {
          if (el.contact.related_contact !== null) {
            return contact.contactQID === el.contact.related_contact.contactQID2;
          }
        });
        if (spouseIndex > -1) {
          return {
            label: household.household_members[ spouseIndex ].contact.contact_name,
            value: household.household_members[ spouseIndex ].contact.contactQID
          };
        }
      }
    }
  };

  const updateSpouse = (value, path) => {
    let contact = _.cloneDeep(activeContact);
    if (contact.related_contact !== null) {
      contact.related_contact.contactQID2 = value.value;
    } else {
      contact.related_contact = {
        contactQID1: contact.contactQID,
        contactQID2: value.value
      }
    }
    const newSpouseIndex = household.household_members.findIndex(el => {
      return el.contact.contactQID === value.value;
    });
    if (newSpouseIndex > -1) {
      contact.spouse = {
        label: household.household_members[ newSpouseIndex ].contact.contact_name,
        value: household.household_members[ newSpouseIndex ].contact.contactQID
      };
      setActiveContact(contact);
      let householdToUpdate = _.cloneDeep(household);
      const newSpousePath = 'household_members[' + newSpouseIndex + '].contact.maritalStatus';
      const activeContactPath = 'household_members[' + activeContactIndex + '].contact.related_contact.contactQID2';
      householdToUpdate = _.set(householdToUpdate, newSpousePath, contact.maritalStatus);
      householdToUpdate = _.set(householdToUpdate, activeContactPath, household.household_members[ newSpouseIndex ].contact.contactQID);
      setHousehold(householdToUpdate);
    }
  };

  const isFieldRequired = (field, type) => {
    if (props.labels) {
      return checkIfFieldIsRequired(field, type, props.labels)
    }
  };

  return (
    <>
      {props.savingHousehold && <ProgressBar />}
      <Container>
        <Breadcrumbs
          items={breadcrumbsItems}
        />
        <HouseholdDetailsHeader
          household={household}
          switchTab={switchTab}
          activeTab={activeTab}
          saveHousehold={saveHousehold}
          addNewContact={addNewContact}
          labels={props.labels}
          missingFields={missingFields}
        />
        {/*{props.fetchingHousehold &&*/}
        {/*	<TransparentLoadingIndicator />*/}
        {/*}*/}
        <div className={cx(props.fetchingHousehold && styles.tabsContainer)}>
          <Tabs
            labels={props.labels}
            activeTab={activeTab}
            household={household}
            getFieldLabel={getFieldLabel}
            getOptions={getOptions}
            update={updateHousehold}
            addNewHouseholdAddress={addNewHouseholdAddress}
            contact={activeContact}
            activeContactIndex={activeContactIndex}
            saveAccount={saveAccount}
            fetching={props.fetchingHousehold}
            updateAccount={updateAccount}
            updateSpouse={updateSpouse}
            isFieldRequired={isFieldRequired}
            missingFields={missingFields}
          />
        </div>
        {/* Temp Hide: QC-1669 
        <div className={styles.docusignButton}>
          <button onClick={(e) => startDocusign()}>Docusign</button>
        </div> */}
      </Container>
      {docusignModal &&
        <DocusignModal
          closeModal={closeDocusignModal}
          url={props.docusignEmbeddedSendingUrl}
        />
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  household: selectHousehold(state),
  labels: selectLabels(state),
  enumerations: selectEnumerations(state),
  fetchingHousehold: selectIsFetchingHouseholds(state),
  docusignConsentUrl: selectDocusignConsentUrl(state),
  docusignEmbeddedSendingUrl: selectDocusignEmbeddedSendingUrl(state),
  columnPref: selectTableColumnsPref(state),
  savingHousehold: selectIsSavingHousehold(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchHouseholdDetails: (data) => dispatch(Creators.fetchHouseholdDetails(data)),
  setActiveHousehold: (qid) => dispatch(Creators.setActiveHousehold(qid)),
  fetchGeneralSettings: () => dispatch(Creators.fetchGeneralSettings()),
  saveHousehold: (household) => dispatch(Creators.saveHousehold(household)),
  saveAccount: (data) => dispatch(Creators.saveAccount(data)),
  clearHouseholdDetails: () => dispatch(Creators.clearHouseholdDetails()),
  updateAccount: (data) => dispatch(Creators.updateAccount(data)),
  fetchDocusignConsentUrl: (data) => dispatch(Creators.fetchDocusignConsentUrl(data)),
  cleanDocusignConsentUrl: () => dispatch(Creators.cleanDocusignConsentUrl()),
  fetchEmbeddedSendingUrl: (data) => dispatch(Creators.fetchEmbeddedSendingUrl(data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdDetails);
