import React from 'react';
import styles from './docusignModa.module.scss';
import { BsX } from 'react-icons/bs';

const DocusignModal = (props) => {
	return (
		<div className={styles.bulkImportModalContainer}>
			<div className={styles.contentContainer}>
				<div className={styles.modalHeader}>
					<div className={styles.modalTitleContainer}>
						<h3>
							Docusign Envelope
						</h3>
					</div>
					<button onClick={() => props.closeModal()}>
						<BsX style={{ strokeWidth: '1.2' }} />
					</button>
				</div>
				<div className={styles.modalBody}>
					<iframe src={props.url} title='Docusign Envelope Preparation/Sending' />
				</div>
			</div>
		</div>
	);
};

export default DocusignModal;
