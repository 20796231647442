import { removeEmptyValues } from "@qwealth/qcore";
import { hasBeneficiariesInAccount, hasParentLegalGuardian, includeSpouseAsSuccessor, isAccountEligibleForPayoutInfo, isAccountNoBeneficiaryType, isAccountRESPType, shouldTransferExternalAccounts } from "@qwealth/qdata";
import { isNil } from "lodash";
import { toast } from "react-toastify";
import { accountsServices } from "../../services/accountsServices";
import { saveOrUpdateContactRequest } from "../../services/contactServices";
import Creators from "../../state/AppRedux";

export const saveOrUpdateAccount = data => async dispatch => {
  dispatch(Creators.isFetching(true));

  const account = { ...data };
  // Sanitize parent fields
  account.id = account.id || undefined;
  account.accountNumber = account.accountNumber || undefined;
  account.jurisdiction = account.jurisdiction || undefined;
  account.educationDisabilityAccountDetailsID = account.educationDisabilityAccountDetailsID || undefined;

  //Sanitize Education And Disability Account Details
  if (account.educationDisabilityAccountDetail) {
    account.educationDisabilityAccountDetail.id = account.educationDisabilityAccountDetail.id || undefined;
  }

  //Sanitize Payout Account Details
  if (isAccountEligibleForPayoutInfo(account.accountType) && account.payoutAccountDetails) {
    if (!isNil(account.payoutAccountDetails.allocationUSD)) {
      account.payoutAccountDetails.allocationUSD = account.payoutAccountDetails.allocationUSD.toString();
    }
    if (!isNil(account.payoutAccountDetails.allocationCAD)) {
      account.payoutAccountDetails.allocationCAD = account.payoutAccountDetails.allocationCAD.toString();
    }
    account.payoutAccountDetails = removeEmptyValues(account.payoutAccountDetails);
  } else {
    account.payoutAccountDetails = undefined;
  }

  // Sanitize Education Disability Account
  if (
    !isAccountRESPType(account.accountType) ||
    !hasParentLegalGuardian(account.subscriberParentLegalGuardian)
  ) {
    account.educationDisabilityAccountDetail = undefined;
  }

  // Sanitize beneficiary values
  if (
    !isAccountNoBeneficiaryType(account.accountType) &&
    !includeSpouseAsSuccessor(account.inclSpouseAsSuccessor) &&
    hasBeneficiariesInAccount(account.beneficiariesInAccount)
  ) {
    account.accountBeneficiaries = account.accountBeneficiaries.map(ben => {
      const beneficaryClean = removeEmptyValues(ben);
      beneficaryClean.id = beneficaryClean.id ? beneficaryClean.id : undefined;
      return beneficaryClean;
    });
  } else {
    account.deleted_account_beneficiary = account.accountBeneficiaries.map(b => b.id);
    account.accountBeneficiaries = undefined;
  }

  // Sanitize relinquishing account values
  if (shouldTransferExternalAccounts(account.tansferExternalAccounts)) {
    account.relinquishingAccount = account.relinquishingAccount.map(acc => ({
      ...acc,
      id: acc.id ? acc.id : undefined,
      relinquishingInstitutionID: acc.relinquishingInstitutionID ? acc.relinquishingInstitutionID : undefined,
      accountNumber: acc.accountNumber || undefined,
      expectedValue: acc.expectedValue.toString(),
    }))
  } else {
    account.deleted_relinquishing_account = account.relinquishingAccount.map(a => a.id);
    account.relinquishingAccount = undefined;
  }

  let result = null;
  try {
    // Create or update contact for education or disability account
    if (account.educationDisabilityAccountDetail) {
      const contact = await saveOrUpdateContactRequest(account.educationDisabilityAccountDetail.parentGuardian);
      account.educationDisabilityAccountDetail.parentGuardianQID = contact.contactQID;
      account.educationDisabilityAccountDetail.parentGuardian = undefined;
    }

    if (data.id) {
      result = await accountsServices.updateAccount({ data: account });
    } else {
      result = await accountsServices.saveAccount({ data: account });
    }

    if (result.status && result.status === 200) {
      dispatch(Creators.isFetching(false));
      toast.success(`Account ${data.id ? 'updated' : 'created'} successfully.`);
      dispatch(Creators.setUpdatedAccount(result.data.response));
    }
  } catch (e) {
    toast.error('Something went wrong. Please try again later.');
    dispatch(Creators.isFetching(false));
    console.log(e);
  }
}