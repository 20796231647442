import React from 'react';
import { Table, Box } from '@qwealth/qcore';
import styles from './households.module.scss';
import LoadingIndicator from '../loading/loading';

const HouseholdsSummaryTable = (props) => {
	
	const columns = [
		{
			formatter: cell => {
				return (
					<Box color="gray.0" fontWeight="bolder" fontSize="large">
						{cell}
					</Box>
				);
			},
			dataField: 'householdName',
			headerStyle: { fontSize: '10px', fontWeight: '700', color: '#CC433C', width: '220px' },
			headerSortingStyle: { cursor: 'pointer' },
			sort: true,
			text: 'Household Name',
		},
		{
			dataField: 'missingFields',
			headerStyle: { fontSize: '10px', fontWeight: '700', color: '#CC433C' },
			headerSortingStyle: { cursor: 'pointer' },
			sort: false,
			text: 'Missing Items',
		},
		{
			formatter: cell => {
				return (
					<Box color="gray.0" fontWeight="bolder" fontSize="large">
						<div className={styles.badge}>{cell}</div>
					</Box>
				);
			},
			dataField: 'missing_fields.totalMissing',
			headerStyle: { fontSize: '10px', fontWeight: '700', color: '#CC433C', width: '30px' },
			sort: false,
			text: '',
		}
	];
	return (
		<>
			{props.fetchingHouseholds ?
				<LoadingIndicator size="small" />
				:
				<Table
					keyField={'householdQID'}
					columns={columns}
					data={props.households}
				/>
			}
		</>
	);
}

export default HouseholdsSummaryTable;
